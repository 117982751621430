import { API_URL, headers, handleApiErrors } from '../config/ApiConfig';

export const searchByID = ({ token, slowlyid }) => {
  return fetch(API_URL + '/users/id/check?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      slowlyid
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then(res => res)
  .catch(error => {
    throw error;
  });
};

export const acceptFriendRequest = ({ token, id }) => {
  return fetch(API_URL + '/users/me/request/accept?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      id
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then(res => res)
  .catch(error => {
    throw error;
  });
}

export const ignoreFriendRequest = ({ token, id }) => {
  return fetch(API_URL + '/users/me/request/ignore?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      id
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then(res => res)
  .catch(error => {
    throw error;
  });
}

export const checkFriendRequest = ({ token }) => {
  return fetch(API_URL + '/users/id/refresh?token=' + token, {
    method: 'POST',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then(res => res)
  .catch(error => {
    throw error;
  });
}
export const getFriendRequests = ({ token, page=1, hidden=0 }) => {
  return fetch(API_URL + '/users/me/request/get?token=' + token +'&page=' + page + '&hidden=' + hidden, {
    method: 'GET',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then(res => res)
  .catch(error => {
    throw error;
  });
}

export const friendRequest = ({ token, slowlyid }) => {
  return fetch(API_URL + '/users/id/request?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      slowlyid
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then(res => res)
  .catch(error => {
    throw error;
  });
};

export const explore = ({ token, page, filters, sortby, now }) => {
  let p = '';

  if (filters!=null) {
    if (filters.gender!=='') p += '&gender=' + filters.gender;
    if (filters.lang.length) p += '&lang=' + filters.lang.toString();
    if (!!filters.lastlogin) p += '&lastlogin=' + filters.lastlogin.toString()
    if (!!filters.about) p += '&about=1'
    if (filters.zodiac.length) p += '&zodiac=' + filters.zodiac.toString();
    if (filters.topics.length) p += '&tags=' + filters.topics.toString();
    if (filters.countries.length) p += '&countries=' + filters.countries.toString();
    if (filters.target_age_from!=null) p += '&target_age_from=' + filters.target_age_from + '&target_age_to=' + filters.target_age_to;
  }
  const s = sortby ? '&sortby=' + sortby : '';

  global.log('params: ' + p);
  global.log('sortby: ' + s);

  return fetch(API_URL + '/explore/v2?token=' + token + p + s + '&now=' + now + '&page=' + page)
  // return fetch('https://api.getslowly.com/explore/v2?token=' + token + p + s + '&now=' + now + '&page=' + page)
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const userStatus = ({ token, uid }) => {
  return fetch(API_URL + '/users/' + uid + '/check?token=' + token)
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const userProfile = ({ token, uid }) => {
  return fetch(API_URL + '/users/' + uid + '/info?token=' + token +'&ver=7.2')
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const userProfileExtend = ({ uid, token }) => {
  const queryString = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? '?dev=true' : ''
  return fetch(API_URL + '/users/' + uid + '/extend' + queryString, {
    method: 'GET',
    headers: {
      ...headers,
      'Authorization': 'Bearer '+token
    }
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

function objToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    if(key!=='content')
    keyValuePairs.push(
      encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    );
  }
  return keyValuePairs.join('&');
}

export const autoSearch = ({ token, fields }) => {
  const queryString = objToQueryString(fields);

  return fetch(`${API_URL}/explore/v2?token=${token}&${queryString}`, {
  // return fetch(`https://api.getslowly.com/explore/v2?token=${token}&${queryString}`, {
    method: 'GET',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const makeFriend = ({ token, userID, fields }) => {
  global.log(fields.content);

  return fetch(`${API_URL}/users/${userID}/join?token=${token}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(fields),
    timeout: 10000,
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};
