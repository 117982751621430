import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe } from "../selectors";
import StaticSideMenu from "../components/StaticSideMenu";
import _ from "lodash"

class Langs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updating: {},
      adding: false,
      level: null
    };
  }

  removeLang = () => {

    const { updating } = this.state;
    this.saveLang( updating, 'REMOVE')
  }

  initUpdate = updating => {
    this.setState({ updating, adding: false })
  }

  submitUpdate = () => {
    if(!!this.state.level)
      this.saveLang( {
        ...this.state.updating,
        level: this.state.level
      }, 'UPDATE')
    this.setState({ updating: {} })
  }

  saveLang = (selectedLang, action) => {
    global.log('selectedLang', selectedLang)
    this.props.langInit(this.props.me.token, selectedLang, action, null);
  }

  onChange =  e => {
    this.setState({ level: e.target.value })
  }

  newLang = e => {
    this.setState({
      newLang: e.target.value
    })
  }

  newLevel = e => {
    this.setState({
      newLevel: e.target.value
    })
  }

  _initAddLang = () => {
    this.setState({
      adding: true,
      newLang: null,
      newLevel: 0
    })
  }

  _addLang = () => {
    if(!this.state.newLang) return false;

    const selected = _.find(this.props.langs, { 'slug': this.state.newLang } );

    this.saveLang({
      ...selected,
      level: this.state.newLevel
    }, 'ADD');

    this.setState({
      adding: false
    })
  }

  render() {
    const { me } = this.props;
    const { updating } = this.state;

    const exists = _.keyBy(me.lang, 'slug')
    const langs = _.reject(this.props.langs, item => !!exists[item.slug] )

    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu />
            </div>
            <div className="col-9 p-3">
              {/* LANGS */}
              <div className="card shadow-sm my-2 ">
                <div className="card-header">
                  {I18n.t('LANGS')}
                </div>
                <div className="card-body pt-2">
                  { !!me.lang &&
                    me.lang.map((lang,index) => {
                      return (
                        <div className="d-flex px-2 py-3 align-items-center border-bottom" key={"lang-"+index}>
                          <div className="h6 flex-grow-1 mb-0">
                            {lang.native}
                            <small className="mx-2 text-lighter">{lang.name}</small>
                          </div>
                          { (updating.id===lang.id) ? (
                            <div className="col-sm-3 text-right">
                              <select className="form-control ml-auto custom-select custom-select-sm" defaultValue={lang.level} onChange={this.onChange}>
                                <option value={0}>{I18n.t('LEARNING')}</option>
                                <option value={1}>{I18n.t('BEGINNER')}</option>
                                <option value={2}>{I18n.t('INTERMEDIATE')}</option>
                                <option value={3}>{I18n.t('ADVANCED')}</option>
                                <option value={4}>{I18n.t('FLUENT')}</option>
                                <option value={5}>{I18n.t('NATIVE')}</option>
                              </select>
                            </div>
                          ):  lang.level > 0 ? (
                            <div className="small lang-level">
                              <i
                                className="icon-circle text-calm"
                              />
                              <i
                                className={ lang.level>= 2 ? "icon-circle text-calm" : "icon-circle-o"}
                              />
                              <i
                                className={ lang.level>= 3 ? "icon-circle text-calm" : "icon-circle-o"}
                              />
                              <i
                                className={ lang.level>= 4 ? "icon-circle text-calm" : "icon-circle-o"}
                              />
                              <i
                                className={ lang.level>= 5 ? "icon-circle text-calm" : "icon-circle-o"}
                              />
                            </div>
                          ):(
                            <div className="small text-lighter">{I18n.t('LEARNING')}</div>
                          )}
                          {(updating.id===lang.id) ? (
                            <div >
                              <button className="btn btn-secondary btn-sm" onClick={this.submitUpdate} disabled={!!me.loading}>
                                { I18n.t('SAVE') }
                              </button>
                              <button className="btn btn-danger btn-sm ml-2" onClick={this.removeLang} disabled={me.lang.length===1  || !!me.loading }>
                                <i className="icon-trash-o"/>
                              </button>
                            </div>
                          ):(
                            <div className="mx-3">
                              <button className="btn btn-light btn-sm" onClick={ () => this.initUpdate( lang ) }  disabled={!!me.loading}>
                                { !!me.loading ? (
                                  <span
                                    className="spinner-grow spinner-grow-sm text-lighter"
                                    role="status"
                                  />)
                                  : I18n.t('EDIT') }
                              </button>
                            </div>
                          )}
                        </div>
                      )
                    })
                  }
                </div>
                <div className="card-footer">
                  { !!this.state.adding ? (
                    <div className="form-row">
                      <div className="col-sm-6">
                        <select className="form-control custom-select custom-select-sm" onChange={this.newLang}>
                          {/* <option>{I18n.t('LANGS')}</option> */}
                          { langs.map( l => (
                            <option value={l.slug} key={'lang-'+l.slug}>{l.native + ' ('+l.name+')'}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-3">
                        <select className="form-control custom-select custom-select-sm" onChange={this.newLevel}>
                          {/* <option>{I18n.t('PROFICIENCY')}</option> */}
                          <option value={0}>{I18n.t('LEARNING')}</option>
                          <option value={1}>{I18n.t('BEGINNER')}</option>
                          <option value={2}>{I18n.t('INTERMEDIATE')}</option>
                          <option value={3}>{I18n.t('ADVANCED')}</option>
                          <option value={4}>{I18n.t('FLUENT')}</option>
                          <option value={5}>{I18n.t('NATIVE')}</option>
                        </select>
                      </div>
                      <div className="col-3">
                        <button className="btn btn-sm btn-primary btn-block" onClick={this._addLang}  disabled={me.lang.length>=10 || !!me.loading }>
                          { I18n.t('ADD_LANG') }
                        </button>
                      </div>
                    </div>
                  ): (
                    <button className="btn btn-sm btn-primary" onClick={this._initAddLang}  disabled={me.lang.length>=10 || !!me.loading }>
                      { I18n.t('ADD_LANG') }
                    </button>
                  )}


                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    langs: state.slowly.lang
  };
};

const langInit = function langInit( token, selectedLang, action, regStep=null ) {
  return {
    type: 'LANG_INIT',
    action,
    langId: selectedLang.id,
    token: token,
    slug: selectedLang.slug,
    level: selectedLang.level,
    regStep,
  };
};


export default connect(
  mapStateToProps,
  {
    langInit
  }
)(Langs);
