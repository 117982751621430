import { API_URL, headers, handleApiErrors } from '../config/ApiConfig';

export const deletePost = ({ token, postID }) => {
  return fetch(API_URL + '/friend/' + postID + '/clear?token=' + token, {
    method: 'DELETE',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getFriends = ({ token }) => {
  return fetch(API_URL + '/users/me/friends/v2?requests=1&dob=true&token=' + token)
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch((error,status) => {
      global.log({
        error,
        status
      })
      throw error;
    });
};

export const getHiddenFriends = ({ token }) => {
  return fetch(API_URL + '/users/me/friends/v2/hidden?token=' + token)
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getBlockedFriends = ({ token }) => {
  return fetch(
    API_URL + '/users/me/friends/?showBlocked=true&sortby=latest&token=' + token
  )
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const favPost = ({ token, postID }) => {
  return fetch(API_URL + '/friend/' + postID + '/fav?token=' + token, {
    method: 'POST',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const unfavPost = ({ token, postID }) => {
  return fetch(API_URL + '/friend/' + postID + '/fav?token=' + token, {
    method: 'DELETE',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const reportUser = ({ token, fields }) => {
  global.log('reportUser', fields)

  return fetch(API_URL + '/friend/report?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify(fields)
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};


export const blockPost = ({ token, postID }) => {
  return fetch(API_URL + '/friend/' + postID + '?token=' + token, {
    method: 'DELETE',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const unblockPost = ({ token, postID }) => {
  return fetch(API_URL + '/friend/' + postID + '/reopen?token=' + token, {
    method: 'PUT',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const hideUser = ({ token, postID }) => {
  return fetch(API_URL + '/friend/' + postID + '/hide?token=' + token, {
    method: 'POST',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const unhideUser = ({ token, postID }) => {
  return fetch(API_URL + '/friend/' + postID + '/hide?token=' + token, {
    method: 'DELETE',
    headers
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const saveRemarks = ({ token, postID, remarks }) => {
  return fetch(API_URL + '/friend/' + postID + '?token=' + token, {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      customdesc: remarks
    })
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const updatePhotoShare = ({ token, postID, allow}) => {
  return fetch(API_URL + '/friend/' + postID + '?token=' + token, {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      photoshare: allow
    })
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
}
export const updateAudioShare = ({ token, postID, allow}) => {
  return fetch(API_URL + '/friend/' + postID + '?token=' + token, {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      audioshare: allow
    })
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
}
