import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import Stamp from "../components/Stamp";
import Avatar from "../components/Avatar";
import {
  reProfileMe, reStampBookmarks, reMyItems, reParagraphs,
  reKeyedItems, reLangs
} from "../selectors";

import {
  deleteDraft,
  saveDraft,
  getDraft,
  reply,
  handleMyComment,
  uploadFile,
  removeFile
} from "../api/letters.api";

import {
  userStatus,
  userProfile,
  makeFriend,
  userProfileExtend
} from "../api/explore.api"

import {
  getQuota,
  matchingQuota
} from '../api/me.api'

import { getSuggestion, replyOpenLetter } from '../api/openletters.api';

import OpenLetter from "./OpenLetter";
import FriendProfile from "./FriendProfile";
import TextareaAutosize from "react-autosize-textarea";
import _ from "lodash";
import { toast } from "react-toastify";
import { API_URL } from "../config/ApiConfig";
import AppAlert from "../components/AppAlert";
import MyParagraphs from "../components/MyParagraphs"
import tracking from '../lib/tracking';
import MiniRecorder from './MiniRecorder'
import { Scrollbars } from 'react-custom-scrollbars';
import SplitPane from 'react-split-pane'
import moment from '../lib/moment'

const attachmentPath = API_URL + "/web/attachments/";

const menuItems = [
  'NEWEST_FIRST',
  'STAMP_PREMIUM',
  'STAMP_SET',
  'STAMP_ACHIEVEMENTS',
  'STAMP_SPECIAL_DAYS',
  'STAMP_LOCATION_BASED'
];

async function asyncForEach(array, callback) {
  if(_.isArray(array))
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
}

const defaultState = {
  stamp: "free",
  body: "",
  loading: true,
  saving: false,
  lastSaved: "",
  draftStatus: null,
  attachments: [],
  onCloud: [],
  trash: [],
  currentTab: '',
  items: [],
  alert: null,
  audio: null,
  quota: {
    photo: { remain: 5 },
    audio: {}
  },
  playing: false,
  showProfile: false,
  profileCached: false,
  wordCount: 0
}

const ComposerWrapper = (props) => {
  if(props.standalone){
    return (
      <div className="Pane2">
        {props.children}
      </div>
    )
  }else{
    return (
      <SplitPane
        split="horizontal"
        minSize={80}
        maxSize={-200}
        defaultSize={350}
        primary="second"
        onDragFinished={props.onDragFinished}
      >
        {props.children}
      </SplitPane>
    )
  }
}

const ComposerHeader = (props) => {
    const { friend, fromDraft={}, showStampSelection, loading, stamp,
    showCount, hideCount, wordCount, charCount, showingCount=false, openletter
  } = props;

    return (
      <div className="d-flex pt-2 px-3 align-items-center" style={{ minHeight: 122 }}>
        <div className="d-flex align-items-center mt-n2">
          <Avatar
            key={friend.id}
            uid={friend.id}
            id={friend.avatar}
            gender={friend.gender}
            name={friend.name}
            size={48}
          />
          <div className="ml-3 mt-1">
            <div className="mb-0 text-light">
              {!!fromDraft.new_friend && fromDraft.channel!=='openletter' ? I18n.t("TO:") : I18n.t("RE:")} {friend.name}
              { !!fromDraft.new_friend && (
                <span className="text-danger mx-2 smallest">
                  {I18n.t('NEW')}
                </span>
              )}
            </div>
            { !!openletter && (
              <span className="badge badge-pill bg-calm badge-tag mr-2 link" onClick={props.showOpenLetter}>
                <i className="icon-envelope mr-1" />
                {I18n.t('OPEN_LETTER')}
              </span>
            )}
            <span className="badge badge-pill badge-tag link" onClick={props.showProfile}>
              <i className="icon-user mr-1" />
              {I18n.t('PROFILE')}
            </span>
            <span
              className="badge badge-pill badge-tag ml-1 link"
              onMouseEnter={showCount}
              onMouseLeave={hideCount}
            >
              <i className="icon-pencil mr-1" />
              {I18n.t('WORD_COUNT')}
            </span>
            { showingCount && (
              <span
              className="badge bg-white text-light fw-lighter"
              >
                { I18n.t('Words') +': ' + wordCount + ' / ' + I18n.t('Characters') + ': ' + charCount }
              </span>
            )}
          </div>
        </div>
        <div className="flex-grow-1 text-right">
          <button
            className="btn btn-default pr-2"
            onClick={showStampSelection}
            disabled={loading}
          >
            {!!stamp && (
              <Stamp slug={stamp} size={100} />
            )}
          </button>
        </div>
      </div>
    )
}

class Composer extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState
  }

  componentDidMount() {
    this.setup();
    this.unblock = this.props.history.block((location, action) => {
      global.log('history', location)
      const unsavedChange = (this.state.body!==this.state.lastSaved || this.state.attachments.length>0 )
      if(!unsavedChange) return true;

      const _vars = location.pathname.split('/');
      global.log('_vars', _vars);
      if(_vars[1]!=='friend' || (_vars[1]==='friend'&& _vars[2]!==this.props.hashid))
        return I18n.t('ASK_SAVE_AUTOMATCH') + ' - ' + I18n.t("CONFIRM_CANCEL_SIGNUP_MSG")

      return true;
    });

    window.addEventListener('beforeunload', this.warnUnload);
    this.onBodyChangeDelayed = _.throttle(this.searchNow, 500);
  }

  warnUnload = event => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') return true;
    // const unsavedChange = (this.state.body!==this.state.lastSaved || this.state.attachments.length>0 )
    if (this.state.draftStatus==='changed' || !!this.state.loading)
    event.returnValue = I18n.t('ASK_SAVE_AUTOMATCH') + ' - ' + I18n.t("CONFIRM_CANCEL_SIGNUP_MSG");
  }

  componentWillUnmount(){
    this.unblock();
    window.removeEventListener('beforeunload', this.warnUnload);
    if(!!this.recorder) this.recorder.stop()
  }

  _hideEditor = () => {
    if(!!this.state.loading) return false;
    // const unsavedChange = (this.state.body!==this.state.lastSaved || this.state.attachments.length>0 )

    if(this.state.draftStatus==='changed'){
      this.setState({
        alert: {
          emoji: "🔔",
          title: I18n.t('ASK_SAVE_AUTOMATCH'),
          message: I18n.t("CONFIRM_CANCEL_SIGNUP_MSG"),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("CONFIRM"),
          confirmAction: this.props.hideEditor
        }
      })
    }else{
      this.setState(defaultState)
      this.props.hideEditor()
    }
  }

  closeAlert = () => {
    this.setState({
      alert: null
    });
  };

  setup = async () => {
    const { me, postID, fromDraft={} } = this.props;

    this.props.beginWriting()

    if(fromDraft.new_friend){
      if(fromDraft.channel==='openletter'){
        this._getOpenLetter()
      }else{
        //check availbitiy
        this.getUserStatus(fromDraft.new_friend.id)
      }
    }

    try{
      const { draft } = await getDraft({
        token: me.token,
        post: !!fromDraft.new_friend ? null : postID,
        user_id: !!fromDraft.new_friend ? fromDraft.new_friend.id : null,
      });
      this.setState({
        stamp: !!draft.stamp ? draft.stamp : !!me.pref.stamp ? me.pref.stamp : "free",
        body: !!draft.body ? draft.body : "",
        wordCount: !!draft.body ? I18n.wordCount(draft.body) : 0,
        onCloud: !!draft.attachments ? draft.attachments.split(",") : [],
        audio: draft.audio,
        lastSaved: draft.body
      });

    }catch(error){
      global.log('draft not found')
      this.setState({
        stamp: !!me.pref.stamp ? me.pref.stamp : "free",
        body: "",
        audio: null,
        lastSaved: ""
      });
    }

    try{
      const quota = await getQuota({ token: this.props.me.token })
      global.log('getQuota', quota)
  
      this.setState({
        quota
      })
    }catch(error){
      global.log('getQuota error', error)
    }

    this.setState({
      loading: false,
      draftStatus: "loaded"
    })

    if(this.props.bookmarks.length>0) 
      this.switchTab('FAV_STAMPS')
    else
      this.switchTab('STAMP_ALL')
  };

  getUserStatus = async (uid, addFriend=false) => {
    if(!this.props.fromDraft.new_friend) return true

    try {
      const {token} = this.props.me;
      const user = await userStatus({token, uid});
      global.log('getUserStatus', user);

      if (user.new_request <= 0) {
        this.setState({
          user_status: false,
          alert: {
            title: I18n.t('SLOWLY_TIPS'),
            message: I18n.t("USER_NOT_ACCEPTING_FRIEND_ATM", {
              name: this.props.fromDraft.new_friend.name,
            }),
            confirmLabel: I18n.t("REG_NOTED"),
            confirmAction: ()=>{
              this.closeAlert()
            }
          }
        })
        return false
      } else {
        this.setState({ user_status: true });
        if(addFriend) this.addFriend()
      }
    } catch (err) {
      global.log(err);
    }
  };

  _getOpenLetter = async (callback) => {
    this.setState({ loading: true });

    try {
      const letter = await getSuggestion({
        token: this.props.me.token,
        matching_token: this.props.fromDraft.new_friend.matching_token,
      });

      global.log('getSuggestion result', letter)
      
      await this.setState(
        {
          openletter: {
            ...letter,
            ...letter.author,
            sent_from: letter.author.location_code,
            language: this.props.langs[letter.language]
            ? this.props.langs[letter.language].native
            : letter.language,
          },
          targetLang: _.keyBy([ { slug: letter.language } ], 'slug'),
          loading: false,
        }
      );
      if(_.isFunction(callback)) callback();

    } catch (e) {
      global.log('getSuggestion error', e);
      const suffix = e.code ? ' (' + e.code+ ')' : ''

      if(e.error){
        if(e.error==='OPENLETTER_EXPIRED'){
          this.setState({
            channel: 'temp',
            remove_openletter: true,
            alert: {
              title: I18n.t('OPENLETTER_EXPIRED'),
              message: I18n.t('OPENLETTER_EXPIRED_TIPS'),
              confirmLabel: I18n.t("REG_NOTED"),
              confirmAction: ()=>{
                this.getUserStatus(this.props.fromDraft.new_friend.id)
                this.closeAlert()
              }
            }
          })
        }else if(e.error==='TARGET_REACHED_DAILY_MATCHING_LIMIT'){
          toast.error(I18n.t('TARGET_REACHED_DAILY_MATCHING_LIMIT', { name: e.name }) + suffix, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
            closeOnClick: true
          });
        }else{
          toast.error(I18n.t(e.error) + suffix, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
            closeOnClick: true
          });
        }
      }else{
        toast.error(I18n.t('UNABLE_TO_REFRESH_DATA') + suffix, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
          closeOnClick: true
        });
      }
      this.setState({ loading: false, user_status: false });
    }
  }

  _initSend = async() => {
    if(!!this.state.loading) return false;

    const bodyLen = I18n.charCount(this.state.body);

    const { fromDraft={} } = this.props;
    //if new friend, check matching quota
    if (!!fromDraft.new_friend) {
      if (bodyLen < 100) {
        this.setState({
          alert: {
            title: I18n.t('TIPS_SHORT_LETTER_TITLE_WITH_NAME', {
              name: fromDraft.new_friend.name,
            }),
            message: I18n.t("AUTOMATCH_CONTENT_TOO_SHORT"),
            confirmLabel: I18n.t("CONTINUE_WRITING"),
            confirmAction: this.closeAlert
          }
        });
        return false;
      }

      const quota = await matchingQuota({ token: this.props.me.token });

      if (quota.count > 0) {
        if (quota.count >= quota.limit) {
          this.setState({
            alert: {
              title: I18n.t('SLOWLY_TIPS'),
              message: I18n.t('REACHED_DAILY_MATCHING_LIMIT', {
                datetime: moment.custom(quota.reset, 'long'),
              }),
              cancelLabel: I18n.t("CANCEL"),
              cancelAction: this.closeAlert,
              confirmLabel: I18n.t("SAVE_DRAFT"),
              confirmAction: ()=> {
                this._saveDraft()
                this.closeAlert()
              }
            }
          })

          return false;
        }
      }

      // if(fromDraft.channel==='openletter'){
      //   this.addFriend()
      // }else{
      //   this.getUserStatus(fromDraft.user_id, true)
      // }
      this.addFriend()

      return true
    }

    if (bodyLen <= 0) return false;
    const totalImgs = this.state.onCloud.length+ this.state.attachments.length

    if( totalImgs > 0 ){
      const result = await getQuota({ token: this.props.me.token })

      this.setState({
        quota: {
          photo: result.photo,
          audio: result.audio
        }
      })

      if(result.photo.remain < totalImgs ){
        this.setState({
          alert: {
            title: I18n.t('REACHED_ATTACHMENTS_QUOTA'),
            message: I18n.t("REACHED_ATTACHMENTS_QUOTA_MSG"),
            confirmLabel: I18n.t("CONFIRM"),
            confirmAction: ()=>{
              this.closeAlert()
            }
          }
        })
        return false;
      }
    }

    if (bodyLen < 20) {
      this.setState({
        alert: {
          title: I18n.t("SLOWLY_TIPS"),
          message: I18n.t("CONFIRM_SHORT_MSG"),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("CONFIRM"),
          confirmAction: this._send
        }
      });
      return false;
    }

    if (this.props.sendLetterReminder !== false) {
      this.setState({
        alert: {
          title: I18n.t("SLOWLY_TIPS"),
          message: I18n.t("CONFIRM_SEND_MSG"),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("CONFIRM"),
          confirmAction: this._send
        }
      });
      return false;
    } else {
      this._send();
      return true;
    }
  };

  _send = async () => {
    this.setState({
      loading: true,
      alert: {
        title: (<span className="text-positive">
          <i className="icon-send mr-1 text-lighter"></i> {I18n.t("FINISHED_UPLOADING")}
        </span>),
        message: "LOADING"
      }
    });

    const { me, postID, friend } = this.props;
    const { stamp, trash, body, attachments, onCloud } = this.state;
    const host = friend.user_id === friend.joined ? true : false;

    let newAttachments = [...onCloud];
    if (_.isArray(attachments) && attachments.length > 0) {
      await asyncForEach(attachments, async file => {
        const result = await uploadFile({
          token: me.token,
          id: postID,
          file: file.file
        });
        global.log("result", result);
        newAttachments.push(result.s3);

        tracking.event('photo_upload');
      });
    }

    if (_.isArray(trash) && trash.length > 0) {
      await asyncForEach(trash, async filename => {
        await removeFile({ token: me.token, post: postID, filename });

        tracking.event('photo_remove');
      });
    }

    let audioID = null;

    if(!!this.state.audio){
      if(!!this.state.audio.uploaded){
        audioID = this.state.audio.uploaded
      }else{
        //upload now
        const audioResult = await uploadFile({
          token: me.token,
          id: postID,
          file: this.state.audio.path,
          type: 'audio',
          duration: this.state.audio.duration
        });

        global.log('audioResult', audioResult)

        audioID = audioResult.id;

        this.setState({
          audio: {
            ...this.state.audio,
            file_path: audioResult.s3,
            uploaded: audioID
          }
        })
      }
    }

    try {
      const response = await reply({
        token: me.token,
        id: postID,
        body,
        stamp,
        attachments: newAttachments.join(','),
        host,
        extra: _.isArray(newAttachments) && newAttachments.length>=1,
        audio: audioID
      });
      
      global.log('reply success', response)

      const commentFixed = await handleMyComment({
        comment: response.comment,
        me: this.props.me,
      });

      this.props.replySuccess({
        comment: commentFixed,
        postID,
        uid: this.props.me.id,
      });
      
      if(this.props.onSent) this.props.onSent(postID)

      this.setState({
        body: "",
        wordCount: '...',
        loading: false,
        saving: false,
        lastSaved: "",
        draftStatus: null,
        attachments: [],
        onCloud: [],
        trash: [],
        alert: null
      })

      toast.info(I18n.t("LETTER_SENT"));

      this.props.hideEditor()

    } catch (error) {
      if (error.error === 'REACHED_ATTACHMENTS_QUOTA_MSG') {
        this.setState({
          loading: false,
          alert: {
            title: I18n.t('REACHED_ATTACHMENTS_QUOTA'),
            message: I18n.t('REACHED_ATTACHMENTS_QUOTA_MSG'),
            confirmAction: this.closeAlert,
            confirmLabel: I18n.t("BTN_CLOSE"),
          }
        });
      } else if (error.error === 'SERVER_TIMEOUT' || !isNaN(error.error)) {
        this.setState({retry: this.state.retry + 1});

        if (this.state.retry <= 3) {
          global.log('reply failed, retry now - ' + this.state.retry);
          setTimeout(this._send, 1000);
          return true;
        } else {
          this.setState({
            loading: false,
            alert: {
              title: I18n.t('SERVER_OFFLINE'),
              message: I18n.t('STORE_IS_TEMP_CLOSED_TRY_LATER'),
              confirmAction: this.closeAlert,
              confirmLabel: I18n.t("BTN_CLOSE"),
            }
          });
        }
      } else {
        this.setState({
          loading: false,
          alert: {
            title: I18n.t('ERROR'),
            message: !!error.error ? I18n.t(error.error) : I18n.t('INTERNAL_ERROR'),
            confirmAction: this.closeAlert,
            confirmLabel: I18n.t("BTN_CLOSE"),
          }
        });
      }
    }
    // this.props.replyInit({
    //   token: me.token,
    //   id: postID,
    //   body,
    //   stamp,
    //   attachments: newAttachments.join(','),
    //   host,
    //   extra: newAttachments.length>=1,
    //   audio: audioID
    // })
  };

  addFriend = async () => {

    this.setState({
      loading: true,
      alert: {
        title: (<span className="text-positive">
          <i className="icon-send mr-1 text-lighter"></i> {I18n.t("FINISHED_UPLOADING")}
        </span>),
        message: "LOADING"
      }
    });

    try{
      const { token } = this.props.me
      const { fromDraft } = this.props;
      const { stamp, body } = this.state;

      const _API = fromDraft.channel === 'openletter' ? replyOpenLetter : makeFriend;

      const response = await _API({
        token,
        userID: fromDraft.user_id,
        fields: {
          matching_token: fromDraft.new_friend.matching_token,
          body,
          stamp,
          style: '{}',
          automatch: 'false',
        },
      })
      global.log('makeFriend', response)

      if(this.props.onSent) this.props.onSent(fromDraft.user_id, 'matches')
      this.props.refreshFriends()

      this.setState(defaultState)
      this.props.hideEditor()

      toast.info("🎉 " + I18n.t("NEW_FRIEND_TOAST"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        closeOnClick: true
      });
    }catch(error){
      this.setState({
        loading: false,
        alert: {
          title: I18n.t('ERROR'),
          message: !!error.error ? I18n.t(error.error) : I18n.t('INTERNAL_ERROR'),
          confirmAction: this.closeAlert,
          confirmLabel: I18n.t("BTN_CLOSE"),
        }
      });
    }

  }

  _saveDraft = async () => {
    if(!!this.state.loading) return false;

    const { me, postID, uuid, fromDraft={} } = this.props;
    const { stamp, trash, body, attachments, onCloud, audio } = this.state;

    // if (body === lastSaved && !attachments.length && !audio ) {
    //   this.setState({
    //     draftStatus: "saved"
    //   });
    //   return false;
    // }

    this.setState({
      loading: true,
      saving: true
    });

    try {
      let newAttachments = [...onCloud];

      if (_.isArray(attachments) && attachments.length > 0) {
        await asyncForEach(attachments, async file => {
          const result = await uploadFile({
            token: me.token,
            id: postID,
            file: file.file
          });
          global.log("result", result);
          newAttachments.push(result.s3);

          tracking.event('photo_upload');
        });
      }
      global.log('newAttachments: '+ newAttachments.join(","))

      let audioID = null;

      if(!!audio){
        if(!!audio.uploaded){
          audioID = audio.uploaded
        }else{
          //upload now
          const audioResult = await uploadFile({
            token: me.token,
            id: postID,
            file: audio.path,
            type: 'audio',
            duration: audio.duration
          });

          global.log('audioResult', audioResult)

          audioID = audioResult.id;

          this.setState({
            audio: {
              ...audio,
              file_path: audioResult.s3,
              uploaded: audioID
            }
          })
        }
      }
      

      const payload = {
        token: me.token,
        post: !!fromDraft.new_friend ? null : postID,
        channel: fromDraft.channel==='openletter' ? 'openletter' : !!this.props.new_friend ? 'temp' : 'web',
        user_id: !!fromDraft.new_friend ? fromDraft.new_friend.id : null,
        stamp,
        body,
        device_id: uuid,
        attachments: newAttachments.join(","),
        audio: audioID,
        matching_token: !!fromDraft.new_friend && fromDraft.new_friend.matching_token
      }
      global.log('saveDraft payload', {payload, fromDraft});

      const result = await saveDraft(payload);

      tracking.event('draft_saved');
      global.log(result);

      if (_.isArray(trash) && trash.length > 0) {
        await asyncForEach(trash, async filename => {
          await removeFile({ token: me.token, post: postID, filename });

          tracking.event('photo_remove');
        });
      }

      this.setState({
        loading: false,
        saving: false,
        attachments: [],
        onCloud: !!result.onCloud ? result.onCloud : newAttachments,
        trash: [],
        lastSaved: body,
        draftStatus: "saved"
      });

      if(this.props.onSave){
        this.props.onSave({
          ...payload,
          new_friend: fromDraft.new_friend,
          body: payload.body.substring(0,100),
          updated_at: result.updated_at
        }, !!fromDraft.new_friend ? "matches" : "friends")
      }

    } catch (e) {
      global.log(e);
      this.setState({
        loading: false,
        saving: false,
        draftStatus: "failed"
      });
    }
  };

  switchTab = (tabName) => {
    if (tabName === this.state.currentTab && this.state.items > 0 && !this.state.searching) return false;

    switch (tabName) {
      case 'NEWEST_FIRST':
        this.setState({
          currentTab: tabName,
          items: _.orderBy(
            this.props.myItems,
            ['created_at', 'id'],
            ['desc', 'desc'],
          ),
          searching: false
        });
        break;

      case 'FAV_STAMPS':
        const { bookmarks } = this.props.me;
        const myItems = _.keyBy(this.props.myItems, 'item_slug');
        this.setState({
          currentTab: tabName,
          items: _.map(_.filter(bookmarks, item => !!myItems[item]), item => myItems[item]),
          searching: false
        });
        break;

      case 'STAMP_PREMIUM':
        this.setState({
          currentTab: tabName,
          items: _.filter(this.props.myItems, function (o) {
            return o.price >= 2;
          }),
          searching: false
        });
        break;

      case 'STAMP_SET':
        this.setState({
          currentTab: tabName,
          items: _.filter(this.props.myItems, function (o) {
            return !!o.is_stamp_set;
          }),
          searching: false
        });
        break;

      case 'STAMP_ACHIEVEMENTS':
        this.setState({
          currentTab: tabName,
          items: _.filter(this.props.myItems, { item_group: 'achievement' }),
          searching: false
        });
        break;

      case 'STAMP_SPECIAL_DAYS':
        this.setState({
          currentTab: tabName,
          items: _.orderBy(
            _.filter(this.props.myItems, function (o) {
              return o.item_group === 'int-days' || o.item_group === 'festive';
            }),
            ['item_group', 'country', 'month', 'day', 'first_release'],
            ['desc', 'asc', 'asc', 'asc', 'asc'],
          ),
          searching: false
        });
        break;

      case 'STAMP_LOCATION_BASED':
        this.setState({
          currentTab: tabName,
          items: _.filter(this.props.myItems, o => !!o.country),
          searching: false
        });
        break;

      default:
        break;
    }
  };

  changeStamp = item => {
    this.setState({
      stamp: item.item_slug,
      showStamp: false
    })
    this.props._changeStamp({
      selectedStamp:  item.item_slug,
    })
  }

  hoverStamp = item => {
    this.setState({
      hover: item
    })
  }

  onChange = event => {
    const { value } = event.target;

    this.setState({
      body: value,
      draftStatus:
        value !== this.state.lastSaved ? "changed" : this.state.draftStatus
    });
  };

  onFileSelected = e => {
    let newFiles = [];

    _.forEach(e.target.files, file => {
      newFiles.push({
        file,
        preview: URL.createObjectURL(file)
      });
    });

    const onCloudLength = _.isArray(this.state.onCloud) ? this.state.onCloud.length : 0

    const attachments = [...this.state.attachments, ...newFiles];
    const totalImgs = onCloudLength + attachments.length;

    if (totalImgs > 5)
      toast.info(I18n.t("REACHED_ATTACHMENTS_LIMIT"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        closeOnClick: true
      });

    this.setState({
      attachments: _.take(attachments, 5 - onCloudLength),
      draftStatus: 'changed'
    });
  };

  _removeCloudFile = filename => {
    if (!!this.state.loading) return false;
    this.setState({
      onCloud: _.reject(this.state.onCloud, o => {
        return o === filename;
      }),
      trash: _.concat(this.state.trash, filename),
      draftStatus: 'changed'
    });
  };

  _removeFile = filename => {
    if (!!this.state.loading) return false;

    this.setState({
      attachments: _.reject(this.state.attachments, o => {
        return o.file.name === filename;
      }),
      draftStatus: 'changed'
    });
  };

  showFileInput = () => {
    if (!!this.state.loading) return false;

    const onCloudLength = _.isArray(this.state.onCloud) ? this.state.onCloud.length : 0
    const attachmentsLength = _.isArray(this.state.attachments) ? this.state.attachments.length : 0

    const totalImgs = onCloudLength + attachmentsLength

    if (totalImgs >= 5) {
      toast.info(I18n.t("REACHED_ATTACHMENTS_LIMIT_DESC"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        closeOnClick: true
      });
      return false;
    }

    this.fileInput.click();
  };

  showStampSelection = () => {
    this.setState({
      showStamp: true
    })
  }

  hideStampSelection = () => {
    this.setState({
      showStamp: false
    })
  }

  onAudioChange = audio => {
    global.log('onAudioChange', audio)

    this.setState({
      audio,
      draftStatus: 'changed'
    })
  }

  startRecording = () => {
    this.setState({ loading: true })
  }

  stopRecording = () => {
    this.setState({ loading: false })
  }

  onDragFinished = padding => {
    this.props.onDragFinished(padding)
    this.setState({ stampBottom: padding-100 })
  }

  friendModalClosed = () => {
    this.setState({
      showProfile: false
    })
  }

  openLetterClosed = () => {
    this.setState({
      showOpenLetter: false
    })
  }

  showOpenLetter = () => {
    this.setState({
      showOpenLetter: true
    })
  }

  showProfile = async() => {
    if(this.state.loading) return false;

    const { fromDraft={} } = this.props;
    if(!fromDraft.new_friend || !!this.state.user){
      this.setState({ showProfile: true, loading: false })
      return true;
    }

    this.setState({ loading: true })

    //load profile
    const user = await userProfile({ token: this.props.me.token, uid: fromDraft.user_id })

    this.setState({
      showProfile: true,
      user
    })

    const extend = await userProfileExtend({ token: this.props.me.token, uid: fromDraft.user_id })
    global.log('Got user', user)

    const myTags = this.props.me.tags;

    const sub_others = !!extend.subtopics
      ? _.filter(
          extend.subtopics.data,
          s => s.parent === 'OTHER_TOPICS' || !s.parent,
        )
      : [];

    global.log('sub_others', sub_others);

    const otherTopics = _.map(
      _.filter(extend.topics, t => {
        return myTags.indexOf(t) < 0;
      }),
      tag => {
        return {
          tag,
          subtopics: !!extend.subtopics
            ? _.filter(extend.subtopics.data, s => s.parent === tag)
            : [],
        };
      },
    );

    this.setState({
      user: {
        ...user,
        extend,
        commonTopics: _.map(
          _.filter(extend.topics, t => {
            return myTags.indexOf(t) >= 0;
          }),
          tag => {
            return {
              tag,
              subtopics: !!extend.subtopics
                ? _.filter(extend.subtopics.data, s => s.parent === tag)
                : [],
            };
          },
        ),
        otherTopics:
          sub_others.length > 0
            ? [
                ...otherTopics,
                {
                  tag: 'OTHER_TOPICS',
                  subtopics: sub_others,
                },
              ]
            : otherTopics,
      },
      loading: false
    })
  }

  deleteDraft = () => {
    this.setState({
      alert: {
        title: I18n.t('CONFIRM_REMOVE'),
        message: I18n.t("CONFIRM_DELETE_ACC_MSG"),
        cancelLabel: I18n.t("CANCEL"),
        cancelAction: this.closeAlert,
        confirmLabel: I18n.t("DELETE"),
        confirmAction: this.confirmDelete,
        danger: true
      }
    })
  }

  confirmDelete = () => {
    const { token } = this.props.me
    const { fromDraft={} } = this.props

    if (!!fromDraft.new_friend) {
      deleteDraft({token, user_id: fromDraft.user_id });
      this.props.onSent(fromDraft.user_id, 'matches')
    } else {
      saveDraft({
        token,
        stamp: this.state.stamp,
        body: '',
        post: this.props.postID,
        channel: this.state.platform,
        device_id: this.state.uuid,
        attachments: '',
        audio: null,
      });
      if(this.props.onSent) this.props.onSent(this.props.postID)
    }

    this.setState({
      alert: null
    })
    this.props.hideEditor()

    toast.info("✔ " + I18n.t("AUTOMATCH_DRAFT_DISCARDED"), {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: true,
      closeOnClick: true
    });
  }

  showCount = () => {
    this.setState({
      charCount: I18n.charCount(this.state.body),
      wordCount: I18n.wordCount(this.state.body),
      showingCount: true
    })
  }

  hideCount = () => {
    this.setState({
      showingCount: false
    })
  }

  insert = text => {
    this.setState({
      body: this.state.body.length>0 ? this.state.body + '\n' + text : text,
      showParagraphs: false
    }, ()=>{
      this.editorScroller.scrollToBottom()
    })
  }
  
  onBodyChange = (e) => {
    this.setState({ searching: true, searchText: e.target.value })
    this.onBodyChangeDelayed()
  }

  searchNow = () => {
    const txt = this.state.searchText
    if(!this.state.searching) return false;

    const searchText = txt.trim().toLowerCase();
    this.setState({
      items: !!searchText
        ? _.filter(this.props.myItems, function (o) {
            return (
              _.includes(o.item_name.toLowerCase(), searchText) ||
              _.includes(I18n.t(o.item_name), searchText) ||
              _.includes(o.desc.toLowerCase(), searchText) ||
              _.includes(I18n.t(o.desc), txt) ||
              _.includes(o.item_slug.toLowerCase(), searchText) ||
              (!!o.country &&
                _.includes(o.country.toLowerCase(), searchText)) ||
              (!!o.country &&
                _.includes(
                  I18n.country(o.country).toLowerCase(),
                  searchText
                )) ||
              (!!o.item_group &&
                o.item_group !== "default" &&
                _.includes(o.item_group.toLowerCase(), searchText))
            );
          })
        : []
    });
  };

  render() {
    const { me, standalone=false, fromDraft={} } = this.props;
    const friend = fromDraft.new_friend ? fromDraft.new_friend : this.props.friend

    const {
      loading,
      saving,
      draftStatus,
      body,
      attachments = [],
      onCloud = [],
      items,
      stampBottom=250,
      showProfile=false,
      showOpenLetter=false
    } = this.state;

    const { photo={ remain: 10 } } = this.state.quota
    const totalImgs = onCloud.length + attachments.length;

    return (
      <div className="container h-100">
        <div className="d-flex h-100">
          <div className="empty-sidebar" />
          <div className="col pr-0 flex-grow-1">
            <ComposerWrapper
              standalone={standalone}
              onDragFinished={this.onDragFinished}
            >
              <div />
              <div className="align-items-stretch h-100 w-100">
                {!standalone && (
                  <div className="handler">
                    <div className="grip">
                      <div className="icon-holder">
                        <i className="icon-more" />
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-100 h-100 editor " dir="ltr">
                  <div className="modal-content w-100 h-100 m-0 p-0">
                    {(totalImgs>0 && photo.remain<5) && (
                      <div className="photo-quota-badge">
                          <div className={  ( photo.remain < totalImgs || photo.remain===0) ? "badge badge-danger p-2" : "text-white badge badge-dark p-2" }>
                            <i className="icon-info pr-1" />
                            {I18n.t("PHOTO_QUOTA") }{': '}{ photo.remain }
                          </div>
                      </div>
                    )}
                    <div className="modal-header p-1" ref={ ref => this.modalBody = ref }>
                      <div className="row w-100 m-0">
                        <div className="col-1 d-flex align-items-center mx-1 p-0">
                          <button
                            type="button"
                            className="btn btn-default btn-toolbar"
                            onClick={this._hideEditor}
                            disabled={!!loading}
                          >
                            <i className="icon-close h4" />
                          </button>
                        </div>
                        <div className="col d-flex flex-row-reverse align-items-center text-right pr-1">
                          <button
                            type="button"
                            className="btn btn-primary ml-2 editor-btn"
                            disabled={ !!loading || this.state.user_status===false }
                            onClick={this._initSend}
                          >
                            <i className="icon-send mr-2" />
                            {I18n.t("SEND")}
                          </button>
                          <button
                            type="button"
                            className="btn ml-2 editor-btn btn-outline-dark"
                            onClick={this._saveDraft}
                            disabled={!!loading}
                          >
                            {draftStatus === "saved" ? (
                              I18n.t("AUTOMATCH_DRAFT_SAVED")
                            ) : !!saving ? (
                              <span
                                className="spinner-grow spinner-grow-sm mr-2 text-warning"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              I18n.t("SAVE_DRAFT")
                            )}
                          </button>

                          <button
                            type="button"
                            className="btn editor-btn btn-default text-light btn-sm"
                            onClick={()=>{
                              this.setState({
                                showParagraphs: true
                              })
                            }}
                          >
                            {I18n.t("MY_PARAGRAPHS")}
                          </button>

                          { (!!friend.allowaudio && draftStatus!==null ) && (
                            <MiniRecorder
                              key={friend.id}
                              me={me}
                              audio={!!this.state.audio ?
                                attachmentPath +
                                this.state.audio.file_path +
                                "?_p=" +
                                friend.pass +
                                "&_u=" +
                                me.hashid
                                 :
                                null}
                              duration={!!this.state.audio ?
                                this.state.audio.duration  : null}
                              onAudioChange={this.onAudioChange}
                              startRecording={this.startRecording}
                              stopRecording={this.stopRecording}
                            />
                          )}
                          { !!friend.allowphotos && (
                            <button
                              type="button"
                              className="btn btn-default btn-toolbar mr-2"
                              ref={ref => (this.imgBtn = ref)}
                              onClick={this.showFileInput}
                            >
                              <i className="icon-attachment-2" />
                              {totalImgs > 0 && (
                                <span className="badge badge-danger badge-corner">
                                  {totalImgs}
                                </span>
                              )}
                            </button>
                          )}

                          { !standalone && (
                            <button
                              type="button"
                              className="btn btn-default btn-toolbar mr-2"
                              onClick={this.props.toggleFocusMode}
                              disabled={!!loading}
                            >
                              <i className={ !!this.props.isFocus ? "icon-minimize h4" : "icon-expand"} />
                            </button>
                          )}
                          {(!!standalone && !!fromDraft.new_friend) && (
                            <button
                              type="button"
                              className="btn btn-default btn-toolbar mr-2"
                              onClick={this.deleteDraft}
                              disabled={!!loading}
                            >
                              <i className="icon-trash-o" />
                            </button>
                          )}

                        </div>
                      </div>
                      <input
                        className="invisible position-absolute"
                        ref={ref => (this.fileInput = ref)}
                        type="file"
                        accept="image/jpeg,image/png,image/jpg"
                        multiple
                        onChange={this.onFileSelected}
                      />
                    </div>
                    { this.props.isFocus && (
                      <ComposerHeader
                          friend={friend}
                          fromDraft={fromDraft}
                          showStampSelection={this.showStampSelection}
                          loading={loading}
                          stamp={this.state.stamp}
                          darkMode={this.props.darkMode}
                          showProfile={this.showProfile}
                          showOpenLetter={this.showOpenLetter}
                          openletter={!!this.state.openletter}
                          wordCount={this.state.wordCount}
                          charCount={this.state.charCount}
                          showCount={this.showCount}
                          hideCount={this.hideCount}
                          showingCount={this.state.showingCount}
                          fullscreen
                       />
                    )}
                    {/* <div className="modal-body position-relative" id="editorScroller"> */}
                    <Scrollbars
                      className="modal-body position-relative"
                      ref={ ref => this.editorScroller = ref }
                    >
                        { !this.props.isFocus && (
                          <ComposerHeader
                              friend={friend}
                              showStampSelection={this.showStampSelection}
                              loading={loading}
                              stamp={this.state.stamp}
                              showProfile={this.showProfile}
                              wordCount={this.state.wordCount}
                              charCount={this.state.charCount}
                              showCount={this.showCount}
                              hideCount={this.hideCount}
                              showingCount={this.state.showingCount}
                           />
                        )}
                        <div className="px-3">
                          <TextareaAutosize
                            key="editor"
                            className="form-control textarea"
                            dir="auto"
                            value={body}
                            onChange={this.onChange}
                            placeholder={I18n.t("BODY_PLACEHOLDER")}
                            disabled={!!loading}
                          />
                        </div>

                        {totalImgs > 0 && (
                          <div
                            className="mt-3 px-2 d-flex mb-1"
                            style={{ width: "80%" }}
                          >
                            {_.map(onCloud, img => {
                              return (
                                <div
                                  className="rounded img-preview m-1 with-border"
                                  key={img}
                                >
                                  <img
                                    src={
                                      attachmentPath +
                                      img +
                                      "?_p=" +
                                      friend.pass +
                                      "&_u=" +
                                      me.hashid
                                    }
                                    alt="..."
                                  />
                                  <div
                                    className="close link"
                                    onClick={() => {
                                      this._removeCloudFile(img);
                                    }}
                                  >
                                    <i className="icon-close text-white" />
                                  </div>
                                </div>
                              );
                            })}

                            {_.map(attachments, img => {
                              return (
                                <div
                                  className="rounded img-preview m-1 with-border"
                                  key={img.file.name}
                                >
                                  <img src={img.preview} alt={img.file.name} />
                                  <div
                                    className="close link"
                                    onClick={() => {
                                      this._removeFile(img.file.name);
                                    }}
                                  >
                                    <i className="icon-close text-white" />
                                  </div>
                                </div>
                              );
                            })}

                          </div>
                        )}
                        <div className="p-2 w-100" />
                    </Scrollbars>
                    {/* </div> */}
                    { (!!this.state.loading && draftStatus===null) && (
                      <div className="editor-block w-100 h-100 d-flex justify-content-center align-items-center">
                        <span
                          className="spinner-grow spinner-grow-sm text-warning"
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    )}

                  </div>
                </div>
                { !!this.state.showStamp && (
                  <div
                    className="stamp-selection"
                    style={{ bottom: stampBottom }}
                    dir="ltr"
                  >
                    <div className="popover bs-popover-top shadow-lg ">
                      <div className="arrow"></div>
                      <div className="d-flex h-100">
                        <div className="bg-stable-darker h-100 set-menu d-flex flex-column" >
                          <div className="p-1 mt-1">
                            <div className="input-group search-bar bg-white input-group-sm">
                              <div className="input-group-prepend">
                                <i className="input-group-text icon-search pr-0" />
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={I18n.t("SEARCH")}
                                ref={(input) => {
                                  this.searchInput = input;
                                }}
                                onChange={this.onBodyChange}
                              />
                            </div>
                          </div>
                          <Scrollbars
                            className="flex-grow-1"
                          >
                            {
                              _.map(menuItems, item => {
                                return (
                                  <div className="mx-1"
                                    key={item}>
                                  <button
                                  className={
                                    !this.state.searching && item===this.state.currentTab ?
                                    "btn btn-secondary btn-sm btn-block text-truncate" :
                                    "btn btn-default btn-sm btn-block text-truncate"
                                  }
                                  onClick={()=> { this.switchTab(item)} }
                                >
                                  {I18n.t(item)}
                                </button></div>
                              )
                              })
                            }
                          </Scrollbars>
                          <div className="p-1">
                            <button type="button"
                              className={`btn btn-sm text-center ${ !this.state.searching && this.state.currentTab==='FAV_STAMPS' ? 'btn-primary' : 'btn-outline-primary '}`}
                              onClick={()=> { this.switchTab('FAV_STAMPS')} }
                              >
                              <i className="icon-bookmark" /> <span className="badge badge-pill ml-1 badge-secondary">{ 
                                _.isArray(this.props.bookmarks) ? this.props.bookmarks.length : 0 }</span>
                            </button>
                          </div>
                        </div>
                        <div className="h-100 d-flex flex-column flex-grow-1">
                          <Scrollbars
                            className="flex-grow-1"
                          >
                              <div className="row no-gutters p-1 py-3">
                              { _.map( items, item => {
                                return (
                                  <div
                                    className="col-4 text-center my-2 link"
                                    onClick={
                                    ()=> { this.changeStamp(item) }
                                  }
                                      key={'item-'+item.item_slug}
                                      onMouseEnter={()=>{ this.hoverStamp(item) }}
                                      onMouseLeave={()=>{ this.hoverStamp(null) }}
                                  >
                                    <Stamp slug={item.item_slug} size={100} />
                                  </div>
                                )
                              })}
                            </div>
                          </Scrollbars>
                          <div className="p-2 px-3 border-top text-light small">
                            { !!this.state.hover ? (
                              <span className="text-primary">
                              {I18n.t(this.state.hover.name)}
                              </span>
                            ) : I18n.t('ABOUT_STAMP')}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ComposerWrapper>
          </div>
        </div>
        { !!this.state.showStamp && (<div className="alert-backdrop for-stamp" onClick={this.hideStampSelection} />)}

        <MyParagraphs
          showing={this.state.showParagraphs}
          dismiss={()=>{
            this.setState({
              showParagraphs: false
            })
          }}

          insert={this.insert}
        />

        <AppAlert alert={this.state.alert} />
        <OpenLetter
          show={showOpenLetter}
          item={this.state.openletter}
          handleClose={this.openLetterClosed}
        />
        <FriendProfile
          show={showProfile}
          postID={friend.id}
          user={this.state.user}
          handleClose={this.friendModalClosed}
          readOnly
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { postID } = props;

  return {
    me: reProfileMe(state),
    bookmarks: reStampBookmarks(state),
    paragraphs: reParagraphs(state),
    myItems: reMyItems(state),
    slowlyItems: reKeyedItems(state),
    friend: state.contacts[postID],
    sendLetterReminder: state.slowly.sendLetterReminder,
    sending: state.letters.sending,
    error: state.letters.error,
    uuid: state.slowly.uuid,
    darkMode: state.slowly.darkMode,
    langs: reLangs(state),
  };
};

const beginWriting = function beginWriting(){
  return {
    type: 'BEGIN_WRITING'
  }
}
// const replyInit = function replyInit( payload ){
//   return {
//     type: 'LETTER_REPLY_INIT',
//     ...payload
//   }
// }

const _changeStamp = function _changeStamp({
  selectedStamp,
  automatch= false
}) {
  return {
    type: 'CHANGE_STAMP',
    selectedStamp,
    automatch
  };
};

const replySuccess = function replySuccess({comment, postID, uid}) {
  return {
    type: 'LETTER_REPLY_SUCCESS',
    comment,
    postID,
    uid,
  };
};


const refreshFriends = function refreshFriends() {
  return {
    type: 'GET_FRIENDS'
  };
};

const returnError = function returnError(error) {
  return {
    type: 'ME_ERROR',
    error: {
      error,
    },
  };
};

export default connect(
  mapStateToProps,
  {
    beginWriting,
    // replyInit,
    replySuccess,
    returnError,
    _changeStamp,
    refreshFriends
  }
)(Composer);
