import React from 'react';
import I18n from "../I18n";
// import colors from "../assets/colors";
import Avatar from "./Avatar";
import Emoji from "./Emoji";
import moment from "../lib/moment"
import { NavLink } from "react-router-dom"
import hashids from '../lib/hashids'
import mailInIcon from '../assets/images/mail-in.png'
import mailOutIcon from '../assets/images/mail-out.png'

export default class FriendListItem extends React.PureComponent {
  scrollTop = () => {
    window.scrollTo(0, 0);
  }

  render() {
    const { friend, meID } = this.props;
    const isFuture = moment.orig.utc(friend.latest_comment) > moment.orig.utc() ? true : false;

    return (
      <NavLink to={"/friend/"+hashids.encode(friend.id)} onClick={this.scrollTop} className="no-underline" activeClassName="selected">
        <div
          className={
            !!friend.user_status
              ? friend.user_status >= 1
                ? "d-flex border-bottom "
                : "d-flex border-bottom semi-trans"
              : "d-flex border-bottom semi-trans"
          }
          key={friend.id}
          >
          <div className="col-pixel-width-50 pl-2 pt-2">
            <Avatar
                key={friend.id}
                uid={friend.id}
                id={friend.avatar}
                gender={friend.gender}
                name={friend.name}
                size={42}
            />
          </div>
          <div className="col pl-2 pt-2 pb-2 text-truncate">
            <div className="d-flex text-truncate">
              <h6 className="col pl-0 pr-0 mt-1 mb-0 text-truncate ">{friend.name}</h6>
              <div className="col-auto pr-0 pl-1 d-flex">
                  {!!isFuture ? (
                  friend.latest_sent_by === meID ? (
                    <img src={mailOutIcon} alt={I18n.t('SENDING')} height={14} />
                  ) : (
                    <img src={mailInIcon}  alt={I18n.t('ARRIVING')} height={14} />
                  )
                ) : !!friend.latest_comment ? (
                  <div className="text-lighter smallest">
                    {moment.custom(friend.latest_comment, "calendar")}
                    {friend.latest_sent_by === meID
                      ? friend.unread === 0 && (
                          <i className="icon-reply text-lighter icon-smallest pl-1" />
                        )
                      : friend.unread === 0 && (
                          <i className="icon-circle text-calm icon-smallest pl-1" />
                      )}
                  </div>
                ): null }
                {friend.unread > 0 && (
                  <div
                    className="text-danger smallest ml-1"
                  >
                    {I18n.t("NEW")}
                  </div>
                )}
                {!!friend.birthdaySoon && (
                  <i className="icon-cake smallest text-calm ml-1" />
                )}
              </div>
            </div>
            <div className="text-black-50 d-flex p-0 m-0 align-items-center small text-truncate">
              { !!friend.plus && (
                <i className="icon-plus-inline text-gold mr-1" />
              )}
              {friend.fav === 1 && (
                <i className="icon-star text-calm mr-1" />
              )}
              { !!friend.emoji_status && friend.emoji_status +' '}
              {!!friend.customdesc
                    ? friend.customdesc
                    : friend.location_code==='REMOVED' ? I18n.t('NA') : I18n.country(friend.location_code)}
            </div>
          </div>
        </div>
      </NavLink>
    );
  }
}
