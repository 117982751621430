import React, { PureComponent } from 'react';

class Emoji extends PureComponent {
  render() {
    const { code, className } = this.props;
    if(!code) return null;
    
    return (
      <span
        className={className+ ' noto-emoji'}
      >
        { code }
      </span>
    )
  }
}
export default Emoji;
