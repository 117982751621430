import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import moment from "../lib/moment";
import { reProfileMe } from "../selectors";
import StaticSideMenu from "../components/StaticSideMenu";
import Avatar from "../components/Avatar";
import colors from "../assets/colors";
import AppAlert from "../components/AppAlert";

import _ from 'lodash'
import {
  deletePost,
  getBlockedFriends,
} from '../api/posts.api';

class RemovedFriends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      removed: [],
      loaded: false,
      shouldRefresh: false,
      alert: null
    };
  }

  _delete = async(user) => {
    this.setState({ loading: true })

    try{
      const result = await deletePost({ token: this.props.me.token, postID: user.id })
      global.log('deletePost', result)
      this.removeFromList(user.id)
    }catch(error){
      this.setState({ loading: false})
      global.log('error', error)
    }
  }

  removeFromList = id => {
    this.setState({
      removed: _.filter(this.state.removed, u => u.id!==id ),
      loading: false
    })
  }

  getRemoved = async() => {
    const { me: { token } } = this.props;
    const result = await getBlockedFriends({ token })

    global.log('getBlockedFriends', result)

    this.setState({
      removed: result,
      loaded: true,
    })
  }

  componentDidMount(){
    this.getRemoved();
  }

  componentWillUnmount(){
    if(!!this.state.shouldRefresh){
      const { me: { token } } = this.props;
      this.props.refreshFriends(token)
    }
  }

  confirmDelete = user => {

    this.setState({
      alert: {
        emoji: '⛔️',
        title: user.name,
        message: I18n.t('CONFIRM_DELETE_ACC_MSG'),
        cancelLabel: I18n.t("CANCEL"),
        cancelAction: this.closeAlert,
        confirmLabel: I18n.t("DELETE_FOREVER"),
        confirmAction: ()=>{
          this.closeAlert()
          this._delete( user )
        },
        danger: true
      }
    });
  }

  closeAlert = () => {
    this.setState({
      alert: null
    });
  };


  _unblock = user => () => {
    const { me: { token } } = this.props;
    this.props.unblock(token, user);

    this.setState({
      removed: _.reject(this.state.removed, o => o.id===user.id ),
      shouldRefresh: true
    })
  }

  render() {
    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu />
            </div>
            <div className="col-9 p-3">
              <div className="card shadow-sm  my-2">
                <div className="card-header">{I18n.t("BLOCKED_USERS_LABEL")}</div>
                <div className="card-body">
                  {
                    !this.state.loaded ? (
                      <small>
                        <span
                          className="spinner-grow spinner-grow-sm mr-2 text-warning"
                          role="status"
                          aria-hidden="true"
                        />
                        {I18n.t("LOADING")}
                      </small>
                    ): this.state.removed.length===0 ? (
                      <p className="small text-light pt-2">
                        { I18n.t('NO_BLOCKED') }
                      </p>
                    ) : this.state.removed.map( friend => (
                    <div key={"friend-"+ friend.id} className="d-flex align-items-center p-2">
                      <Avatar
                        key={friend.id}
                        uid={friend.id}
                        id={friend.avatar}
                        gender={friend.gender}
                        name={friend.name}
                        size={42}
                        borderColor={colors.borderLight}
                      />
                      <div className="ml-3 flex-grow-1 text-primary">
                        <strong>{ friend.name }</strong>
                        <div className="small text-light">
                          <i className="icon-envelope mr-1" /> { moment.custom(friend.latest_comment, 'fromNow') }
                        </div>
                      </div>
                      <div className="ml-3 text-right" >
                        <button className="btn btn-light btn-sm mr-1" onClick={this._unblock(friend)}>
                          { I18n.t('UNBLOCK_USER') }
                        </button>
                        <button className="btn btn-light btn-sm text-danger" onClick={
                          ()=> {
                              this.confirmDelete(friend)
                          }

                        }>
                          { I18n.t('DELETE_FOREVER') }
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppAlert alert={this.state.alert} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state)
  };
};

const refreshFriends = function refreshFriends(token) {
  return {
    type: 'GET_FRIENDS',
    token
  };
};

const unblock = function unblock(token, user) {
  return {
    type: 'UNBLOCK_USER',
    token,
    user
  };
};


export default connect(
  mapStateToProps,
  {
    unblock,
    refreshFriends
  }
)(RemovedFriends);
