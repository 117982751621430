import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { checkSubtopics, getSubtopics, saveTags, updateSubtopics } from "../api/me.api";
import { reProfileMe  } from "../selectors";
import StaticSideMenu from "../components/StaticSideMenu";
import _ from "lodash";
import { Modal } from "react-bootstrap";

class Topics extends Component {
  constructor(props) {
    super(props);

    const { me = {} } = props;
    const excluded_tags = _.map(me.excluded_tags, t => {
      return t.tag_slug;
    });

    const _tags = _.filter(this.props.tags, t => {
      return t.suggest >= 0 || me.tags.indexOf(t.slug) >= 0;
    });

    const tags = [
      ..._tags,
      {
        slug: 'OTHER_TOPICS',
        skip: true,
      },
    ];

    this.state = {
      touched: false,
      subTouched: false,
      subtopics: {},
      subtopicsRaw: !!this.props.me.subtopics ? this.props.me.subtopics : [],
      excluded_tags,
      tempTags: [...me.tags],
      tags: _.orderBy(
        me.age < 18 && me.tags.indexOf("sex") < 0
          ? _.reject(tags, t => t.slug === "sex")
          : tags,
        [
          function(o) {
            return me.tags.indexOf(o.slug) >= 0
              ? me.tags.indexOf(o.slug)
              : o.slug === "OTHER_TOPICS"
              ? 50
              : excluded_tags.indexOf(o.slug) >= 0
              ? 200
              : 100;
          },
          "count"
        ],
        ["asc", "desc"]
      )
    };
  }

  componentDidMount() {
    this.init();

    this.unblock = this.props.history.block((location, action) => {
      global.log("history", location);
      const unsavedChange = this.state.touched || this.state.subTouched || this.state.loading;
      if (!unsavedChange) return true;

      return (
        I18n.t("ASK_SAVE_AUTOMATCH") +
        " - " +
        I18n.t("CONFIRM_CANCEL_SIGNUP_MSG")
      );
    });

    window.addEventListener("beforeunload", this.warnUnload);
  }

  componentWillUnmount() {
    this.unblock();
    window.removeEventListener("beforeunload", this.warnUnload);
  }

  warnUnload = event => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') return true;
    if (this.state.touched || this.state.subTouched || this.state.loading)
      event.returnValue =
        I18n.t("ASK_SAVE_AUTOMATCH") +
        " - " +
        I18n.t("CONFIRM_CANCEL_SIGNUP_MSG");
  };

  init = async () => {
    const { subcheck, subtopics = [], token } = this.props.me;
    if (subtopics.length > 0) this.fixSubtopics();

    const checkResult = await checkSubtopics({ token });

    if (checkResult.subcheck) {
      if (checkResult.subcheck > subcheck || subtopics.length <= 0) {
        try {
          const subResult = await getSubtopics({ token });
          this.setState({
            subtopicsRaw: subResult.subtopics
          })
          if (subResult.subtopics && subResult.subtopics.length > 0)
            this.fixSubtopics(subResult.subtopics);

          this.props.saveSub(subResult);
          global.log("getSubtopics success");
        } catch (error) {
          global.log("getSub error", error);
        }
      }
    }
  };

  pickTag = slug => {
    if(this.state.loading) return false
    const index = this.state.tempTags.indexOf(slug);
    const { topicLimit } = this.props;
    let temp = this.state.tempTags;

    if (index > -1) {
      //remove tag
      if (temp.length <= 3) return false;
      temp.splice(index, 1);
    } else {
      if (temp.length >= topicLimit) return false;
      temp.push(slug);
    }

    this.setState(
      {
        touched: true,
        saved: false,
        tempTags: temp
      },
      this.fixSubtopics
    );
  };

  fixSubtopics = payload => {
    var mapped = {};
    const { tempTags, subtopicsRaw } = this.state;
    const subtopics = payload ? payload : subtopicsRaw;

    _.map(subtopics, (sub, index) => {
      if (tempTags.indexOf(sub.parent) >= 0) {
        if (!mapped[sub.parent]) mapped[sub.parent] = [];
        mapped[sub.parent].push({
          ...sub,
          index
        });
      } else {
        if (!mapped["OTHER_TOPICS"]) mapped["OTHER_TOPICS"] = [];
        mapped["OTHER_TOPICS"].push({
          ...sub,
          index
        });
      }
      return true;
    });

    this.setState(
      !!payload
        ? {
            subtopics: mapped,
            subtopicsRaw: payload,
            showAddSubtopic: false
          }
        : {
            subtopics: mapped,
            showAddSubtopic: false
          }
    );
  };

  _saveAll = async () => {
    const { token } = this.props.me;
    this.setState({ loading: true });
    const { tempTags } = this.state;

    try {
      if(this.state.touched){
        const result = await saveTags({
          token,
          tags: tempTags.join(",")
        });
        this.props.saveTopics({ tagged: result.tagged, regStep: null });
      }

      if(this.state.subTouched){
        const subResult = await updateSubtopics({
          token,
          payload: {
            data: JSON.stringify(this.state.subtopicsRaw),
            channel: 'web'
          },
        });
        this.props.saveSub(subResult)
      }

      const excluded_tags = _.map(this.props.me.excluded_tags, t => {
        return t.tag_slug;
      });
      const tags = [
        ...this.props.tags,
        {
          slug: "OTHER_TOPICS",
          skip: true
        }
      ];

      this.setState({
        touched: false, subTouched: false, loading: false, saved: true,
        tags: _.orderBy(
          this.props.me.age < 18 && tempTags.indexOf("sex") < 0
            ? _.reject(tags, t => t.slug === "sex")
            : tags,
          [
            function(o) {
              return tempTags.indexOf(o.slug) >= 0
                ? tempTags.indexOf(o.slug)
                : o.slug === "OTHER_TOPICS"
                ? 50
                : excluded_tags.indexOf(o.slug) >= 0
                ? 200
                : 100;
            },
            "count"
          ],
          ["asc", "desc"]
        )
      });

    } catch (err) {
      global.log(err);
      this.setState({ loading: false });
      if (!!err.error) this.props.showError(err.error);
      else this.props.showError("ERROR");
    }
  };

  mapTag = (tag, index) => {
    const { excluded_tags, tempTags } = this.state;

    const isExcluded = excluded_tags.indexOf(tag.slug) >= 0;
    const isSelected =
      tag.slug === "OTHER_TOPICS" || tempTags.indexOf(tag.slug) >= 0
        ? true
        : false;
    const subtopics = this.state.subtopics[tag.slug];
    if (!!tag.skip && !subtopics) return null;

    const reachedLimit = this.state.subtopicsRaw.length >= this.props.topicLimit

    return (
      <div
        className={
          "d-flex px-3 py-3 topic border-bottom align-items-top" +
          (isExcluded ? " excluded" : isSelected ? " selected" : "")
        }
        key={tag.slug}
      >
        <div className="flex-fill">
          <div style={{ marginTop: "0.2rem" }}>
            {isExcluded ? (
              <del className="text-lightest h5 font-weight-normal">
                {I18n.t(tag.slug)}
              </del>
            ) : (
              <span
                className={
                  tempTags.indexOf(tag.slug) >= 0
                    ? " selected-text link h5"
                    : " default-text link h5 font-weight-normal"
                }
                onClick={() => this.pickTag(tag.slug)}
              >
                {isSelected ? (
                  <strong>{I18n.t(tag.slug)}</strong>
                ) : (
                  I18n.t(tag.slug)
                )}
              </span>
            )}
          </div>
          {isSelected && (
            <div className="tags-holder h5 mt-2 mb-0">
              {!!subtopics &&
                subtopics.map((t, index) => {
                  return (
                    <span
                      key={"tag-" + index}
                      onClick={() => {
                        this.editSub(tag.slug, t.index);
                      }}
                      className="badge badge-pill badge-tag badge-subtopic link"
                    >
                      {I18n.t(t.name)}
                    </span>
                  );
                })}
                <span
                  key={"tag-add"}
                  onClick={() => {
                    if(!reachedLimit) this.editSub(tag.slug);
                  }}
                  className={ reachedLimit ? "badge badge-pill badge-tag semi-trans cursor-disabled" : "badge badge-pill badge-tag link"}
                >
                  <i className="icon-add" /> {I18n.t("SUBTOPIC")}
                </span>
            </div>
          )}
        </div>
        <div>
          <button
            className="btn btn-light btn-sm"
            style={{ width: 30 }}
            onClick={() => this.pickTag(tag.slug)}
            disabled={isExcluded}
          >
            <i
              className={
                isExcluded
                  ? "icon-ban text-lightest"
                  : isSelected
                  ? "icon-checkmark text-secondary"
                  : "icon-add text-primary"
              }
            />
          </button>
        </div>
      </div>
    );
  };

  editSub = (topic = null, index = null) => {
    if(this.state.loading) return false

    this.setState({
      editingTopic: topic,
      editingIndex: index,
      editingSub: index !== null ? this.state.subtopicsRaw[index].name : "",
      showAddSubtopic: true
    }, ()=>{
      if(this.subInput) this.subInput.focus()
    });
    global.log("editSub", { topic, index });

  };

  _save = e => {
    if (e) e.preventDefault();
    if (this.state.editingSub.length <= 0) return true;
    if(this.state.loading) return false

    let subtopics = this.state.subtopicsRaw;

    if (this.state.editingIndex !== null) {
      subtopics[this.state.editingIndex] = {
        name: _.trim(this.state.editingSub),
        parent: !!this.state.editingTopic
          ? this.state.editingTopic
          : "OTHER_TOPICS"
      };
    } else {
      subtopics.push({
        name: _.trim(this.state.editingSub),
        parent: !!this.state.editingTopic
          ? this.state.editingTopic
          : "OTHER_TOPICS"
      });
    }
    this.fixSubtopics(subtopics);
    this.setState({ subTouched: true, saved: false });

    return true;
  };

  _del = () => {
    if(this.state.loading) return false

    const subtopics = _.reject(this.state.subtopicsRaw, (value, index) => {
      return index === this.state.editingIndex;
    });
    this.fixSubtopics(subtopics);
    this.setState({ subTouched: true, saved: false });
  };

  onHide = () => {
    this.setState({
      showAddSubtopic: false
    });
    this.fixSubtopics();
  };

  onChangeSubtopic = e => {
    this.setState({
      editingSub: e.target.value
    });
  };

  onChangeTopic = e => {
    this.setState({
      editingTopic: e.target.value
    });
  };

  render() {
    const { tags, tempTags } = this.state;
    const { subtopics } = this.props.me;
    const { topicLimit } = this.props;
    const tagLength = tempTags.length;
    const subTagsLength = !subtopics ? 0 : subtopics.length;
    const showTotal = tagLength >= 20 || subTagsLength >= 20;

    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu />
            </div>
            <div className="col-9 p-3 pb-0">
              {/* TAGS */}
              <div className="card shadow-sm mt-2">
                <div className="card-header">
                  <div className="d-flex">
                    <strong className="flex-fill">
                      {I18n.t("INTERESTED_TOPICS")}
                    </strong>
                    <div className="small">
                      {I18n.t("TOPICS") + ": "}
                      <strong
                        className={tagLength >= topicLimit ? "text-danger" : ""}
                      >
                        {tagLength}
                      </strong>
                      {showTotal && "/" + topicLimit}
                      <span className="mx-1" />
                      {I18n.t("SUBTOPICS") + ": "}
                      <strong
                        className={
                          subTagsLength >= topicLimit ? "text-danger" : ""
                        }
                      >
                        {subTagsLength}
                      </strong>
                      {showTotal && "/" + topicLimit}
                    </div>
                  </div>
                </div>
                <div
                  className="card-body tags-holder py-2 px-2 overflow-auto"
                  dir="ltr"
                >
                  {tags.map(this.mapTag)}
                </div>
                <div className="card-footer">
                  <button
                    className="btn btn-primary btn-block px-3"
                    disabled={tagLength < 3 || tagLength > 30 || this.state.loading}
                    onClick={this._saveAll}
                  >
                    {!!this.state.saved ? (
                      <i className="icon-checkmark text-secondary" />
                    ) : this.state.loading ? "..." : I18n.t("SAVE")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showAddSubtopic}
          onHide={this.onHide}
          dialogClassName="modal-dialog modal-dialog-scrollable"
          aria-labelledby="contained-modal-title-vcenter"
          keyboard={false}
          centered
        >
          <Modal.Header className="bg-stable">
            <div className="w-100 position-relative ">
              <h5 className="text-primary p-0 m-0">{I18n.t("SUBTOPIC")}</h5>
              <div className="position-absolute" style={{ top: -8, right: -8 }}>
                <button className="btn btn-default" onClick={this.onHide}>
                  <i className="icon-close h5" />
                </button>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this._save}>
              <input
                ref={ ref => this.subInput = ref }
                key={'sub'+this.state.editingIndex}
                type="text"
                className="form-control"
                rows="1"
                onChange={this.onChangeSubtopic}
                value={this.state.editingSub}
                maxLength={20}
                autoFocus
              />
              <div className="input-group mt-2">
                <div className="input-group-prepend">
                  <label
                    className="input-group-text small"
                    htmlFor="labelSelect"
                  >
                    {I18n.t("UNDER_TOPIC")}
                  </label>
                </div>
                <select
                  id="labelSelect"
                  className="custom-select"
                  value={this.state.editingTopic}
                  onChange={this.onChangeTopic}
                >
                  {tempTags.map(tag => {
                    return (
                      <option
                        className="list-group-item link"
                        key={tag}
                        value={tag}
                      >
                        {I18n.t(tag)}
                      </option>
                    );
                  })}
                  <option
                    className="list-group-item link"
                    value={"OTHER_TOPICS"}
                  >
                    {I18n.t("OTHER_TOPICS")}
                  </option>
                </select>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="bg-stable w-100 d-flex">
            {this.state.editingIndex !== null && (
              <button
                type="button"
                className="btn btn-danger mr-2"
                onClick={this._del}
              >
                <i className="icon-trash-o" />
              </button>
            )}
            <div className="flex-grow-1">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={this._save}
              >
                {this.state.editingIndex !== null
                  ? I18n.t("SAVE")
                  : I18n.t("ADD")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    tags: state.slowly.tags,
    me: reProfileMe(state),
    topicLimit: 30
  };
};

const saveTopics = function saveTopics({ tagged, regStep }) {
  return {
    type: "SET_TOPICS_SUCCESS",
    tagged,
    regStep
  };
};

const updateExploreFilter = function updateExploreFilter() {
  return {
    type: "SAVE_EXPLORE_FILTERS"
  };
};

const newTagSeen = function newTagSeen(error) {
  return {
    type: "NEW_TAG_SEEN"
  };
};

const showError = function showError(error) {
  return {
    type: "ME_ERROR",
    error: {
      error
    }
  };
};

const saveSub = function saveSub({ subtopics, subcheck }) {
  return {
    type: "SAVE_SUBTOPICS",
    subtopics,
    subcheck
  };
};

export default connect(mapStateToProps, {
  saveTopics,
  updateExploreFilter,
  showError,
  newTagSeen,
  saveSub
})(Topics);
