import {
  INIT_APP,
  FETCH_SLOWLY_SUCCESS,
  SAVE_SLOWLY_VERSION,
  GOT_TOKEN,
  SHOW_ERROR,
  GOT_UUID,
  LOGOUT
} from "../actions/type";

import { pingSlowly, fetchSlowly } from "../api/slowly.api";

import {
  getUILocale,
  getDataVer,
  getUUID
} from "../selectors";

import { call, put, select, takeLatest } from "redux-saga/effects";
import moment from "moment";
import I18n from '../I18n'
import uuidv4 from 'uuid/v4';

function* loadingWatcher() {
  yield takeLatest(INIT_APP, _init);
}

function* _init({ token, role, newLocale=null }) {
  global.log("INIT_APP");

  try {
    // check data ver
    const pingData = yield call(pingSlowly);
    global.log("pingData", pingData);
    if (!!pingData.now) {
      const offset = new Date(pingData.now).getTime() - Date.now();
      global.log("offset", offset);
      moment.now = function() {
        return offset + Date.now();
      };
    }
    global.log("ping slowly", pingData);

    const storedDataVer = yield select(getDataVer);
    const latestDataVer = parseFloat(pingData.dataVer);
    const oldUILocale = yield select(getUILocale);

    yield put({
      type: SAVE_SLOWLY_VERSION
    });

    const uuid = yield select(getUUID);
    if(!uuid){
      yield put({
        type: GOT_UUID,
        uuid: uuidv4()
      });
    }

    if (latestDataVer > storedDataVer || !!newLocale ) {
      global.log("data outdated, get slowly data now");
      const slowlyData = yield call(fetchSlowly, { ver: latestDataVer });
      yield put({ type: FETCH_SLOWLY_SUCCESS, data: slowlyData });

      yield call(I18n.init, oldUILocale, latestDataVer, true);
    }else{
      yield call(I18n.init, oldUILocale, storedDataVer);
    }

    if (!!token) {
      yield put({
        type: GOT_TOKEN,
        token,
        location: null,
        location_code: null,
        device: null
      });

    } else {
      global.log("data ok, but no token found, go to home");
      yield put({ type: LOGOUT });
    }
  } catch (error) {
    global.log("INIT_APP error", error);
    if (error.msg === "maintenance_mode" || error.msg === "outdated_version") {
      error.error = error.msg;
    }
    yield put({ type: SHOW_ERROR, error });
    yield put({ type: LOGOUT });
  }
}

const loadingSaga = [ loadingWatcher ]

export default loadingSaga
