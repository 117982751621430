import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import StaticSideMenu from "../components/StaticSideMenu";
import Loader from  "../components/Loader"
import { availableLang } from '../config/ApiConfig'
import { getTrust } from "../selectors";
import {
  updateDevice
} from '../api/me.api'
import * as firebaseMsg from "firebase/messaging";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newLocale: null,
      show_last_login: parseInt(this.props.me.show_last_login) === 1 ? true : false,
      show_account_age: !!this.props.me.account_age ? true: false,
      show_weather: this.props.me.weather ? this.props.me.weather.status>=1 : false,
      notifications: this.props.me.pref.notifications,
    };
  }

  async componentDidMount(){
    const isSupported = await firebaseMsg.isSupported()
    this.setState({
      isSupported : isSupported && !!Notification
    })
  }

  _onChange = ev => {
    global.log(ev);
    this.setState({
      newLocale: ev.target.value
    })
  }

  _saveUILocale = async() =>{
    if(!this.state.newLocale) return false;

    this.props.saveUILocale(this.state.newLocale)
    this.props.initApp(this.props.me.token, this.state.newLocale)

    try{
      const newDevice = await updateDevice({
        token: this.props.me.token,
        uuid: this.props.uuid,
        lang: this.state.newLocale
      })
      global.log('device updated', newDevice)
    }catch(error){
      global.log('update device error', error)
    }
  }

  updateNotifications = (field, value) => {
    const { me, updateMe } = this.props;

    this.setState({
      notifications: {
        ...this.state.notifications,
        [field]: value
      }
    });

    updateMe(
      me.token,
      {
        pref: {
          ...me.pref,
          notifications: {
            ...this.state.notifications,
            [field]: value
          }
        }
      },
      true
    );
  };

  updateLastLogin = value => {
    const { me, updateMe } = this.props;

    this.setState({
      show_last_login: value
    });

    updateMe(
      me.token,
      {
        show_last_login: value ? 1 : 0
      },
      true
    );
  };

  updateShowAccountAge = value => {
    const { me, updatePref } = this.props;

    this.setState({
      show_account_age: value
    });

    updatePref(
      me.token,
      {
        show_account_age: value ? '1' : '0'
      }
    );
  };

  updateShowWeather = value => {
    const { me, updatePref } = this.props;

    this.setState({
      show_weather: value
    });

    updatePref(
      me.token,
      {
        weather: value ? '1' : '0'
      }
    );
  };

  toggleDarkMode = () => {
    this.props.switchDarkMode(!this.props.darkMode)
  }

  enabledNotification = async() => {
    if(!Notification) return false;
    const isSupported = await firebaseMsg.isSupported()
    if(!isSupported) return false

    this.setState({
      loading: true
    })

    const messaging = firebaseMsg.getMessaging()
    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
        global.log('Notification permission granted.');
        try{
          const currentToken = await firebaseMsg.getToken(messaging, {vapidKey: "BAz5bgdPlYjNxxHqclnomJxxaNqORVszGdGNqeuKXZiDHymTZym748bS0QFb9qnT9aFK7jEztSMGEoKUEp0_j3M"})
          if (currentToken && currentToken!==this.props.fcm) {
            // const firebaseRes = await getToken(global.appCheck);

            const newDevice = await updateDevice({
              token: this.props.me.token,
              uuid: this.props.uuid,
              fcm: currentToken,
              lang: this.props.locale,
              // appCheckToken: firebaseRes.token
            })
            global.log('newDevice', newDevice)
            this.props.saveFCM(currentToken)
          }
        }catch(error){
          global.log('FCM error', error)
        }

        this.setState({ loading: false })

    } else {
        global.log('Unable to get permission to notify.');
        this.setState({ loading: false })
    }
  }

  disableNotification = async() =>{
    this.setState({
      loading: true
    })

    const messaging = firebaseMsg.getMessaging()
    const result = await firebaseMsg.deleteToken(messaging)

    global.log('deleteToken result', result)

    // const firebaseRes = await getToken(global.appCheck);
    const newDevice = await updateDevice({
      token: this.props.me.token,
      uuid: this.props.uuid,
      fcm: 0,
      // appCheckToken: firebaseRes.token
    })
    global.log('remove fcm', newDevice)
    this.props.saveFCM(0)

    this.setState({
      loading: false
    })

  }

  render() {
    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu />
            </div>
            <div className="col-9 p-3">
              <div className="card shadow-sm  my-2">
                <div className="card-header">{I18n.t("APP_LANGUAGE")}</div>
                <div className="card-body">
                  <div className="input-group">
                    <select className="custom-select" onChange={this._onChange} defaultValue={this.props.locale}>
                      { availableLang.map( lang => (
                        <option key={lang} value={lang}>{I18n.t(lang)}</option>
                      ))}
                    </select>
                    <div className="input-group-append">
                      <button className="btn btn-primary"
                        type="button"
                        onClick={this._saveUILocale}
                        disabled={this.state.newLocale===null || this.props.locale === this.state.newLocale}>
                        { I18n.t('SAVE') }
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card shadow-sm  my-2">
                <div className="card-body">
                  <div className="custom-control custom-switch link">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={!!this.props.darkMode}
                      onChange={this.toggleDarkMode}
                      id="darkModeSwitch"
                    />
                    <label className="custom-control-label" htmlFor="darkModeSwitch">
                      {I18n.t('DARK_MODE')}
                    </label>
                  </div>
                </div>
              </div>
              { this.state.isSupported && this.props.trusted && (
              <div className="card shadow-sm my-2">
                  <div className="card-body">
                    <div className="custom-control custom-switch link">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={Notification &&  Notification.permission==='granted' && !!this.props.fcm}
                        onChange={ ()=>{
                          if(Notification &&  Notification.permission==='granted' && !!this.props.fcm){
                            this.disableNotification()
                          }else{
                            this.enabledNotification()
                          }
                        }}
                        disabled={!Notification || this.state.loading}
                        id="notiSwitch"
                      />
                      <label className="custom-control-label" htmlFor="notiSwitch">
                        {I18n.t("REG_NOTIFY") + ' - '+ I18n.t('SLOWLY_WEB')}
                      </label>
                    </div>
                    { Notification && Notification.permission==='denied' && (
                      <div className="text-smaller mt-2 text-light">
                        *{I18n.t('PUSH_NOTI_OFF') }
                      </div>
                    )}
                  </div>

              </div>)}

              <div className="card shadow-sm my-2">
                <div className="card-header">{I18n.t("PRIVACY_SETTINGS")}</div>
                <div className="card-body">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="show_last_login"
                      checked={this.state.show_last_login}
                      onChange={ () => {
                        this.updateLastLogin(!this.state.show_last_login)
                      }}
                    />
                    <label className="custom-control-label" htmlFor="show_last_login">{I18n.t('SHOW_LAST_LOGIN')}</label>
                  </div>
                  <div className="custom-control custom-checkbox mt-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="show_account_age"
                      checked={this.state.show_account_age}
                      onChange={ () => {
                        this.updateShowAccountAge(!this.state.show_account_age)
                      }}
                    />
                    <label className="custom-control-label" htmlFor="show_account_age">{I18n.t('SHOW_ACCOUNT_AGE')}</label>
                  </div>
                  <div className="custom-control custom-checkbox mt-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="show_weather"
                      checked={this.state.show_weather}
                      onChange={ () => {
                        this.updateShowWeather(!this.state.show_weather)
                      }}
                    />
                    <label className="custom-control-label" htmlFor="show_weather">
                      {I18n.t('SHOW_WEATHER_AND_LOCAL_TIME')}
                    </label>
                  </div>
                </div>
              </div>

              <div className="card shadow-sm my-2">
                <div className="card-header">{I18n.t("SLOWLY_TIPS")}</div>
                <div className="card-body">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="send-letter-reminder"
                      checked={this.props.sendLetterReminder}
                      onChange={ () => {
                        this.props.updateSendLetterReminder(!this.props.sendLetterReminder)
                      }}
                    />
                    <label className="custom-control-label" htmlFor="send-letter-reminder">{I18n.t('SEND_LETTER_REMINDER')}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        { !!this.props.restarting && (
          <Loader style={{ opacity: .6 }} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: state.me,
    locale: state.slowly.locale,
    sendLetterReminder: state.slowly.sendLetterReminder,
    darkMode: !!state.slowly.darkMode,
    uuid: state.slowly.uuid,
    fcm: state.slowly.fcm,
    version: state.slowly.appVerString,
    restarting: state.slowly.loading,
    trusted: getTrust(state)
  };
};

const updateMe = function updateMe(token, updateFields, skip = false) {
  return {
    type: 'UPDATE_ME',
    token,
    updateFields,
    skip
  };
};

const updatePref = function updatePref(token, updateFields){
  return {
    type: 'UPDATE_PREF',
    token,
    updateFields
  };
}

const saveUILocale = function saveUILocale(locale){
  return{
    type: 'SAVE_UI_LOCALE_SUCCESS',
    locale
  }
}

const initApp = function initApp(token, newLocale){
  return{
    type: 'INIT_APP',
    token,
    newLocale
  }
}

const updateSendLetterReminder = function updateSendLetterReminder(value){
  return{
    type: 'UPDATE_SEND_LETTER_REMINDER',
    value
  }
}

const switchDarkMode = function switchDarkMode(darkMode){
  return{
    type: 'SWITCH_DARKMODE',
    darkMode
  }
}

const saveFCM = function saveFCM(fcm){
  return{
    type: 'REG_DEVICE',
    fcm
  }
}

export default connect(
  mapStateToProps,
  {
    saveUILocale,
    updateSendLetterReminder,
    initApp,
    updateMe,
    updatePref,
    switchDarkMode,
    saveFCM
  }
)(Settings);
