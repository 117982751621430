import { createSelector } from "reselect";
import moment from "../lib/moment";
import _ from "lodash";
import I18n from "../I18n"

export const getUUID = state => state.slowly.uuid;
export const getTrust = state => state.slowly.trust===false ? false : true;

export const getMe = state => state.me;

const getMeForItems = state => ({
  items: state.me.items,
  showCollectionNotify: state.me.showCollectionNotify,
  newStamps: state.me.newStamps,
  newCountry: state.me.newCountry,
  bookmarks: state.me.bookmarks,
  location_code: state.me.location_code,
});

const getLangs = state => state.slowly.lang

export const getStore = state => state.slowly.store;
export const getToken = state => state.me.token;
export const getLastCheck = state => state.letters.lastCheck;
export const getLatestLastCheck = state => state.letters.latest.now;
export const getPhotoQuota = state => {
  return {
    sharequota: !!state.slowly.sharequota ? state.slowly.sharequota : 10,
    sharequotareset: !!state.slowly.sharequotareset
      ? state.slowly.sharequotareset
      : 12
  };
};

export const getRemindedIDs = state => !!state.contacts['reminder-'+state.me.id] ? state.contacts['reminder-'+state.me.id] : []


export const getRetryCount = state => state.me.retry;
export const getDisableAutoLoc = state => state.slowly.disableAutoLoc;
export const getBackupCountryCode = state => state.slowly.country;

export const getUILocale = state => state.slowly.locale;
export const getCustomLocale = state => state.slowly.customLocale;

export const searchingID = state => {
  if(state.letters.searchText==='') return false;
  return state.letters.searching
}

export const getMyID = state => state.me.id;
export const getPref = state => state.me.pref;

const postsData = state => state.letters.data;
const contactsData = state => state.contacts;
const draftsData = state => state.drafts;
const getAvatarData = state => state.slowly.avatarData;

const getCollectionData = (state,props) => props.collection ? props.collection: state.me.collection

export const getErrorCount = state => state.me.retry;

export const getFriendIds = state => state.contacts.friendIds;
// export const getPermission = state => state.app.permission
export const storedHello = state =>
  state.slowly.hello ? state.slowly.hello : null;
export const storedLang = state =>
  state.slowly.langData ? state.slowly.langData : null;

export const getDataVer = state => parseFloat(state.slowly.ver);
// export const getAppVer = state => state.slowly.appVer;
// export const getAppVerString = state => state.slowly.appVerString;

export const getAcceptedTerms = state =>
  !!state.me.accept_terms ? true : false;

// const getActiveRequests = state => state.contacts.activeRequests
const getLetters = state => state.letters;
const getLatest = state => state.letters.latest;

const getFriendPosts = (state, props) =>
  !!state.letters[props.postID] ? state.letters[props.postID] : null;
const getMyTags = (state, props) => state.me.tags;
const getMyFriendTags = (state, props) =>
  !!state.contacts[props.postID]
    ? !!state.contacts[props.postID].tagged
      ? state.contacts[props.postID].tagged
      : []
    : [];
const getFriend = (state, props) =>
  !!state.contacts[props.postID] ? state.contacts[props.postID] : {};

const getShownItem = (state, props) => props.item_slug;

export const getTempAvatar = state => {
  return {
    avatar: state.me.avatar,
    avatarbuilder: !!state.me.avatarbuilder
      ? state.me.avatarbuilder.value
      : null
  };
};

export const getPost = (post, data) => {
  if (!post) return [];
  if (!post.ids) return [];
  // global.log('Mapping data')
  return post.ids.filter(id => !!data[id]).map(id => data[id]);
};

export const reStamp = () => {
  return createSelector(
    [getItems, getShownItem, getMe],
    (items, slug, me) => {
      const item = _.findLast(items, { slug });
      const exists = _.findIndex(me.items, { item_slug: slug });

      return {
        ...item,
        exists: exists >= 0
      };
    }
  );
};

export const reFriendPosts = () =>
  createSelector(
    [postsData, getFriend, getFriendPosts, getMyID, getSearchText],
    (data, getFriend, post, meID, searchParams) => {
      const friend = getFriend;
      const { searchMode, searchText, filter=null } = searchParams

      if (!post || (searchMode && searchText==='' && filter===null)) {
        return {
          current_page: null,
          next_page_url: null,
          lastIndex: null,
          data: [],
          friend,
          arrived: 0,
          incoming: 0,
          searchMode,
          searchText,
          filter
        };
      }

      const filtered = post.ids
          .filter(id => {
            if(!data[id]) return false
            if(!searchMode) return true

            if(!data[id].body) return false;
            if(data[id].type===6) return false; //new friend passed

            const foundText = _.includes(data[id].body.toLowerCase(), searchText.toLowerCase())
            if(filter!==null){
              if(filter==='audio') return data[id].type===5 && (foundText || searchText==='')
              if(filter==='photos') return data[id].attachments && (foundText || searchText==='')
              // if(filter==='unread') return (!data[id].read_at && data[id].user!==meID) && (foundText || searchText==='')
              if(filter==='gift') return data[id].type===3 && (foundText || searchText==='')
            }
            return foundText
          })

      const mappedData = !!post.ids ? filtered.map(id => {
              if(data[id].type === 3){
                return {
                  ...data[id],
                  summary: I18n.t('GIFT_MESSAGE_TITLE', {name: data[id].name}),
                };
              }

              if(searchText!==null && searchText!==''){
                const begin = data[id].body.toLowerCase().indexOf(searchText.toLowerCase());
                // const length = 100 - searchText.length - 3;
                const highlight = begin===0 ? data[id].body.substring(begin, 100) :
                      '...' + data[id].body.substring(begin, 100)

                return {
                  ...data[id],
                  summary: highlight,
                };
              }


              const summary =
                data[id].type === 6
                  ? I18n.t('NEW_FRIEND_PASSED_PRE', {name: data[id].name})
                  : _.truncate(data[id].body, {
                      length: 100,
                      omission: '',
                    });

              return {
                ...data[id],
                summary,
              };
            })
        : [];

      return {
        current_page: post.current_page,
        next_page_url: post.next_page_url,
        lastIndex: post.lastIndex,
        data: _.orderBy(mappedData, ['deliver_at'], ['desc']),
        friend,
        arrived: !!friend.isNew
          ? _.filter(mappedData, l => {
              return !!l.read_at || l.body !== null;
            }).length
          : 0,
        incoming: !!friend.isNew
          ? _.filter(mappedData, l => {
              return l.body === null;
            }).length
          : 0,
        searchMode,
        searchText,
        filter
      };
    },
  );


export const reCommonTags = () => {
  return createSelector(
    [getMyTags, getMyFriendTags],
    (myTags, friendTags) => {
      if (!friendTags) return [];

      let commonTags = [];
      friendTags.map(tag => {
        if (myTags.indexOf(tag.tag_slug) !== -1)
          commonTags.push({
            tag_slug: tag.tag_slug
          });

        return true;
      });

      return commonTags;
    }
  );
};

export const reSharingPhotoLabel = () =>
  createSelector(
    [getFriend, getMyID],
    (friend, meID) =>{
      if(!!friend.allowphotos) return "REQUEST_ACTIVE"

      if(friend.user===meID){
        //I am user
        if(!friend.user_photos && (!!friend.joined_photos || !!friend.photorequest) )
          return "ACCEPT_REQUEST"
        if(!!friend.user_photos)
          return "PENDING_REQUEST"
        else
          return "REQUEST"
      }else{
        //I am joined
        if(!friend.joined_photos && (!!friend.user_photos || !!friend.photorequest) )
          return "ACCEPT_REQUEST"
        if(!!friend.joined_photos)
            return "PENDING_REQUEST"
          else
            return "REQUEST"
      }
    }
  );

export const reSharingAudioLabel = () =>
  createSelector(
    [getFriend, getMyID],
    (friend, meID) => {
      if(!!friend.allowaudio) return "REQUEST_ACTIVE"

      if(friend.user===meID){
        //I am user
        if(!friend.user_audio && (!!friend.joined_audio || !!friend.audiorequest) )
          return "ACCEPT_REQUEST"
        if(!!friend.user_audio)
          return "PENDING_REQUEST"
        else
          return "REQUEST"
      }else{
        //I am joined
        if(!friend.joined_audio && (!!friend.user_audio || !!friend.audiorequest) )
          return "ACCEPT_REQUEST"
        if(!!friend.joined_audio)
          return "PENDING_REQUEST"
        else
          return "REQUEST"
      }
    }
  );

export const reFullCommonTags = () => {
  return createSelector(
    [getMyTags, getMyFriendTags],
    (myTags, friendTags) => {
      if (!friendTags) return [];

      let commonTags = [];
      friendTags.map(function(tag) {
        commonTags.push({
          tag_slug: tag.tag_slug,
          isCommon: myTags.indexOf(tag.tag_slug) !== -1 ? true : false
        });

        return true;
      });

      return commonTags;
    }
  );
};
const isPLUS = state => state.me.role>=20

// const getFriendLimit = state =>
//   !!state.slowly.friendLimit ? state.slowly.friendLimit : 100;

const getTopicLimit = state =>
  !!state.slowly.topicLimit ? state.slowly.topicLimit : 30;

export const reFriendLimit = createSelector(
  isPLUS,
  plus => (
    !!plus ? 200 : 100
  ),
);
export const reTopicLimit = createSelector(
  getTopicLimit,
  limit => limit
);

export const reFirstArrive = createSelector(
  getLetters,
  letters => {
    if (letters.incoming.length === 0) return null;
    else return letters.incoming[0].deliver_at;
  }
);

export const reIncomingCounter = createSelector(
  getLetters,
  letters => letters.incomingCounter
);

export const reUnreadCounter = createSelector(
  getLetters,
  letters => letters.unreadCounter
);

export const reActiveRequests = createSelector(
  contactsData,
  contacts => contacts.activeRequests
);

export const reLatestLetters = createSelector(
  [postsData, getLatest],
  (data, latest) => {
    return {
      ...latest,
      data: !!latest.ids
        ? latest.ids
            .filter(id => !!data[id])
            .map(id => {
              return {
                ...data[id],
                summary: _.truncate(data[id].body, {
                  length: 100,
                  omission: ""
                })
              };
            })
        : []
    };
  }
);

const getOrientation = state => state.slowly.orientation;

export const reOrientation = createSelector(
  getOrientation,
  orientation => orientation
);

const getItems = state => state.slowly.items;

const getSearchText = (state, props) => {
  if(state.letters.searching!==props.postID)
  return {
    searchMode: false,
    searchText: null,
    filter: null
  };
  return {
    searchMode: true,
    searchText: state.letters.searchText,
    filter: state.letters.filter
  }
}

export const reKeyedItems = createSelector(
  getItems,
  items => _.keyBy(items, "slug")
)

export const reFoundItems = createSelector(
  getMe,
  me => me.collection.length
);

export const reCollection = createSelector(
  [getCollectionData, getMe, getItems],
  (_collection, me, items) => {
    let collection = [], gotCountry = [], countryLog = [];

    items.map((item, i) => {
      item.got = _collection.indexOf(item.slug) >= 0;
      const countryCheck = !!item.country && countryLog.indexOf(item.country)<0 ? item.country : false;
      if(countryCheck) countryLog.push(item.country)
      if(!!item.got && item.country) gotCountry.push(item.country)

      if (!!item.got || !!countryCheck) collection.push(item);
      return true;
    });

    collection = _.reject(collection, function(o){
      return !o.got && !!o.country && gotCountry.indexOf(o.country)>=0
    })

    const free = _.filter(collection, function(o) {
      return (
        o.slug==='free'
      );
    });

    const part0 = _.sortBy(
      _.filter(collection, function(o) {
        return (
          o.item_group==='achievement'
        );
      }),
      ['weight'],
    );

    const part1 = _.sortBy(
      _.filter(collection, function(o) {
        return (
          o.item_group !== 'festive' &&
          o.item_group !== 'int-days' &&
          o.type !== 'birthday' &&
          !o.country &&
          o.item_group!=='achievement'
        );
      }),
      ['type', 'id'],
    );
    const part2 = _.sortBy(
      _.filter(collection, function(o) {
        return (
          (o.type === 'birthday' ||
            o.item_group === 'festive' ||
            o.item_group === 'int-days') &&
          !o.country &&
          o.item_group!=='achievement'
        );
      }),
      ['type', 'item_group'],
    );
    const part3 = _.sortBy(_.filter(collection, o => !!o.country), ['country']);

    return {
      collection: _.uniq([ ...free, ...part0, ...part1, ...part2, ...part3]),
      gotCountry: _.uniq(gotCountry).length,
      total: _collection.length
    };
  }
);

export const reMe = createSelector(
  [getMe],
  me => {
    return {
      id: me.id,
      hashid: me.hashid,
      token: me.token,
      showStamp: !!me.showStamp,
      loading: !!me.loading,
      newCountry: me.newCountry,
      location_code: me.location_code,
      messages: me.messages,
      newStamps: !!me.newStamps ? me.newStamps : [],
      newStampsIsSet: !!me.newStampsIsSet,
      foundStamp: me.foundStamp,
      device: me.device,
      role: me.role,
      last_login: me.last_login,
      updated_at: me.updated_at
    };
  }
);

export const totalFriends = createSelector(
  contactsData,
  contacts => contacts.friendIds.length + contacts.hidden.length
);

export const reContacts = createSelector(
  [ contactsData, getFriendIds],
  (contacts, ids) => {
    const sortedFriends = ids.filter(id => !!contacts[id] ).map( id => contacts[id] );
    global.log('sortedFriends', sortedFriends);

    const field = !!contacts.sortby ? contacts.sortby : 'unread';
    const fields = _.uniq([field, 'fav', 'unread', 'latest_comment', 'total', 'created_at', 'distance'])
    const friendIds = field==='alphabetical' ? _.map( _.sortBy(sortedFriends, [user => user.name.toLowerCase()]), 'id') :
                      _.map( _.orderBy(sortedFriends, fields, ['desc','desc','desc','desc','desc','desc']), 'id')
    global.log('reContacts', friendIds);

    return {
      friendIds,
      hidden: contacts.hidden,
      loading: contacts.loading,
      sortby: field,
      data: friendIds.filter(id => !!contacts[id] ).map( id => contacts[id] )
    }
  }
)

export const reMySetMenu = createSelector(
  [getMeForItems, getItems],
  (me, items) => {
    const slowlySet = _.keyBy(items.filter(item => !!item.is_stamp_set), 'slug');

    const mySets = _.uniqBy(
      me.items
        .filter(item => !!slowlySet[item.item_slug])
        .map(item => slowlySet[item.item_slug].is_stamp_set),
      'id',
    );

    return [
      'STAMP_ALL',
      ...mySets.map(item => item.title),
      'STAMP_PREMIUM',
      'STAMP_ACHIEVEMENTS',
      'STAMP_SPECIAL_DAYS',
      'STAMP_LOCATION_BASED',
    ];
  },
);

export const reMyItems = createSelector(
  [getMeForItems, getItems],
  (me, items) => {
    const slowlyItems = _.keyBy(_.uniqBy(items, 'slug'), 'slug');
    return _.orderBy(
      me.items.map(item => ({
        ...item,
        ...slowlyItems[item.item_slug],
        desc: slowlyItems[item.item_slug]
          ? slowlyItems[item.item_slug].desc
          : null,
        item_name: slowlyItems[item.item_slug]
            ? slowlyItems[item.item_slug].name
            : item.item_name,
        created_at: item.created_at,
        created_at_formatted: moment.custom(item.created_at, 'long'),
        isNew: !!(
          me.newStamps.indexOf(item.item_slug) >= 0 ||
          (me.newCountry && me.location_code === item.country)
        ),
        bookmark: !me.bookmarks
          ? false
          : me.bookmarks.indexOf(item.item_slug) >= 0,
        id: item.id
      })),
      ['country', 'item_set_id', 'id'],
      ['desc', 'desc', 'asc'],
    );
  },
);

export const reStampBookmarks = createSelector(
  [getMeForItems, reMyItems],
  (me, items) => {
    if (!me.bookmarks) return [];
    if (me.bookmarks.length === 0) return [];

    const myItems = _.keyBy(items, 'slug');
    return me.bookmarks.filter(o => !!myItems[o]);
  },
);

export const reAchievements = createSelector(
  [reMyItems, getItems],
  (myItems, items) => {
    const unlocked = _.map(
      _.filter(myItems, { item_group: 'achievement' }),
      'item_slug',
    );

    const keyed = _.keyBy(myItems, 'item_slug')

    const _achievements = _.map(_.filter(items, i => {
      return i.item_group==='achievement' && (i.rarity<=1 || !!keyed[i.slug])
    }), _a => {
        if(keyed[_a.slug]){
          return {
            ..._a,
            ...keyed[_a.slug],
            unlocked: true
          };
        }else{
          return {
            ..._a,
            item_name: _a.name,
            item_slug: _a.slug,
            unlocked: false
          };
        }

      })

    return {
      all: _.orderBy(_achievements, ['weight'], ['asc']),
      unlocked: unlocked.length,
    };
  },
);

export const reAvatarData = createSelector(
  getAvatarData,
  data => {
    return {
      ...data,
      last_index: !!data.last_index ? data.last_index : 265
    };
  }
);

export const reUnlocked = createSelector(
  getMe,
  me => {
    return _.uniq([ ..._.map(me.items, 'item_slug'), ...me.newStamps ]);
  }
);

export const reProfileMe = createSelector(
  getMe,
  me => {
    const tags = Array.isArray(me.tags) ? me.tags : []
    const subtopicsCounter = !me.subtopics ? {} : _.keyBy(_.map(tags, t => {
      const counter = _.filter(me.subtopics, s => s.parent===t).length;
      return {
        tag: t,
        counter
      }
    }), 'tag');
    const myItems = _.keyBy(me.items, 'item_slug');

    return {
      id: me.id,
      email: me.email,
      role: me.role,
      show_badge: me.show_badge,
      slowlyid: me.slowlyid,
      billing_id: me.billing_id,
      hashid: me.hashid,
      age: !!me.age ? me.age : -1,
      token: me.token,
      show_last_login: me.show_last_login,
      account_age: me.account_age,
      showStampNotify: me.showStampNotify,
      showAchievementNotify: me.showAchievementNotify,
      showCollectionNotify: me.showCollectionNotify,
      newStamps: Array.isArray(me.newStamps) ? me.newStamps : [],
      new_request: me.new_request,
      dob: me.dob,
      dob_string: !!me.dob && moment.custom(me.dob, 'birthday'),
      dob_privacy: me.dob_privacy,
      isBirthday: me.isBirthday,
      coffee: _.find(me.items, { item_slug: 'coffee' }),
      pref: me.pref,
      location_code: me.location_code,
      gender: me.gender,
      avatar: me.avatar,
      name: me.name,
      original_name: me.original_name,
      lang: Array.isArray(me.lang) ? me.lang : [],
      locale: me.locale,
      zodiac: me.zodiac,
      collectionLength: _.uniq(me.collection).length,
      itemsLength: _.uniqBy(me.items, 'item_slug').length,
      excluded_countries: Array.isArray(me.excluded_countries) ? me.excluded_countries : [],
      excluded_tags: Array.isArray(me.excluded_tags) ? me.excluded_tags : [],
      created_at: me.created_at,
      tags,
      about: me.about,
      target_gender: me.target_gender,
      target_age_from: me.target_age_from,
      target_age_to: me.target_age_to,
      emoji_status: me.emoji_status ? me.emoji_status : null,
      device: me.device,
      ratioText: me.ratioText,
      coins: me.coins ? me.coins : 0,
      // review_guideline: !!me.review_guideline,
      add_by_id: me.add_by_id ? me.add_by_id.status : true,
      avatarbuilder: !!me.avatarbuilder ? me.avatarbuilder.value : null,
      avatarstatus: !!me.avatarbuilder ? me.avatarbuilder.status : 0,
      lastUsedStamp: !!me.pref.stamp ? me.pref.stamp : 'free',
      star: _.find(me.items, ['item_slug', 'you-are-a-star']),
      showWeather: !me.weather || me.weather.status>=1,
      weather: me.weather,
      updated_at: me.updated_at,
      reminder: me.reminder,
      paragraph: me.paragraph,
      promo: !!me.promo && moment.orig.utc(me.promo.expire_at) > moment.orig.utc() ? me.promo : null,
      subtopics: !!me.subtopics ? me.subtopics : [],
      subcheck: me.subcheck,
      subtopicsCounter,
      bookmarks: !!me.bookmarks ? _.filter(me.bookmarks, o => !!myItems[o])  : [],
      fav: !!me.fav ? _.filter(me.fav, o => !!myItems[o]) : [],
      extend_pref: _.keyBy(me.extend_pref, 'type')
    }
  },
);

export const reParagraphsCount = createSelector(
  [draftsData, getMyID],
  (data, myID) => {
    return data['p-' + myID] ? data['p-' + myID].data.length : null
  }
)

export const reParagraphs = createSelector(
  [draftsData, getMyID],
  (data, myID) => {
    return !data['p-' + myID]
      ? {
          data: [],
          labels: [],
          labelKeys: {},
          last_sync: null
        }
      : {
        data: _.map(_.reject(data['p-' + myID].data, d => !d.body), d => {
          return {
            ...d,
            summary: _.truncate(d.body, {
                length: 150,
                omission: '...',
            })
          }
        }),
        labels: data['p-' + myID].labels ? data['p-' + myID].labels : [],
        labelKeys: data['p-' + myID].labelKeys ? data['p-' + myID].labelKeys: {},
        last_sync: data['p-' + myID].last_sync
      }
  }
)

export const reLangs = createSelector(
  [getLangs],
  langs => {
    return _.keyBy(langs, 'slug')
  }
)