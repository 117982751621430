import React from 'react';
import { NavLink } from 'react-router-dom'
import I18n from '../I18n'

class StaticSideMenu extends React.Component {

  render() {
    return (
      <div className="sticky-top sidebar pt-4 pl-1">
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink className="nav-link" to="/profile" exact activeClassName="active">
              {I18n.t("PROFILE")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/settings/topics" exact activeClassName="active">
              - {I18n.t("INTERESTED_TOPICS")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/settings/languages" exact activeClassName="active">
              - {I18n.t("LANGS")}
            </NavLink>
          </li>
        </ul>
        <hr />
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink className="nav-link" to="/settings/preferences" exact activeClassName="active">
              {I18n.t("MATCHING_PREF")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/settings/topics/excluded" exact activeClassName="active">
              - {I18n.t("EXCLUDE_TOPICS")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/settings/regions/excluded" exact activeClassName="active">
              - {I18n.t("EXCLUDED_COUNTRIES")}
            </NavLink>
          </li>
        </ul>
        <hr />
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink className="nav-link" to="/subscription" exact activeClassName="active">
              Slowly Plus
            </NavLink>
          </li>
        </ul>
        <hr />
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink className="nav-link" to="/settings" exact activeClassName="active">
              {I18n.t("SETTINGS")}
            </NavLink>
          </li>
        </ul>
        <hr />
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink className="nav-link" to="/friends/hidden" exact activeClassName="active">
              {I18n.t("HIDDEN_USERS_LABEL")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/friends/removed" exact activeClassName="active">
              {I18n.t("BLOCKED_USERS_LABEL")}
            </NavLink>
          </li>
        </ul>
        <hr />
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink className="nav-link" to="/about" exact activeClassName="active">
              {I18n.t("ABOUT_SLOWLY")}
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}


export default StaticSideMenu;
