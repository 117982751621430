import {
  PAYMENT_API_URL,REDIRECT_URL, WEB_VERSION_INT,
  headers, handleApiErrors, } from '../config/ApiConfig'

export const checkPromo = ({ token }) => {
  return fetch(PAYMENT_API_URL + '/store/promo?token=' + token , {
    method: 'GET',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}


export const getSub = ({ token }) => {
  return fetch(PAYMENT_API_URL + '/web/payment/status?token=' + token, {
    method: 'GET',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const updateWebPayment = ({ token, customer_id, sub_id, currency, success_url, cancel_url }) => {
  return fetch(PAYMENT_API_URL + '/web/sub/payment',
  {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      customer_id,
      sub_id,
      currency,
      success_url,
      cancel_url
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}


export const resumePlan = ({ token, external_id }) => {
  return fetch(PAYMENT_API_URL + '/web/sub/resume?token=' + token +'&external_id=' +external_id,
  {
    method: 'POST',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const cancelPlan = ({ token, external_id  }) => {
  return fetch(PAYMENT_API_URL + '/web/sub/cancel?token=' + token+'&external_id=' +external_id, {
    method: 'POST',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const getPortalLink = ({ token}) => {
  return fetch(PAYMENT_API_URL + '/web/payment/portal?token=' + token
  + '&return_url=' + REDIRECT_URL + '/settings/plus', {
    method: 'GET',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const getDataPublic = ({ location_code=null, promo=null}) => {
  return fetch(PAYMENT_API_URL + '/web/payment/data/public', {
    method: 'POST',
    headers,
    body: JSON.stringify({
      location_code,
      promo,
      version: WEB_VERSION_INT
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const getData = ({token, location_code=null, promo=null }) => {
  return fetch(PAYMENT_API_URL + '/web/payment/data', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      location_code,
      promo,
      version: WEB_VERSION_INT
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const createSub = ({ token, payload }) => {
  global.log('createSub payload', payload);
  return fetch(PAYMENT_API_URL + '/web/subscription/create?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const checkoutSession = ({ token, payload }) => {
  global.log('createSub payload', payload);
  return fetch(PAYMENT_API_URL + '/web/payment/checkoutsession?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const setupIntent = ({ token, payload }) => {
  return fetch(PAYMENT_API_URL + '/web/payment/intent?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const checkSource = ({ token, payload }) => {
  return fetch(PAYMENT_API_URL + '/web/payment/source/check?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const validateIntent = ({ token, intent_id, device_id}) => {
  return fetch(PAYMENT_API_URL + '/web/payment/intent/check?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      intent_id, device_id
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const removeCard = ({ token }) => {
  return fetch(PAYMENT_API_URL + '/web/payment/remove?token=' + token, {
    method: 'POST',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}