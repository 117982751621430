import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe, totalFriends, reFriendLimit } from "../selectors";
import StaticSideMenu from "../components/StaticSideMenu";
import _ from "lodash";

const baseList = [
  { label: "0", value: 0 },
  { label: "20", value: 20 },
  { label: "25", value: 25 },
  { label: "30", value: 30 },
  { label: "35", value: 35 },
  { label: "40", value: 40 },
  { label: "45", value: 45 },
  { label: "50", value: 50 },
  { label: "55", value: 55 },
  { label: "60", value: 60 },
  { label: "65+", value: 65 },
];
const genderList = ["male", "female", "other"];

class MatchingPref extends Component {
  constructor(props) {
    super(props);

    const { new_request, add_by_id } = this.props.me;

    this.state = {
      disabled: false,
      saved: false,
      alert: null,
      enableAgeFilter: false,
      target_gender: genderList,
      from: 0,
      to: 65,
      fromList: _.filter(baseList, (i) => {
        return i.value < 65;
      }),
      toList: _.filter(baseList, (i) => {
        return i.value > 0;
      }),
      suggestions: new_request === 1 || new_request === 2,
      automatch: new_request === 1 || new_request === 3,
      add_by_id: !!add_by_id
    };

    this.autoSaveDelayed = _.throttle(this.autoSave, 5000, { leading: false });
  }

  componentDidMount() {
    const { me, friendLimit, totalFriends } = this.props;
    global.log('me.new_request: '+ me.new_request)

    if (totalFriends >= friendLimit) {
      if (me.new_request >= 1) {
        this.props.updateMe(me.token, {
          new_request: 0
        });
      }
      this.setState({ 
        disabled: true,
        suggestions: false,
        automatch: false
      });
    }

    const from = !!me.target_age_from
      ? me.target_age_from > 0 && me.target_age_from < 20
        ? 0
        : me.target_age_from
      : 0;
    const to = !!me.target_age_to ? me.target_age_to : 65;

    this.setState({
      target_gender:
        me.target_gender === "any" ? genderList : me.target_gender.split(","),
      enableAgeFilter: me.target_age_from !== null,
      from,
      to,
      fromList: _.filter(baseList, (i) => {
        return i.value < 65;
      }),
      toList: _.filter(baseList, (i) => {
        return i.value > from;
      }),
      suggestions: me.new_request === 1 || me.new_request === 2,
      automatch: me.new_request === 1 || me.new_request === 3,
      add_by_id: !!me.add_by_id
    });
  }

  componentWillUnmount() {
    this.autoSaveDelayed.flush();
  }

  // updateRequest = (new_request) => {
  //   const { totalFriends, friendLimit } = this.props;

  //   if (totalFriends >= friendLimit) {
  //     return false;
  //   }

  //   this.setState({ new_request, saved: false });
  // };

  updateFrom = (e) => {
    const from = e.target.value;

    this.setState({
      from,
      toList: _.filter(baseList, (i) => {
        return i.value > from;
      }),
      saved: false,
    });
  };

  updateTo = (e) => {
    this.setState({
      to: e.target.value,
      saved: false,
    });
  };

  toggleGender = (val) => {
    const exists = this.state.target_gender.indexOf(val) >= 0;

    if (exists) {
      this.setState({
        target_gender: _.pull(this.state.target_gender, val),
        saved: false,
      });
    } else {
      this.setState({
        target_gender: _.concat(this.state.target_gender, val),
        saved: false,
      });
    }
  };

  _savePref = () => {
    const { new_request, target_gender, enableAgeFilter, from, to } =
      this.state;

    this.props.updateMe(this.props.me.token, {
      new_request,
      target_gender:
        target_gender.length === 3 ? "any" : target_gender.join(","),
      target_age_from: !!enableAgeFilter ? from : null,
      target_age_to: !!enableAgeFilter ? to : null,
    });

    this.setState({
      saved: true,
    });
  };

  updateAddById = () => {
    this.setState({
      add_by_id: !this.state.add_by_id
    }, ()=>{
      const { me, updatePref } = this.props;
      updatePref(me.token, {
        add_by_id: !!this.state.add_by_id ? "1" : "0"
      });
    })
  };

  toggleSuggestions = () => {
    // global.log('toggleSuggestions:' + value);
    this.setState(
      {
        suggestions: !this.state.suggestions,
      },
      this.autoSaveDelayed,
    );
  };

  toggleAutomatch = () => {
    // global.log('toggleAutomatch:' + value);

    this.setState(
      {
        automatch: !this.state.automatch,
      },
      this.autoSaveDelayed,
    );
  };

  autoSave = () => {
    const { suggestions, automatch } = this.state;
    const new_request =
      suggestions && automatch
        ? 1
        : !suggestions && automatch
        ? 3
        : !automatch && suggestions
        ? 2
        : 0;
    const oldValue = this.props.me.new_request;

    global.log('new_request old: ' + oldValue + ', new: ' + new_request);

    if (oldValue !== new_request) {
      this.props.updateMe(
        this.props.me.token,
        {
          new_request,
        },
        true,
      );
    }
  };

  render() {
    const { disabled, from, to, fromList, toList, target_gender } = this.state;
    const { me } = this.props;

    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu />
            </div>
            <div className="col-9 p-3">
              <div className="card shadow-sm  my-2">
                <div className="card-header d-flex align-items-center">
                  <div className="flex-grow-1 strong">
                    {I18n.t("MATCHING_PREF")}
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group row mt-2">
                    <label className={"col-4"}>
                      {I18n.t("ACCEPTING_PENPALS")}
                    </label>
                    <div className="col-8">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="add_by_id"
                          checked={this.state.add_by_id}
                          onChange={this.updateAddById}
                          disabled={!!disabled}
                        />
                        <label
                          className="custom-control-label mb-3"
                          htmlFor="add_by_id"
                        >
                          <strong>{'Slowly ID'}</strong><br/>
                          <small>{I18n.t('ALLOW_ADD_BY_ID')}</small>
                        </label>
                      </div>

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="automatch"
                          checked={this.state.automatch}
                          onChange={this.toggleAutomatch}
                          disabled={!!disabled}
                        />
                        <label
                          className="custom-control-label mb-3"
                          htmlFor="automatch"
                        >
                          <strong>{I18n.t("AUTO_MATCH")}</strong><br/>
                          <small>{I18n.t('MANAGE_AUTOMATCH_PREF_TIPS')}</small>
                        </label>
                      </div>

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="new_request"
                          checked={this.state.suggestions}
                          onChange={this.toggleSuggestions}
                          disabled={!!disabled}
                        />
                        <label
                          className="custom-control-label mb-3"
                          htmlFor="new_request"
                        >
                          <strong>{I18n.t("PROFILE_SUGGESTION")}</strong><br/>
                          <small>{I18n.t('MANAGE_SUGGESTIONS_PREF_TIPS')}</small>
                        </label>
                      </div>
                    </div>
                  </div>

                  {!!disabled && (
                    <p className="mt-1 small muted text-danger">
                      {I18n.t("REACHED_FRIENDS_LIMIT")}
                    </p>
                  )}
                  <hr />
                  {me.gender !== "NA" && (
                    <>
                      <div className="form-group row align-items-center">
                        <label
                          className={
                            "col-4" +
                            (target_gender.length === 0 ? " text-danger" : " ")
                          }
                        >
                          {I18n.t("TARGET_GENDER")}
                          {target_gender.length === 0 && (
                            <span className="strong ml-1">*</span>
                          )}
                        </label>
                        <div className="col-8">
                          <div
                            className="btn-group btn-block"
                            role="group"
                            dir="ltr"
                          >
                            <button
                              type="button"
                              className="btn btn-light text-left"
                              onClick={() => {
                                this.toggleGender("male");
                              }}
                            >
                              {target_gender.indexOf("male") >= 0 ? (
                                <i className="icon-checkbox-checked text-primary mr-2" />
                              ) : (
                                <i className="icon-checkbox-unchecked text-lightest mr-2" />
                              )}
                              {I18n.t("male")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-light text-left"
                              onClick={() => {
                                this.toggleGender("female");
                              }}
                            >
                              {target_gender.indexOf("female") >= 0 ? (
                                <i className="icon-checkbox-checked text-primary mr-2" />
                              ) : (
                                <i className="icon-checkbox-unchecked text-lightest mr-2" />
                              )}
                              {I18n.t("female")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-light text-left"
                              onClick={() => {
                                this.toggleGender("other");
                              }}
                            >
                              {target_gender.indexOf("other") >= 0 ? (
                                <i className="icon-checkbox-checked text-primary mr-2" />
                              ) : (
                                <i className="icon-checkbox-unchecked text-lightest mr-2" />
                              )}
                              {I18n.t("other")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <div className="form-group row mb-3 align-items-start">
                    <label className="col-4">
                      {I18n.t("TARGET_AGE_FILTERS")}
                    </label>
                    <div className="col-8">
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="enable-age"
                          checked={this.state.enableAgeFilter}
                          onChange={(ev) => {
                            this.setState({
                              enableAgeFilter: !this.state.enableAgeFilter,
                            });
                          }}
                          disabled={!me.dob || me.dob_privacy < 4}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="enable-age"
                        >
                          {I18n.t("ENABLE_AGE_RESTRICTION")}
                        </label>
                      </div>
                      {(!me.dob || me.dob_privacy < 4) && (
                        <p className="mt-1 small muted text-danger">
                          {!me.dob
                            ? I18n.t("UNLOCK_AFTER_ENTER_DOB")
                            : I18n.t("YOUR_AGE_IS_HIDDEN")}
                        </p>
                      )}

                      {!!this.state.enableAgeFilter && (
                        <div className="input-group">
                          <select
                            className="custom-select"
                            id="age-from"
                            defaultValue={from}
                            onChange={this.updateFrom}
                          >
                            {fromList.map(function (c, index) {
                              return (
                                <option
                                  value={c.value}
                                  key={"age-from" + c.value}
                                >
                                  {c.label}
                                </option>
                              );
                            })}
                          </select>
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="icon-more-horizontal" />
                            </span>
                          </div>
                          <select
                            className="custom-select input-group-append"
                            id="age-to"
                            defaultValue={to}
                            onChange={this.updateTo}
                          >
                            {toList.map(function (c, index) {
                              return (
                                <option
                                  value={c.value}
                                  key={"age-from" + c.value}
                                >
                                  {c.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    className="btn btn-primary btn-block"
                    disabled={target_gender.length === 0 || !!this.state.saved}
                    onClick={this._savePref}
                  >
                    {!!this.state.saved ? (
                      <i className="icon-checkmark text-secondary" />
                    ) : (
                      I18n.t("SAVE")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    friendLimit: reFriendLimit(state),
    totalFriends: totalFriends(state),
  };
};

const updateMe = function updateMe(token, updateFields, skip = false) {
  return {
    type: "UPDATE_ME",
    token,
    updateFields,
    skip,
  };
};

const updatePref = function updatePref(token, updateFields) {
  return {
    type: "UPDATE_PREF",
    token,
    updateFields,
  };
};

export default connect(mapStateToProps, {
  updateMe,
  updatePref,
})(MatchingPref);
