import React from "react";
import I18n from "../I18n";
import Stamp from "./Stamp";
import moment from "../lib/moment";
import { STATIC_CDN_URL } from "../config/ApiConfig";
import coin from "../assets/images/coin.png";
import colors from "../assets/colors";

export default class StampItem extends React.PureComponent {
  render() {
    const { item, showRank=false, index=99 } = this.props;
    if (!item) return null;

    const { salesInfo={} } = item;

    return (
      <div className="col-6 px-0 mb-3 cursor-default">
        <div className="d-flex">
          { !!showRank && (
            <div className="mr-n3 mt-n2 h5" style={{ width:30, zIndex: 2 }}>
              <span className={index<3 ? "badge badge-pill border rank"+index : "badge badge-pill border badge-light"}>
                {index+1}
              </span>
            </div>
          )}
          {item.isSet ? (
            <div
              className="rounded store-item-img m-1 link"
              dir="ltr"
              onClick={this.props.showStamp}
            >
              <img
                src={STATIC_CDN_URL + item.image}
                alt={item.title}
                width={90}
                height={90}
              />
            </div>
          ) : (
            <div
              className={
                !!salesInfo.coming
                  ? "store-item-img m-1 disabled link"
                  : "store-item-img m-1 link"
              }
              onClick={this.props.showStamp}
            >
              <Stamp slug={item.showingItem.slug} size={100} />
            </div>
          )}
          <div className="ml-0 mx-2 my-2 store-item-desc">
            <div className="h5 mb-1 text-darker">
              <span className="link" onClick={this.props.showStamp}>
                {item.title}
              </span>
            </div>
            <span className="text-light link singleline-desc" onClick={this.props.showStamp}>
              {!!salesInfo.comingSoon
                ? moment.custom(item.schedules[0].starttime, "birthday") +
                  " — " +
                  moment.custom(item.schedules[0].endtime, "birthday")
                : item.desc}
            </span>
            <div className="d-flex mt-3 align-items-center">
              {!!item.isSeries ? (
                <div
                  className="mr-2 small badge btn btn-light px-2 link"
                  onClick={this.props.showStamp}
                >
                  {I18n.t("LEARN_MORE")}
                </div>
              ) : !!item.unlocked ? (
                <div
                  className="mr-2 small badge btn btn-light pr-2 link"
                  onClick={this.props.showStamp}
                >
                  <i className="icon-checkmark text-calm" />{" "}
                  {" " + I18n.t("OWNED")}
                </div>
              ) : !salesInfo.coming && salesInfo.price === 0 ? (
                <div
                  className="mr-2 small text-white small badge badge-danger link"
                  onClick={this.props.showStamp}
                >
                  {I18n.t("FREE")}
                </div>
              ) : !salesInfo.coming && this.props.showStamp ? (
                <div
                  className="mr-2 small badge btn btn-light d-flex align-items-center link"
                  onClick={this.props.showStamp}
                >
                  <img src={coin} height="13" alt={I18n.t("SLOWLY_COINS")} />
                  <strong
                    className="mx-1 my-0"
                    style={
                      !!salesInfo.original_price
                        ? { color: colors.alert, fontWeight: "bold" }
                        : { fontWeight: "bold" }
                    }
                  >
                    {salesInfo.price}
                  </strong>
                  {!!salesInfo.original_price && (
                    <span style={{ textDecorationLine: "line-through" }}>
                      {salesInfo.original_price}
                    </span>
                  )}
                </div>
              ) : !!salesInfo.coming && !!salesInfo.comingSoon ? (
                <div
                  className="mr-2 small badge btn btn-light disabled link"
                  onClick={this.props.showStamp}
                >
                  <i className="icon-clock mr-1" />
                  {I18n.t("Coming Soon")}
                </div>
              ) : (
                !!salesInfo.coming && (
                  <div
                    className="small badge btn btn-light disabled link"
                    onClick={this.props.showStamp}
                  >
                    <i className="icon-clock mr-1" />
                    {I18n.t("EXPECTED_MONTH", {
                      month: moment.orig(salesInfo.starttime).format("MMMM"),
                    })}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
