import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import FriendListItem from "../components/FriendListItem";
import Friend from "../components/Friend";
import Letters from "../components/Letters";
import Composer from "../components/Composer";
import {  Dropdown } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Loader from "../components/Loader";
import PopupModal from "../components/PopupModal";
import { Scrollbars } from 'react-custom-scrollbars';

import {
  reContacts,
  reProfileMe,
  totalFriends,
  reFriendLimit
} from "../selectors";

import {
  discover,
  getWP,
  readMessage,
  getMessages
} from "../api/slowly.api"

import {
  GMAP_KEY
} from "../config/ApiConfig"

// import moment from "moment"
import _ from "lodash"
import hashids from "../lib/hashids";
import tracking from "../lib/tracking";

const sorting = [
  {
    label: "ALPHABETICAL",
    value: "alphabetical"
  },
  {
    label: "LATEST_FIRST",
    value: "latest_comment"
  },
  {
    label: "FREQUENT_CONTACT",
    value: "total"
  },
  {
    label: "UNREAD_FIRST",
    value: "unread"
  },
  {
    label: "FAV_FIRST",
    value: "fav"
  },
  {
    label: "NEWEST_FIRST",
    value: "created_at"
  },
  {
    label: "DISTANCE",
    value: "distance"
  }
];

const provider = (x, y, z, dpr) => {
  const s = String.fromCharCode(97 + ((x + y + z) % 3));
  return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
};
const providerDE = (x, y, z, dpr) => {

    const s = String.fromCharCode(97 + ((x + y + z) % 3));
    return `https://${s}.tile.openstreetmap.de/${z}/${x}/${y}.png`;
};
const providerFR = (x, y, z, dpr) => {

    const s = String.fromCharCode(97 + ((x + y + z) % 3));
    return `https://${s}.tile.openstreetmap.fr/osmfr/${z}/${x}/${y}.png`
};

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditor: false,
      focusMode: false,
      replyButton: false,
      storyTitle: this.props.storyTitle,
      feedItems: [],
      featuredTitle: null,
      // featuredItems: [],
      messages: [],
      stories: this.props.stories,
      discover: this.props.discover,
      index: 0,
      provider: this.props.locale === "de"
          ? providerDE
          : this.props.locale === "fr"
          ? providerFR
          : provider
    }
    this.changeSorting = this.changeSorting.bind(this);
  }

  changeSorting(eventKey, event) {
    event.preventDefault();
    global.log(eventKey);
    this.props.orderBy(eventKey);
    return true;
  }

  componentWillReceiveProps(nextProps){
    const { match: { params } } = nextProps;
    const existingID = !!this.props.match.params.id ? this.props.match.params.id : null;

    if(params.id !== existingID && this.state.showEditor===true ) this._hideEditor()

  }

  componentDidMount(){
    setTimeout(()=>{
      this.getDiscover()
      this.getStories()
      this._getMessages()

      
    })
  }

  _getMessages = async () => {
    try {
      const { token, coffee=null } = this.props.me;
      const { locale } = this.props;

      const messages = await getMessages({
        token,
        os: 'web',
        language: locale,
        coffee: !!coffee ? coffee.id : 0
      });

      global.log("_getMessages", messages);
      if (messages.length) {
        this.setState({
          messages
        })
      }
    } catch (err) {
      global.log(err);
    }
  };

  getStories = async() => {
    const { locale, stories_cached_key } = this.props;
    const utc = new Date().toJSON().slice(0, 10);

    if(stories_cached_key===locale+'_'+utc){
      //skip
      return true;
    }

    const webLocale = locale==='fil' ? 'ph' : locale==='zh_Hant' ? 'zh' : locale==='zh_Hans' ? 'cn' : locale==='pt_BR' ? 'pb' : locale;
    const translatedLocales = [
      'zh',
      'cn',
      'ru',
      'es',
      'fr',
      'it',
      'vi',
      'tr',
      'pb',
      'cs',
      'pl',
    ];
    const localeOnly = translatedLocales.indexOf(webLocale) >= 0 ? '&hl=' + webLocale : '';
    const res = await getWP({locale: webLocale, utc, localeOnly});

    if (res.stories.length>0){
      const data = _.take(_.shuffle(_.uniqBy(res.stories, 'permalink')), 3)
      this.setState({
        storyTitle: res.title,
        storyDesc: res.desc,
        stories: data
      });

      this.props.cacheStories({
        storyTitle: res.title,
        stories: data,
        cached_key:  locale+'_'+utc
      })
    }
  }

  getDiscover = async(skip=false) => {
    const { locale, contacts, discover_cached_key } = this.props;
    const utc = new Date().toJSON().slice(0, 13);

    if(discover_cached_key===locale+'_'+utc && !skip && this.props.discover && this.props.discover.result){
      //skip
      return true;
    }
    this.setState({ loading: true, card: null })

    try{
      const webLocale = locale==='fil' ? 'ph' : locale==='zh_Hant' ? 'zh' : locale==='zh_Hans' ? 'cn' : locale==='pt_BR' ? 'pb' : locale;
      const friendsCountries = _.uniq(_.map(contacts.data, 'location_code'))
      // global.log('friendsCountries', friendsCountries)

      const { country, index } = (friendsCountries.length>=3 && !skip) ?
                        _.sample(friendsCountries)
                        : I18n.randomCountry(this.state.index)

      global.log('discover: ' + webLocale + ' / ' + country )

      let result = await discover({ locale: webLocale, country })
      global.log('discover result', result)
      if(!result.result){
        const { country, index } =  I18n.randomCountry(this.state.index+1)
        const newResult = await discover({ locale: webLocale, country })
        this.setState({
          discover: newResult.result ? newResult : this.state.discover,
          index: index+1,
          loading: false
        })
      }else{
        this.setState({
          discover: result,
          index,
          loading: false
        })

        this.props.cacheDiscover({ discover: result, cached_key: locale+'_'+utc})
      }
      if(!!skip) tracking.event("worldexplorer_web");

    }catch(error){
      global.log('error', error);
      this.setState({ loading: false })
    }

  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  _showEditor = () => {
    this.setState({
      showEditor: true,
      focusMode: false
    })
  }

  _hideEditor = () => {
    this.setState({
      showEditor: false,
      focusMode: false
    })
  }

  toggleFocusMode = () => {
    this.setState({ focusMode: !this.state.focusMode })
  }

  onDragFinished = padding => {
    // global.log('onDragFinished', padding)
    this.setState({ padding })
  }

  setReplyButton = status => {
    this.setState({
      replyButton: status
    })
  }

  closePopup = () => {
    const msg = this.state.messages[0]
    this.setState({ messages: [] })
    readMessage({ token: this.props.me.token, message_id: msg.id });
  }

  render() {
    const {
      contacts = { data: [] },
      match: { params },
      me,
      loading,
      locale
    } = this.props;

    if (!me.token)
      return (
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      );

    if(!!loading) return (<Loader />)

    const { replyButton, stories=[] } = this.state;
    const postID = params.id && hashids.decode(params.id)[0]
    const webLocale = locale==='en' ? '' : locale==='zh_Hant' ? '/zh' : locale==='zh_Hans' ? '/cn' : locale==='pt_BR' ? '/pb' : '/'+locale;

    const geo = (this.state.discover && this.state.discover.result) ? this.state.discover.result.geo.split(',').map(Number) : [0,0]

    return (
      <div key="Home"
        className={ !!this.state.focusMode ? "main-scroller focus-mode" : "main-scroller"}
      >
          <div className="container mt-3 home-wrapper">
            <div className="d-flex">
              <div className="side-bar-wrapper print-hidden">
                <div className="side-bar">
                  <div className="card">
                    <div className="card-header p-2 pl-3">
                      <div className="float-right">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="toolbar"
                            id="dropdown-basic"
                          >
                              <i className="icon-sort text-black-50" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {sorting.map(item => {
                              return (
                                <Dropdown.Item
                                  key={item.value}
                                  eventKey={item.value}
                                  onSelect={this.changeSorting}
                                  active={contacts.sortby === item.value}
                                >
                                  { I18n.t(item.label)}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="h6 text-primary mb-0 pt-1">
                        <i className="icon-users mr-2" />
                        {I18n.t("PENPALS")}
                      </div>
                      <div className="text-black-50 small">
                        <span className="pr-1">
                          {I18n.t("PENPALS_COUNT", {
                            count: contacts.friendIds.length.toString()
                          })}{" "}
                        </span>
                        <span>
                          {// contacts.hidden.length > 0 &&
                          I18n.t("HIDDEN_USERS", {
                            count: contacts.hidden.length.toString()
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="overflow" dir="ltr">
                      <Scrollbars>
                          {
                            contacts.data.length===0 ?
                            (
                              <div className="text-lighter p-3">
                                <strong>{I18n.t("NO_PENPALS")}</strong> -
                                { I18n.t("NO_PENPALS_MSG")}
                              </div>
                            ) : contacts.data.map((friend, index) => {
                            return (
                              <FriendListItem
                                friend={friend}
                                meID={me.id}
                                key={"friendlist-" + friend.id}
                              />
                            );
                          })}
                      </Scrollbars>
                    </div>
                  </div>
                  <div className="py-2 text-left">
                    <div className="text-lighter smaller p-2">
                      {I18n.t("SLOWLY_WEB") + ' ( v' + this.props.ver + ' )'}
                      <br />
                      <a href="https://help.slowly.app/hc/en-us/" target="_blank"  rel="noopener noreferrer" className="text-lighter">
                          {I18n.t('FAQ')}
                      </a>
                      { me.age && me.age>=35 && (
                        <>
                        ． <a href={"https://slowly.app"+webLocale+"/safety-tips/"} target="_blank"  rel="noopener noreferrer" className="text-lighter">
                                {I18n.t('Safety Tips')}
                              </a></>
                      )}
                       ． <a href={"https://slowly.app"+webLocale+"/terms/"} target="_blank"  rel="noopener noreferrer" className="text-lighter">{I18n.t('TERMS')}</a>
                       ． <a href={"https://slowly.app"+webLocale+"/privacy/"} target="_blank"  rel="noopener noreferrer" className="text-lighter">{I18n.t('PRIVACY')}</a>

                    </div>
                    <div className="text-lighter p-0 ml-n2">
                      <a href="https://www.facebook.com/slowlyapp" target="_blank" rel="noopener noreferrer" className="btn btn-default">
                        <i className="icon-facebook text-light"/>
                      </a>
                      <a href="https://www.instagram.com/slowlyapp" target="_blank" rel="noopener noreferrer" className="btn btn-default">
                        <i className="icon-instagram text-light"/>
                      </a>
                      <a href="https://www.x.com/slowlyapp" target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                        <i className="icon-x text-light"/>
                      </a>
                      <a href="https://www.pinterest.com/slowlyapp/" target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                        <i className="icon-pinterest text-light"/>
                      </a>
                    </div>
                  </div>

                </div>
              </div>
              <div
                className="pl-3 main-container flex-grow-1"
                style={!!this.state.showEditor ? {
                  paddingBottom: !!this.state.padding ? this.state.padding : 350
                }: null }
              >
                {!!params.letter ? (
                  <Letters
                    postID={postID}
                    letterID={hashids.decode(params.letter)[0]}
                    scrollToTop={this.scrollToTop}
                    setReplyButton={this.setReplyButton}
                  />
                ) : !!params.id ? (
                  <Friend
                    postID={postID}
                    // scrollToTop={this.scrollToTop}
                    setReplyButton={this.setReplyButton}
                  />
                ) : (
                  <div className="bg-stable p-1">
                    <h3 className="text-positive pt-2">
                      {I18n.t('WELCOME_BACK') + " 👋"  }
                    </h3>

                    { this.state.discover && this.state.discover.result ? (
                      <div className="card w-100 mt-3" dir="ltr">
                        <div className="card-body p-0 m-0">
                          <div className="row m-0">
                            <div className="col border-right d-flex">
                              <div className="p-3 mt-5 mb-3 align-self-center" dir="auto">
                                <p className="text-light mb-0 smaller">
                                  { '🌎 '}
                                  { I18n.t('World Explorer') + ': ' }
                                  { I18n.country(this.state.discover.country) }
                                </p>
                                <h4 className="text-primary mt-1">{this.state.discover.result.title}</h4>
                                <p className="smaller">
                                  <span className="pr-1">{this.state.discover.result.intro}</span>
                                    { this.state.discover.result.source && (
                                      <a href={this.state.discover.result.source_link} target="_blank"  rel="noopener noreferrer" className="text-lighter link">
                                        <nobr>
                                          <i className="icon-info" />
                                          {' '}{this.state.discover.result.source}
                                        </nobr>
                                      </a>
                                    )}
                                </p>
                                { this.state.discover.result.credit_type &&  (
                                  <p className="mt-4 mb-1 text-lighter smallest">
                                    {'© '}
                                    <a href="https://creativecommons.org/licenses/by-sa/3.0/igo/legalcode"  target="_blank"  rel="noopener noreferrer" className="text-lighter link">
                                      {this.state.discover.result.credit_type}
                                    </a>
                                  </p>
                                ) }
                                { this.state.discover.result.img_caption && (
                                  <div>
                                    { this.state.discover.result.img_link ? (
                                      <p className="text-lighter smallest">
                                        <a href={this.state.discover.result.img_link} target="_blank" className="text-lighter" rel="noopener noreferrer">
                                           {this.state.discover.result.img_caption}
                                        </a>
                                      </p>
                                    ):(
                                      <p className="text-lighter smallest">
                                        {this.state.discover.result.img_caption}
                                      </p>
                                    )}
                                </div>
                                ) }

                              </div>
                              <div className="position-absolute m-2 border-0" style={{ right: 0, top:0 }}>
                                <button
                                  className="btn btn-default btn-sm text-lighter smaller"
                                  disabled={this.state.loading}
                                  onClick={()=> { this.getDiscover(true)}}
                                >
                                  { I18n.t('Next Destination') }{' '}
                                  <i className={ "icon-caret-right"} />
                                </button>
                              </div>
                            </div>
                            <div
                              className="col-5 bg-stable p-0 m-0"
                              style={{ overflow: 'hidden' }}
                              >
                              <div
                                className="w-100"
                                style={{ minHeight: 300, height: '100%' }}
                              >
                                { (this.state.discover.result.img && this.state.card!=='map') ? (
                                  <div
                                    key={'img-'+ this.state.discover.result.title }
                                    className="cover w-100 h-100 rounded-right"
                                    style={{
                                      backgroundImage: `url(${this.state.discover.result.img})`
                                    }}
                                  />
                                ): (
                                    <iframe
                                      title={this.state.discover.result.source_link}
                                      style={{ width:'100%', height:'100%', border: 0 }}
                                      loading="lazy"
                                      allowFullScreen={false}
                                      src={ (!!geo[0] && !!geo[1]) ? "https://maps.google.com/maps?q="+ geo[0] + "," +geo[1] + "&z=6&output=embed&hl="+this.props.locale :
                                      "https://www.google.com/maps/embed/v1/place?key=" +GMAP_KEY +
                                      '&zoom=6&q=' + this.state.discover.result.title + ',' + I18n.country(this.state.discover.country)
                                      + '&language='+this.props.locale}>
                                    </iframe>
                                )}

                              </div>
                              <div className="position-absolute my-2 mx-1 border-0" style={{ right: 0, top:0 }}>
                                { !!this.state.discover.result.img && (
                                  <div className="btn-group btn-group-sm mr-1" role="group">
                                    <button type="button"
                                      onClick={()=>{
                                        this.setState({ card: 'map' })
                                      }}
                                      className={ this.state.card==='map' ? "btn btn-secondary" : "btn btn-light" }>
                                      <i className="icon-pin" />
                                    </button>
                                    <button type="button"
                                      onClick={()=>{
                                        this.setState({ card: 'photo' })
                                      }}
                                      className={ this.state.card==='map' ? "btn btn-light" : "btn btn-secondary" }>
                                      <i className="icon-camera" />
                                    </button>
                                </div>
                              )}
                            </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    ) : (
                      <hr className="mt-3" />
                    )}
                    <h5 className="text-primary my-3">
                      { '💬 ' + this.state.storyTitle}
                    </h5>
                    <div className="row">
                      { stories.map( (item, index) => {
                        return (
                          <div className={index>=2 ? "col-6 col-xl-4 mb-3 d-lg-none d-xl-block" : "col-6 col-xl-4 mb-3"} key={item.permalink}>
                            <div className="card shadow-sm">
                              <a href={item.permalink} target="_blank"  rel="noopener noreferrer">
                                <img src={item.img} className="card-img-top" alt={item.nickname} />
                              </a>
                              <div className="card-body">
                                <a href={item.permalink} target="_blank"  rel="noopener noreferrer">
                                  <h5 className="card-title mb-0">{item.nickname}</h5>
                                </a>
                                <p className="card-text mt-2 small">{item.summary}</p>
                                <a href={item.permalink} target="_blank"  rel="noopener noreferrer" className="btn btn-light btn-sm">{ I18n.t('READ_MORE') }</a>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          { (!!params.id && !!this.state.showEditor) ? (
            <div className="editor-wrapper fixed-bottom print-hidden">
              <Composer
                postID={postID}
                hideEditor={this._hideEditor}
                history={this.props.history}
                hashid={params.id}
                toggleFocusMode={this.toggleFocusMode}
                isFocus={!!this.state.focusMode}
                onDragFinished={this.onDragFinished}
                key={'composer'}
               />
            </div>
          ) : (!!params.id && !!replyButton) && (
            <button type="button"
              className="btn btn-secondary btn-lg btn-reply shadow-lg print-hidden"
              onClick={this._showEditor}
             >
              <i className="icon-pencil" /> { I18n.t('SEND_A_LETTER')}
            </button>
          )}
          <PopupModal
            show={this.state.messages.length>0}
            messages={this.state.messages}
            handleClose={this.closePopup}
          />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: reContacts(state),
    me: reProfileMe(state),
    totalFriends: totalFriends(state),
    friendLimit: reFriendLimit(state),
    loading: state.slowly.loading,
    users: !!state.slowly.users ? state.slowly.users : '...',
    friendships: !!state.slowly.friendships ? state.slowly.friendships : '...',
    locale: state.slowly.locale,
    searchingID: state.letters.searching,
    ver: state.slowly.appVerString,
    storyTitle: state.slowly.storyTitle,
    stories: state.slowly.stories,
    stories_cached_key: state.slowly.stories_cached_key,
    discover: state.slowly.discover,
    discover_cached_key: state.slowly.discover_cached_key
  };
};

const orderBy = function orderBy(field) {
  return { type: "SORT_FRIENDS", field };
};

const cacheStories = function cacheStories(fields) {
  return { type: "CACHE_STORIES", ...fields };
};

const cacheDiscover = function cacheDiscover(fields) {
  return { type: "CACHE_DISCOVER", ...fields };
};

export default connect(
  mapStateToProps,
  { orderBy,
  cacheStories,
  cacheDiscover
 }
)(Home);
