import { API_URL, headers, handleApiErrors } from '../config/ApiConfig';

export const syncCoins = ({ token }) => {
  return fetch(API_URL + '/store/coins?token=' + token , {
    method: 'POST',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const updateExtendPref = ({ token, type, value }) =>{
  return fetch(API_URL + '/users/me/extend/pref?token=' +token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      type,
      value
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const checkSubtopics = ({ token  }) =>{
  return fetch(API_URL + '/users/me/subtopics/check?token=' +token , {
    method: 'GET',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const getSubtopics = ({ token  }) =>{
  return fetch(API_URL + '/users/me/subtopics/sync?token=' +token , {
    method: 'GET',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}


export const updateSubtopics = ({token, payload }) =>{
  return fetch(API_URL + '/users/me/subtopics/update?token=' +token , {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const matchingQuota = ({ token }) => {
  return fetch(API_URL + '/users/me/matching?token=' +token , {
    method: 'POST',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const getQuota = ({ token }) => {
  return fetch(API_URL + '/users/me/quota?token=' +token , {
    method: 'GET',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}


export const photoQuota = ({ token }) => {
  return fetch(API_URL + '/users/me/quota/photo?token=' +token , {
    method: 'POST',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}
export const refreshRatio = ({token}) => {
  return fetch(API_URL + '/users/me/ratio?token=' +token , {
    method: 'POST',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
};

export const unlockStamp = ({token, stamp}) => {
  return fetch(API_URL + '/users/me/unlock?token=' +token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      stamp
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
};

export const getStamps = ({token}) => {
  return fetch(API_URL + '/users/me/stamps?token=' +token , {
    method: 'POST',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const getMe = ({token, device=null, includes=null, trusted }) => {
  return fetch(API_URL + '/web/me', {
    method: 'POST',
    headers: {
      ...headers,
      'Authorization': 'Bearer '+  token
    },
    body: JSON.stringify({
      device: JSON.stringify(device),
      trusted,
      ver: 80000,
      includes
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
};

export const updateMe = ({token, updateFields}) =>{
  if( !!updateFields.pref ) updateFields.pref = JSON.stringify(updateFields.pref)

  return fetch(API_URL + '/users/me?token=' +token , {
    method: 'PUT',
    headers,
    body: JSON.stringify(updateFields)
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const updatePref = ({token, updateFields}) =>{
  return fetch(API_URL + '/users/me/pref?token=' +token , {
    method: 'PUT',
    headers,
    body: JSON.stringify(updateFields)
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const updateDevice = vars =>{
  global.log('updateDevice vars', vars)
  return fetch(API_URL + '/web/device', {
    method: 'PUT',
    headers: {
      ...headers,
      'Authorization': 'Bearer '+ vars.token,
      'X-Firebase-AppCheck': vars.appCheckToken
    },
    body: JSON.stringify(vars)
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const refreshWebToken = ({ token, uuid, uid }) =>{
  return fetch(API_URL + '/web/auth/refresh?token='+token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      uuid, uid
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const syncCollection = ({token}) => {
  return fetch(API_URL + '/users/me/collection?token=' +token )
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const clearToken = ({ token, uuid })=>{
  return fetch(API_URL + '/web/auth/clear?token='+token , {
    method: 'DELETE',
    headers,
    body: JSON.stringify({
      uuid
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}


export const saveTags = ({token, tags}) =>{
  return fetch(API_URL + '/users/me/tags?token=' +token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      tags
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const addLang = ({token, slug, level}) =>{
  return fetch(API_URL + '/users/me/lang?token=' +token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      slug,
      level
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const updateLang = ({token, langId, slug, level}) =>{
  return fetch(API_URL + '/users/me/lang?token=' +token , {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      id: langId,
      slug,
      level
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const removeLang = ({token, langId}) =>{
  return fetch(API_URL + '/users/me/lang/'+ langId +'?token=' +token , {
    method: 'DELETE',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const syncExcludeTopics = ({token, tags}) => {
  return fetch(API_URL + '/users/me/excluded_tags?token=' +token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      tags: tags.length ? tags.join(',') : ''
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
};

export const getStampData = ({ token }) => {
  return fetch(API_URL + '/web/stamps/data?token=' +token)
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const syncStampFav = ({ token, fav=[] }) => {
  return fetch(API_URL + '/web/stamps/fav?token=' +token , {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      fav: fav.length>0 ? fav.join(',') : ''
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const syncStampBookmark = ({ token, bookmarks }) => {
  return fetch(API_URL + '/web/stamps/bookmarks?token=' +token , {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      bookmarks: bookmarks.length ? bookmarks.join(',') : ''
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const checkAchievement = ({token, stamp}) => {
  return fetch(API_URL + '/users/me/achievement/check?token=' +token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      stamp
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
};

//v7.4 hide& unhide stamps
export const hideStamp = ({ token, id }) => {
  return fetch(API_URL + '/users/me/stamps/hide', {
    method: 'POST',
    headers: {
      ...headers,
      'Authorization': 'Bearer '+ token
    },
    body: JSON.stringify({
      id
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const unhideStamps = ({ token, ids }) => {
  return fetch(API_URL + '/users/me/stamps/unhide', {
    method: 'POST',
    headers: {
      ...headers,
      'Authorization': 'Bearer '+ token
    },
    body: JSON.stringify({
      ids
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}


export const getHiddenStamps = ({ token }) => {
  return fetch(API_URL + '/users/me/stamps/hidden', {
    method: 'GET',
    headers: {
      ...headers,
      'Authorization': 'Bearer '+ token
    }
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}