import React from "react";
import StampSmall from './StampSmall'
import Avatar from "./Avatar";
import moment from "../lib/moment";
import I18n from "../I18n";
import { Modal, Dropdown } from "react-bootstrap";
import {
  reProfileMe,
  reKeyedItems,
  reSharingPhotoLabel,
  reSharingAudioLabel,
} from "../selectors";
import { connect } from "react-redux";
import { truncate } from "lodash";
import AppAlert from "../components/AppAlert";
import StampCollection from "./StampCollection";
import ShowStamp from "./ShowStamp";

class FriendProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAbout: false,
      editRemark: false,
      reporting: false,
      reasons: [
        {
          group: "Spam / Scam",
          sub: [
            "Fake profile",
            "Unsolicited advertising",
            "Ask for money",
            "Same person with multiple accounts",
            "Others",
          ],
          last: 4,
        },
        {
          group: "Offensive",
          sub: [
            "Sexual exploitation",
            "Violent threat",
            "Child abuse",
            "Flirt without consent",
            "Others",
          ],
          last: 4,
        },
        {
          group: "Hate Speech",
          sub: [
            "Race or ethnicity",
            "National origin",
            "Sex or gender identity",
            "Others",
          ],
          last: 3,
        },
        {
          group: "Others",
          sub: [
            "Send personal contact in first letter",
            "Push to send photos or personal info",
            "Suicidal or self-harm",
            "Others",
          ],
          last: 3,
        },
      ],
      group: null,
      reason: null,
      remark: "",
      comments: "",
    };
  }
  rtlDetect = (text) =>
    /[\u0590-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/.test(text);

  stripHtml = (html) => {
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || ""
  };

  readMore = () => {
    this.setState({
      showAbout: true,
    });
  };

  readLess = () => {
    this.setState({
      showAbout: false,
      comments: "",
    });
  };

  beginEditRemark = () => {
    this.setState({
      editRemark: true,
      remark: !!this.props.friend.customdesc
        ? this.props.friend.customdesc
        : "",
    });
    // this.scroller.scrollToTop()
  };

  endEditRemark = () => {
    this.setState({
      editRemark: false,
    });
  };

  remarkOnChange = (ev) => {
    this.setState({
      remark: ev.target.value,
    });
  };

  saveRemark = () => {
    const { me, friend } = this.props;
    const { remark } = this.state;
    this.props.saveRemark(me.token, friend.id, remark);
    this.endEditRemark();
  };

  toggleFav = () => {
    const { friend, me, favPost, unfavPost } = this.props;
    if (friend.fav === 1) unfavPost(me.token, friend.id);
    else favPost(me.token, friend.id);
  };

  closeAlert = () => {
    this.setState({
      alert: null,
    });
  };

  _handleAudioShare = () => {
    if (this.props.audioSharingLabel === "PENDING_REQUEST") return false;
    const { friend, me } = this.props;

    if (friend.allowaudio === 1) {
      this.setState({
        alert: {
          title: friend.name,
          message: I18n.t("CONFIRM_STOP_AUDIOSHARING"),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("CONFIRM"),
          confirmAction: () => {
            this.props.updateAudioSharing(friend.id, 0, me);
            this.closeAlert();
          },
        },
      });
    } else if (this.props.audioSharingLabel === "ACCEPT_REQUEST") {
      this.props.updateAudioSharing(friend.id, 1, me);
    } else {
      this.setState({
        alert: {
          title: I18n.t("SLOWLY_TIPS"),
          message: I18n.t("CONFIRM_SEND_AUDIOSHARING_REQUEST", {
            name: friend.name,
          }),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("CONFIRM"),
          confirmAction: () => {
            this.props.updateAudioSharing(friend.id, 1, me);
            this.closeAlert();
          },
        },
      });
    }
  };

  _handlePhotoShare = () => {
    if (this.props.photoSharingLabel === "PENDING_REQUEST") return false;
    const { friend, me } = this.props;

    if (friend.allowphotos === 1) {
      this.setState({
        alert: {
          title: friend.name,
          message: I18n.t("CONFIRM_STOP_PHOTOSHARING"),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("CONFIRM"),
          confirmAction: () => {
            this.props.updatePhotoSharing(friend.id, 0, me);
            this.closeAlert();
          },
        },
      });
    } else if (this.props.photoSharingLabel === "ACCEPT_REQUEST") {
      this.props.updatePhotoSharing(friend.id, 1, me);
    } else {
      this.setState({
        alert: {
          title: I18n.t("SLOWLY_TIPS"),
          message: I18n.t("CONFIRM_SEND_PHOTOSHARING_REQUEST", {
            name: friend.name,
          }),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("CONFIRM"),
          confirmAction: () => {
            this.props.updatePhotoSharing(friend.id, 1, me);
            this.closeAlert();
          },
        },
      });
    }
  };

  _hideUser = () => {
    const { friend, me, hideUser } = this.props;

    this.setState({
      alert: {
        message: friend.name,
        title: I18n.t("CONFIRM_HIDE"),
        cancelLabel: I18n.t("CANCEL"),
        cancelAction: this.closeAlert,
        confirmLabel: I18n.t("CONFIRM"),
        confirmAction: () => {
          hideUser(me.token, friend);
          this.closeAlert();
          this.props.handleClose();
        },
      },
    });
  };

  _blockUser = () => {
    const { friend, me, blockUser } = this.props;

    this.setState({
      alert: {
        message: friend.name,
        title: I18n.t("CONFIRM_BLOCK"),
        cancelLabel: I18n.t("CANCEL"),
        cancelAction: this.closeAlert,
        confirmLabel: I18n.t("REMOVE"),
        confirmAction: () => {
          blockUser(me.token, friend);
          this.closeAlert();
          this.props.handleClose();
        },
        danger: true
      },
    });
  };

  _reportUser = () => {
    this.setState({
      reporting: true,
    });
  };

  cancelReport = () => {
    this.setState({ reporting: false, reason: null, comments: "" });
  };

  selectReason = (reason) => {
    this.setState({ reason });
  };

  selectGroup = (index) => {
    if (index !== this.state.group)
      this.setState({ group: index, reason: null });
    else this.setState({ group: null });
  };

  _report = () => {
    if (this.state.reason === null || !!this.state.loading) return false;

    const { friend } = this.props;

    this.setState({
      alert: {
        message: I18n.t("CONFIRM_DELETE_ACC_MSG"),
        title: I18n.t("PASS_CONFIRM_REMINDER", { name: friend.name }),
        cancelLabel: I18n.t("CANCEL"),
        cancelAction: this.closeAlert,
        confirmLabel: I18n.t("REPORT"),
        confirmAction: () => {
          this.closeAlert();
          this._reportConfirm();
        },
        danger: true
      },
    });
  };

  _reportConfirm = () => {
    global.log("_reportConfirm");
    const { reasons } = this.state;
    this.setState({
      loading: true,
    });

    this.props.reportUser(
      this.props.me.token,
      {
        postId: this.props.friend.id,
        from_id: this.props.me.id,
        from_name: this.props.me.name,
        user_id: this.props.friend.user_id,
        user_name: this.props.friend.name,
        reason:
          reasons[this.state.group].group +
          " - " +
          reasons[this.state.group].sub[this.state.reason],
        comments: this.state.comments,
      },
      this.props.friend
    );
  };

  onClose = () => {
    if (!!this.state.reporting) return false;

    this.setState({
      showAbout: false,
      editRemark: false,
      reporting: false,
      reason: null,
      comments: "",
      expanded: false,
      showStampCollectionModal: false
    })

    this.props.handleClose();
  };

  renderFavStamp = (item,index) => {
    return(
      <button
        key={'item'+ item} className="fav-stamp btn btn-default my-1 text-center stamp-zoomable"
        onClick={()=> { this.showStamp(item) }}
      >
        <StampSmall slug={item} size={80} darkMode={this.props.darkMode} />
      </button>
    )
  }

  renderTag = (topic, other=false) =>{
    if (this.state.expanded && topic.subtopics.length > 0){
      return (
        <div className="flex-wrap d-inline" key={topic.tag}>
          <span
            className={ other ? "badge badge-pill badge-tag" : "badge badge-pill badge-tag badge-active"}
            key={topic.tag}
          >
            {I18n.t(topic.tag)} <span className="semi-trans ml-1">({topic.subtopics.length})</span>
          </span>
          {topic.subtopics.map((sub, subIndex) => {
              return (
                <span
                  className="badge badge-pill badge-tag badge-subtopic"
                  key={'sub'+ subIndex}
                >
                  {sub.name}
                </span>
              );
            })}
        </div>
      )
    }else{
      return (
        <span
          className={ other ? "badge badge-pill badge-tag" : "badge badge-pill badge-tag badge-active"}
          key={topic.tag}
        >
          {I18n.t(topic.tag)}
          { topic.subtopics.length>0 && (
            <span className="semi-trans ml-1">{"("+topic.subtopics.length+")"}</span>
          )}
        </span>
      )
    }
  }

  mapTopics = (topic,index) => {
    return this.renderTag(topic, false);
  }

  mapOtherTopics = (topic,index) => {
    return this.renderTag(topic, true);
  }

  toggleSub = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  showStampCollection = () => {
    global.log('showStampCollection')
    this.setState({
      showStampCollectionModal: true
    })
  }

  closeStampCollection = () => {
    this.setState({
      showStampCollectionModal: false
    })
  }

  showStamp = (item) => {
    this.setState({
      stamp: this.props.slowlyItems[item]
    })
  }

  closeStamp = () => {
    this.setState({
      stamp: null
    })
  }

  render() {
    const {
      show,
      photoSharingLabel,
      audioSharingLabel,
      readOnly=false,
    } = this.props;

    const { show_last_login } = this.props.me

    const friend = !!this.props.user ? this.props.user : this.props.friend
    if (!friend) return null;

    const {
      extend={},
      commonTopics = [],
      otherTopics = [],
    } = friend;

    const realDistance = parseFloat(friend.distance)
    const distance =  !I18n.countryWithMiles() ? realDistance <= 100 ? I18n.t('LESS_THAN_KM', { distance:100}) : "≈" + I18n.toNumber(Math.round(realDistance/100)*100, { precision: 0}) + I18n.t('KM_AWAY')
    : realDistance <= 100 ? I18n.t('LESS_THAN_MILES', { distance: 60 }) : "≈" + I18n.toNumber(Math.round(realDistance/1.609344/100)*100, { precision: 0}) + I18n.t('MILES_AWAY')

    const trimmed = truncate(friend.about, {
      length: 200,
    });

    const { reporting, reasons } = this.state;
    const selectedReasons =
      this.state.group !== null ? reasons[this.state.group] : null;

      global.log('reading friend profile', friend)

    return (
      <>
        <Modal
          show={show}
          onHide={this.onClose}
          dialogClassName="profile"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {!reporting ? (
            <Modal.Header>
              <div className="text-center p-0 w-100 position-relative">
                <h6 className="text-primary pt-2">
                  {
                    !this.state.showStampCollectionModal ? friend.name : I18n.t('STAMP_COLLECTION')
                  }
                </h6>
                { !this.state.showStampCollectionModal && (<div className="avatar-wrapper-2">
                  <Avatar
                    key={friend.id}
                    uid={friend.id}
                    id={friend.avatar}
                    gender={friend.gender}
                    name={friend.name}
                    size={180}
                  />
                  {!!friend.emoji_status && (
                    <div className="bubble">{ friend.emoji_status }</div>
                  )}
                </div>)}
                { !readOnly && !this.state.showStampCollectionModal && !this.state.editRemark && !reporting && (
                  <div
                    className="position-absolute m-1"
                    style={{ right: -4, bottom: -4 }}
                  >
                    <button
                      className="btn btn-light btn-sm"
                      onClick={this.beginEditRemark}
                    >
                      {!!friend.customdesc
                        ? I18n.t("EDIT_REMARK")
                        : I18n.t("ADD_REMARK")}
                    </button>
                  </div>
                )}
                { this.state.showStampCollectionModal && (
                  <div
                    className="position-absolute"
                    style={{ top: 0, left: -4 }}
                  >
                    <button className="btn btn-default" onClick={this.closeStampCollection}>
                      <i className="icon-chevron-left mr-1" />{I18n.t('BACK')}
                    </button>
                  </div>
                )}
                <div
                  className="position-absolute"
                  style={{ top: 0, right: -5 }}
                >
                  <button className="btn btn-default" onClick={this.onClose}>
                    <i className="icon-close h5" />
                  </button>
                </div>
                { !this.state.showStampCollectionModal && !!friend.plus && (
                  <div className="plus-badge m-1">
                    <i className="icon-plus-inline text-gold h5" />
                  </div>
                )}
              </div>
            </Modal.Header>
          ) : (
            <Modal.Header>
              <div className="w-100 mb-n1 px-2">
                <small className="text-light">
                  {I18n.t("REPORT_USER")} - {friend.name}{" "}
                </small>
                <h4 className="text-primary mt-1 align-left w-100">
                  {I18n.t("REASON")}
                </h4>
              </div>
            </Modal.Header>
          )}
          { !!this.state.showStampCollectionModal ? (
            <Modal.Body className="stamp-collection" key="stamp-collection">
              <StampCollection
                key={friend.id}
                user={friend.name}
                collection={ (!!friend.extend && !!friend.extend.stamp_collection) ? friend.extend.stamp_collection.data : []}
                onClose={this.stampCollectionClosed}
                openProfile={this.showFriend}
                showStamp={this.showStamp}
              />
            </Modal.Body>
        ): (<Modal.Body className={!!readOnly && 'read-only' }>
            <div className="profile-wrapper" dir="ltr">
              {!!reporting ? (
                <div className="p-3 py-1 my-n1">
                  {reasons.map((item, index) => (
                    <div key={"reason-group-" + index} className="card my-2">
                      <div
                        onClick={() => {
                          this.selectGroup(index);
                        }}
                        className={
                          "card-header link d-flex justify-content-between align-items-center"
                        }
                      >
                        <i
                          className={
                            this.state.group === index
                              ? "icon-chevron-down mr-2 text-primary"
                              : "icon-chevron-right mr-3 text-primary"
                          }
                        />
                        <div
                          className={
                            this.state.group === index
                              ? "flex-grow-1 strong"
                              : "flex-grow-1"
                          }
                        >
                          {I18n.t(item.group)}
                        </div>
                      </div>
                      {this.state.group === index && (
                        <div className="list-group list-group-flush">
                          {selectedReasons.sub.map((item, index) => (
                            <button
                              className="list-group-item list-group-item-action"
                              onClick={() => {
                                this.selectReason(index);
                              }}
                              key={"reason-" + item}
                            >
                              <i
                                className={
                                  this.state.reason === index
                                    ? "icon-checkbox-checked mr-3 text-positive"
                                    : "icon-checkbox-unchecked mr-3 text-lighter"
                                }
                              />
                              {I18n.t(item)}
                            </button>
                          ))}
                        </div>
                      )}
                      {this.state.group === index &&
                        reasons[this.state.group].last ===
                          this.state.reason && (
                          <div className="p-3">
                            <textarea
                              row={3}
                              style={{ resize: "none", height: 100 }}
                              className="form-control"
                              onChange={(ev) => {
                                this.setState({
                                  comments: ev.target.value,
                                });
                              }}
                              value={this.state.comments}
                              placeholder={I18n.t("TELL_US_MORE")}
                            />
                          </div>
                        )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="profile-container"  dir="auto">
                  <div className="small text-light flex-grow-1">
                    {I18n.t("ABOUT_USER", { name: friend.name })}{" "}
                  </div>
                  {!!this.state.editRemark ? (

                      <div className="d-flex mt-2 mb-5">
                        <div className="flex-grow-1 mr-2">
                          <form onSubmit={this.saveRemark}>
                            <input
                              type="text"
                              className="form-control "
                              rows="3"
                              value={this.state.remark}
                              onChange={this.remarkOnChange}
                              maxLength={200}
                              placeholder={I18n.t("ADD_REMARK")}
                              autoFocus
                            />
                          </form>
                        </div>
                        <div>
                          <button
                            className="btn btn-primary mr-1"
                            onClick={this.saveRemark}
                          >
                            {I18n.t("SAVE")}
                          </button>
                          <button
                            className="btn btn-outline-light"
                            onClick={this.endEditRemark}
                          >
                            {I18n.t("CANCEL")}
                          </button>
                        </div>
                      </div>
                  ) : (
                    !!friend.customdesc && (
                      <blockquote className="blockquote mb-3">
                        <p className="mb-0">{friend.customdesc}</p>
                      </blockquote>
                    )
                  )}

                  {!!friend.about && (
                    <>
                      <p
                        className={
                          !!this.state.showAbout
                            ? "mt-1 about pre-wrap expanded"
                            : "mt-1 about pre-wrap "
                        }
                      >
                        {!!this.state.showAbout ? friend.about : trimmed}
                      </p>
                      {friend.about !== trimmed && !this.state.showAbout && (
                        <button
                          type="button"
                          className="btn btn-sm btn-block btn-light mt-n2"
                          onClick={this.readMore}
                        >
                          <i className="icon-chevron-down" />
                        </button>
                      )}
                      {!!this.state.showAbout && (
                        <button
                          type="button"
                          className="btn btn-sm btn-block btn-light mt-n2"
                          onClick={this.readLess}
                        >
                          <i className="icon-chevron-up" />
                        </button>
                      )}
                    </>
                  )}
                  <table className="table table-borderless profile-list table-sm">
                    <tbody>
                      {!!friend.dob && friend.dob_privacy >= 1 && (
                        <tr>
                          <td className="icon-td">
                            <i className="icon-cake text-calm"></i>
                          </td>
                          <td>
                            <span className={ !!friend.birthdaySoon ? "strong" : ""}>
                            {(friend.dob_privacy === 3 ||
                              friend.dob_privacy === 5 ||
                              friend.dob_privacy === 6) &&
                              moment.custom(friend.dob, "birthday")}
                            {friend.dob_privacy >= 4 &&
                              friend.age &&
                              " (" + friend.age + ")"}
                            </span>
                            {!!friend.zodiac && friend.dob_privacy < 6 && (
                              <span>
                                {" "}
                                <i
                                  className={
                                    "icon-" + friend.zodiac + " text-lighter"
                                  }
                                ></i>{" "}
                                {I18n.t(friend.zodiac)}
                              </span>
                            )}
                          </td>
                        </tr>
                      )}
                      { !!friend.birthdaySoon &&(
                        <tr>
                          <td />
                          <td>
                            <small className="mb-1 text-calm">
                              {I18n.t('BIRTHDAY_REMINDER_CTA')+ '✍️🎉' }
                            </small>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="icon-td">
                          <i
                            className={
                              friend.gender === "male"
                                ? "icon-mars text-calm"
                                : friend.gender === "female"
                                ? "icon-venus text-calm"
                                : "icon-circle-o text-calm"
                            }
                          ></i>
                        </td>
                        <td>
                          {I18n.t("GENDER") + ": " + I18n.t(friend.gender)}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-td">
                          <i className="icon-pin text-calm"></i>
                        </td>
                        <td>
                          {friend.location_code === "REMOVED"
                            ? I18n.t("NA")
                            : I18n.country(friend.location_code)}
                        </td>
                      </tr>
                      {friend.location_code !== "REMOVED" && (
                        <tr>
                          <td className="icon-td">
                            <i className="icon-arrows1 text-calm"></i>
                          </td>
                          <td>{distance}</td>
                        </tr>
                      )}
                      {friend.location_code !== "REMOVED" && (
                        <tr>
                          <td className="icon-td">
                            <i className="icon-send text-calm"></i>
                          </td>
                          <td>
                            {I18n.t("LETTER_DELIVERS", {
                              time: moment.custom(friend.delivery_mins, "mins"),
                            })}
                          </td>
                        </tr>
                      )}

                      {!!show_last_login && (
                        <tr>
                          <td className="icon-td">
                            <i className="icon-signin text-calm"></i>
                          </td>
                          <td>
                            {I18n.t("LAST_ONLINE", {
                              time: friend.last_login
                                ? moment.custom(friend.last_login, "fromNow")
                                : I18n.t("NOT_AVAILABLE"),
                            })}
                          </td>
                        </tr>
                      )}

                      {!!friend.account_age && (
                        <tr>
                          <td className="icon-td">
                            <i className="icon-clock text-calm"></i>
                          </td>
                          <td>
                            {I18n.t("JOINED", {
                              time: moment.custom(
                                friend.account_age.value,
                                "joined"
                              ),
                            })}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td className="icon-td">
                          <i className="icon-stats text-calm"></i>
                        </td>
                        <td>
                          {!!extend.ratio
                            ? I18n.t("SENT_RECEIVED_RATIO") +
                              " ( " +
                              extend.ratio +
                              " )"
                            : I18n.t("SENT_RECEIVED_RATIO") +
                              " ( " +
                              I18n.t("NO_DATA") +
                              " )"}
                        </td>
                      </tr>

                      {!!friend.by_id && (
                        <tr>
                          <td className="icon-td">
                            <i className="icon-users text-calm"></i>
                          </td>
                          <td>
                            {I18n.t("CONNECTED_BY_ID")}
                            <br />
                            <small className="text-light">
                              {I18n.t("FRIEND_BY_ID_RATIO_NOT_COUNT")}
                            </small>
                          </td>
                        </tr>
                      )}

                      {!readOnly && (
                        <tr>
                          <td className="icon-td">
                            <i className="icon-image text-calm"></i>
                          </td>
                          <td className="position-relative">
                              {I18n.t("PHOTO_SHARING")}
                              <div className="position-absolute" style={{ right: 0, top: 2 }}>
                                <button
                                  className={
                                    !!friend.allowphotos ? "btn btn-sm btn-outline-success py-0 px-2"
                                    : "btn btn-sm btn-light py-0 px-2"
                                  }
                                  onClick={this._handlePhotoShare}
                                >
                                  {!!friend.allowphotos && (
                                    <i className="icon-check-alt mr-1" />
                                  )}
                                  {I18n.t(photoSharingLabel)}
                                </button>
                              </div>
                          </td>
                        </tr>
                      )}
                      {!readOnly && (
                        <tr>
                          <td className="icon-td">
                            <i className="icon-mic text-calm"></i>
                          </td>
                          <td className="position-relative">
                              {I18n.t("AUDIO_SHARING")}
                              <div className="position-absolute" style={{ right: 0, top: 2 }}>
                                <button
                                  className={
                                    !!friend.allowaudio ? "btn btn-sm btn-outline-success py-0 px-2"
                                    : "btn btn-sm btn-light py-0 px-2"
                                  }
                                  onClick={this._handleAudioShare}
                                >
                                  { !!friend.allowaudio && (
                                    <i className="icon-check-alt mr-1" />
                                  )}
                                  {I18n.t(audioSharingLabel)}
                                </button>
                              </div>
                          </td>
                        </tr>
                      )}
                      {!readOnly && (
                        <tr>
                          <td className="icon-td" />
                          <td className="py-0">
                            <small className="text-light">
                              {I18n.t("MEDIA_SHARING_TIPS")}
                            </small>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {!!extend.fav_stamps && extend.fav_stamps.length > 0 && (
                    <>
                      <hr className="mt-1 mb-3" />
                      <div className="small text-light">
                        <i className="icon-heart-o mr-1" /> {I18n.t("FAV_STAMPS")}
                      </div>
                      <div className="d-flex flex-wrap mb-3">
                        {extend.fav_stamps.map(this.renderFavStamp)}
                      </div>
                    </>
                  )}
                  {!!extend.stamp_collection && (
                    <table className="table table-borderless profile-list table-sm mt-n2">
                      <tbody>
                        <tr>
                          <td className="icon-td">
                            <i className="icon-th text-calm"></i>
                          </td>
                          <td className="position-relative text-left">
                            {I18n.t('STAMP_COLLECTION')}
                            <div className="position-absolute" style={{ right: 0, top: 4 }}>
                              <button
                                className={"btn btn-sm btn-light py-0 px-2"}
                                onClick={this.showStampCollection}
                              >
                                <strong>{extend.stamp_collection.data && extend.stamp_collection.data.length}</strong>
                                <small><i className="icon-chevron-right pl-2" /></small>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  <hr className="mt-1 mb-3" />
                  {(extend.letter_length >= 0 || extend.reply_time >= 0) && (
                    <div>
                      <div className="small text-light">
                        {I18n.t("WRITING_PREF")}
                      </div>
                      <table className="table table-borderless profile-list table-sm mt-1">
                        <tbody>
                          {extend.letter_length >= 0 && (
                            <tr>
                              <td className="icon-td">
                                <i className="icon-pencil text-calm"></i>
                              </td>
                              <td className="position-relative text-left">
                                {I18n.t('LETTER_LENGTH') + ': '}
                                <strong>{I18n.t('LETTER_LENGTH_' + extend.letter_length)}</strong>
                              </td>
                            </tr>
                          )}
                          {extend.reply_time >= 0 && (
                            <tr>
                              <td className="icon-td">
                                <i className="icon-clock text-calm"></i>
                              </td>
                              <td className="position-relative text-left">
                                {I18n.t('REPLY_TIME') + ': '}
                                <strong>{I18n.t('REPLY_TIME_' + extend.reply_time)}</strong>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <hr className="mt-1 mb-3" />
                    </div>
                  )}
                  <div className="small text-light position-relative">
                    {I18n.t("TOPICS")}
                    {!!extend.subtopics && extend.subtopics.data.length > 0 && (  <div className="position-absolute" style={{ right: 0, top: 2 }}>
                      <button
                        className="btn btn-sm btn-light py-0 px-2 smaller"
                        onClick={this.toggleSub}
                      >
                        {this.state.expanded
                        ? I18n.t('HIDE_SUBTOPICS')
                        : I18n.t('SHOW_SUBTOPICS')}
                      </button>
                    </div>)}
                  </div>
                  <div className="my-3">
                    <div className="tags-holder h5 m-0">
                      {commonTopics.map(this.mapTopics)}
                    </div>
                    <div className="tags-holder h5 m-0">
                      {otherTopics.map(this.mapOtherTopics)}
                    </div>
                  </div>
                  <p className="text-light mb-3 small">
                    <i className="icon-checkbox text-calm" />{" "}
                    {I18n.t("COMMON_TOPICS")}
                    {!!extend.subtopics && extend.subtopics.data.length > 0 && (
                      <span>
                        <i className="icon-checkbox subtopic pl-3" />{" "}
                        {' ' + I18n.t('SUBTOPICS')}
                      </span>
                    )}
                    {otherTopics.length > 0 && (
                      <span>
                        <i className="icon-checkbox-o text-calm pl-3" />{" "}
                        {' ' + I18n.t('OTHER_TOPICS')}
                      </span>
                    )}
                  </p>
                  <hr className="mt-2 mb-4" />
                  <div className="small text-light">{I18n.t("LANGS")}</div>
                  <div className="tags-holder mt-3 mb-4 row">
                    {!!extend.lang ?
                      extend.lang.map((lang) => {
                        return (
                          <div className="col-6 mb-2" key={"lang-" + lang.slug}>
                            {lang.native}
                            {lang.level > 0 ? (
                              <div className="small lang-level">
                                <i className="icon-circle text-calm" />
                                <i
                                  className={
                                    lang.level >= 2
                                      ? "icon-circle text-calm"
                                      : "icon-circle-o"
                                  }
                                />
                                <i
                                  className={
                                    lang.level >= 3
                                      ? "icon-circle text-calm"
                                      : "icon-circle-o"
                                  }
                                />
                                <i
                                  className={
                                    lang.level >= 4
                                      ? "icon-circle text-calm"
                                      : "icon-circle-o"
                                  }
                                />
                                <i
                                  className={
                                    lang.level >= 5
                                      ? "icon-circle text-calm"
                                      : "icon-circle-o"
                                  }
                                />
                              </div>
                            ) : (
                              <div className="small text-lighter">
                                {I18n.t("LEARNING")}
                              </div>
                            )}
                          </div>
                        );
                      }) : (
                        <div className="small p-3">
                          <span
                            className="spinner-grow spinner-grow-sm mr-2 text-warning"
                            role="status"
                            aria-hidden="true"
                          />
                          {I18n.t("LOADING")}
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>)}
          {!readOnly && !this.state.showStampCollectionModal && (
            <Modal.Footer className="bg-stable-darker">
              <div className="w-100">
                {!!reporting ? (
                  <div className="px-2 text-center d-flex">
                    <button
                      type="button"
                      className="btn btn-default mr-1 flex-grow-1"
                      onClick={this.cancelReport}
                      disabled={!!this.state.loading}
                    >
                      {I18n.t("CANCEL")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger ml-1  flex-grow-1"
                      onClick={this._report}
                      disabled={
                        this.state.reason === null || !!this.state.loading
                      }
                    >
                      {I18n.t("REPORT")}
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <button
                        type="button"
                        className={
                          friend.fav === 1
                            ? "btn btn-sm btn-secondary"
                            : "btn btn-sm btn-primary active"
                        }
                        onClick={this.toggleFav}
                      >
                        <span className="mx-2">
                          <i
                            className={
                              friend.fav === 1
                                ? "icon-star mr-1"
                                : "icon-star-o mr-1"
                            }
                          />
                          {friend.fav === 1
                            ? I18n.t("UNMARK_STARRED")
                            : I18n.t("STARRED")}
                        </span>
                      </button>
                    </div>
                    <div>
                      <Dropdown drop="up">
                        <Dropdown.Toggle variant="default">
                          <i className="icon-more h6 px-2" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          alignRight={this.props.isRTL ? false : true}
                        >
                          <Dropdown.Item
                            eventKey="HIDE_USER"
                            onSelect={this._hideUser}
                          >
                            <i className="icon-hidden text-lighter mr-2" />
                            <span className="mr-2">{I18n.t("HIDE_USER")}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="BLOCK_USER"
                            onSelect={this._blockUser}
                          >
                            <i className="icon-remove text-lighter mr-2" />
                            <span className="mr-2">{I18n.t("BLOCK_USER")}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="REPORT_USER"
                            onSelect={this._reportUser}
                          >
                            <i className="icon-alert text-danger mr-2" />
                            <span className="text-danger mr-2">
                              {I18n.t("REPORT_USER")}
                            </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Footer>
          )}
          <ShowStamp stamp={this.state.stamp} closeStamp={this.closeStamp} />
        </Modal>
        <AppAlert alert={this.state.alert} />

      </>
    );
  }
}

const favPost = function favPost(token, postID) {
  return {
    type: "FAV_POST",
    token,
    postID,
  };
};

const unfavPost = function unfavPost(token, postID) {
  return {
    type: "UNFAV_POST",
    token,
    postID,
  };
};

const blockUser = function blockUser(token, user) {
  return {
    type: "BLOCK_USER",
    token,
    user,
  };
};

const hideUser = function hideUser(token, user) {
  return {
    type: "HIDE_USER",
    token,
    user,
  };
};

const saveRemark = function saveRemark(token, postID, remarks) {
  return {
    type: "SAVE_REMARK",
    token,
    postID,
    remarks,
  };
};

const updatePhotoSharing = function updatePhotoSharing(postID, allow, me) {
  return {
    type: "UPDATE_PHOTO_SHARING",
    postID,
    allow,
    me,
  };
};

const updateAudioSharing = function updateAudioSharing(postID, allow, me) {
  return {
    type: "UPDATE_AUDIO_SHARING",
    postID,
    allow,
    me,
  };
};

const reportUser = function reportUser(token, fields, user) {
  return {
    type: "REPORT_USER",
    token,
    fields,
    user,
  };
};

const makeMapStateToProps = () => {
  // const getcommonTopics = reFullcommonTopics();
  const getPhotoSharingLabel = reSharingPhotoLabel();
  const getAudioSharingLabel = reSharingAudioLabel();

  const mapStateToProps = (state, props) => {
    return {
      friend: state.contacts[props.postID],
      me: reProfileMe(state),
      photoSharingLabel: getPhotoSharingLabel(state, props),
      audioSharingLabel: getAudioSharingLabel(state, props),
      slowlyItems: reKeyedItems(state),
      isRTL: state.slowly.isRTL,
      darkMode: state.slowly.darkMode
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps, {
  favPost,
  unfavPost,
  blockUser,
  saveRemark,
  hideUser,
  updatePhotoSharing,
  updateAudioSharing,
  reportUser,
})(FriendProfile);
