import React, { Component } from "react";
import { connect } from "react-redux";
import Emoji from "../components/Emoji";
import { reProfileMe, reUnlocked } from "../selectors";
import { getStampList, draw } from "../api/slowly.api";
import StampRandomItem from "../components/StampRandomItem";
import _ from "lodash";
import I18n from "../I18n";
import hashids from "../lib/hashids";
import { toast } from "react-toastify";
import coin from "../assets/images/coin.png";
import AppAlert from "./AppAlert";
import Loader from "./Loader";

class StampStoreRandom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setInfo: this.props.item,
      showing: null,
      data: [],
      loading: true,
      ready: false,
      alert: null,
    };
  }

  componentDidMount() {
    if (this.props.item) {
      this.loadData(this.props.item.id);
    } else {
      const id = hashids.decode(this.props.list);
      global.log("loading random list", id);
      if (id && id.length > 0) this.loadData(id[0], false);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list) {
      this.setState({
        ready: false,
        data: [],
        loading: true,
      });
      const id = hashids.decode(this.props.list);
      global.log("loading random list", id);
      if (id && id.length > 0) this.loadData(id[0], false);
    }
    if (prevProps.unlocked.length !== this.props.unlocked.length)
      this.mapData(this.state.data);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  loadData = async (id, skip = true) => {
    if (this.unmounted) return false;

    try {
      this.setState({ loading: true });
      const data = await getStampList(id);
      global.log("get stamp list success", data);
      this.mapData(data.stamps);
      this.setState({
        setInfo: data.set,
      });
    } catch (error) {
      global.log("error", error);
      toast.error(error.error ? I18n.t(error.error) : I18n.t("ERROR"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        closeOnClick: true,
        ...error,
      });
    }
  };

  mapData = (data) => {
    if (this.unmounted) return false;

    const { unlocked } = this.props;
    let _unlocked = 0;

    const mapped = _.map(data, (i) => {
      const isUnlocked = unlocked.indexOf(i.slug) >= 0;
      if (isUnlocked) _unlocked++;
      return {
        ...i,
        title: I18n.t(i.name),
        desc:
          !!i.country && !i.item_set_id
            ? I18n.country(i.country)
            : I18n.t(i.desc),
        showingItem: i,
        salesInfo: {},
        unlocked: isUnlocked,
      };
    });

    this.setState({
      data: mapped,
      unlocked: _unlocked,
      remaining: data.length - _unlocked,
      loading: false,
    });
  };

  closeAlert = () => {
    this.setState({ alert: null });
  };

  _purchase = () => {
    const { setInfo } = this.state;
    const { coins } = this.props.me;
    // const coins=10
    const { purchasing } = this.state;
    const canPurchaseCoins =
      this.props.ipcountry !== "IN" && this.props.ipcountry !== "RU";

    if (!!purchasing) return false;

    if (coins < setInfo.price) {
      //out of coins
      this.setState({
        alert: {
          title: I18n.t("NOT_ENOUGH_COINS"),
          message: I18n.t("MY_COINS") + ": " + coins,
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: canPurchaseCoins
            ? I18n.t("PURCHASE_COINS")
            : I18n.t("REG_NOTED"),
          confirmAction: canPurchaseCoins
            ? this.props.purchaseCoins
            : this.closeAlert,
        },
      });
    } else {
      //confirm purchase
      this.setState({
        alert: {
          title: I18n.t("CONFIRM_PURCHASE"),
          message: I18n.t("CONFIRM_PURCHASE_MSG", {
            coins: setInfo.price,
          }),
          cancelLabel: I18n.t("CANCEL"),
          confirmLabel: I18n.t("PURCHASE"),
          cancelAction: this.closeAlert,
          confirmAction: this._drawNow,
        },
      });
    }
    return true;
  };

  _drawNow = async () => {
    const { setInfo } = this.state;
    const { token } = this.props.me;

    this.setState({
      purchasing: true,
      alert: null,
    });

    try {
      const { uuid } = this.props;

      const results = await draw({
        token: token,
        device_id: uuid,
        item_set_id: setInfo.id,
        timezone: "",
      });

      global.log("draw results", results);
      this.props.refreshCoins(results.coins);

      this.setState({
        drawResult: results,
        loading: false,
        purchasing: false,
      });
      if (results.newStamps && results.newStamps.length > 0) {
        this.props.refreshStamps({ ...results });
        if (this.props.newPurchase) this.props.newPurchase();
        const found = _.find(this.state.data, { slug: results.newStamps[0] });
        global.log("draw result: ", found);

        toast.warn(
          I18n.t("STAMP_SET_PURCHASED", { item: I18n.t(found.title) }),
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: true,
            closeOnClick: true,
          }
        );
      }
    } catch (error) {
      global.log("error", error);
    }
  };

  render() {
    const { setInfo = {}, data = [], loading } = this.state;

    return (
      <div className="mt-n3">
        <div className="bg-stable store-item-group-wrapper sticky-top  mb-3 border-bottom">
          <div className="pl-1">
            <h3 className="text-darker mt-2">
              {!!setInfo.title ? I18n.t(setInfo.title) : I18n.t("RANDOM_STAMP")}
            </h3>
            <p className="text-light">
              {!!setInfo.body
                ? I18n.t(setInfo.body)
                : I18n.t("RANDOM_STAMP_EXPLAIN")}
            </p>
          </div>
          {setInfo.price && !loading && (
            <div className="d-flex mb-3 align-items-end">
              <button
                className="btn btn-secondary btn-sm mr-auto"
                onClick={this._purchase}
                disabled={this.state.pruchasing || this.state.remaining === 0}
              >
                <img
                  src={coin}
                  height="14"
                  alt={I18n.t("SLOWLY_COINS")}
                  className="mr-1 d-inline-block mt-n1"
                />
                <strong>{setInfo.price + " "}</strong>
                {!!setInfo.original_price && (
                  <span
                    style={{ textDecoration: "line-through" }}
                    className="ml-1"
                  >
                    {setInfo.original_price + " "}
                  </span>
                )}
              </button>
              {!this.state.purchasing && (
                <div className="text-right text-light small mr-3">
                  <span className="noto-emoji">🎲</span>
                  {this.state.remaining === 0
                    ? " " + I18n.t("NA")
                    : this.state.remaining > 0 &&
                      " 1 / " + this.state.remaining}
                </div>
              )}
              {!this.state.purchasing && (
                <div className="text-right text-light small mr-2">
                  {I18n.t("OWNED") + ": " + this.state.unlocked}
                </div>
              )}
              {!!this.state.purchasing && (
                <div className="text-right text-light small mr-2">
                  {I18n.t("LOADING")}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="store-item-group row">
          {data.map((item, index) => (
            <StampRandomItem
              item={item}
              key={"item-" + item.id}
              index={index}
            />
          ))}
          {!!loading && (
            <div className="text-lighter p-3">
              <small>
                <span
                  className="spinner-grow spinner-grow-sm mr-2 text-warning"
                  role="status"
                  aria-hidden="true"
                ></span>
                {I18n.t("LOADING")}
              </small>
            </div>
          )}
        </div>
        <AppAlert alert={this.state.alert} />
        {!!this.state.purchasing && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,.2)",
              zIndex: 9999,
            }}
          >
            <Loader style={{ background: "transparent" }} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    unlocked: reUnlocked(state),
    uuid: state.slowly.uuid,
    cachedStore: state.slowly.store,
    ipcountry: state.slowly.country,
  };
};

const refreshCoins = function refreshCoins(coins, from = "stampRandom") {
  return {
    type: "REFRESH_COINS",
    coins,
    from,
  };
};

const refreshStamps = function refreshStamps({
  items = [],
  newStamps = [],
  isSet = false,
}) {
  return {
    type: "REFRESH_STAMPS",
    from: "StampDetailsScreen",
    items,
    newStamps,
    isSet,
    skipRefresh: true,
  };
};

export default connect(mapStateToProps, {
  refreshCoins,
  refreshStamps,
})(StampStoreRandom);
