import React, { Component } from "react";
import { connect } from "react-redux";
import flags from "../lib/flags";
import { reProfileMe, reUnlocked } from "../selectors";
import {
  getSeries,
  getBestSellers,
  getComingSoon,
  getAllStamps,
  getExclusive,
  getPromoSchedule,
  getStoreItemInfo,
  getSuggestions
} from "../api/slowly.api";

import { Link } from "react-router-dom";
import StampItem from "../components/StampItem";
import { toast } from "react-toastify";
import _ from "lodash";
import I18n from "../I18n";
import moment from "../lib/moment";

import en_country from '../I18n/countries/en'

const _dataAPI = {
  exclusive: getExclusive,
  single: getAllStamps,
  set: getAllStamps,
  series: getSeries,
  bestsellers: getBestSellers,
  comingsoon: getComingSoon,
  "also-like": getSuggestions
};

class StampStoreList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showing: null,
      data: [],
      rawData: [],
      page: 1,
      next_page: null,
      loading: true,
      ready: false,
      suggestions: []
    };

    this.scrollDebounced = _.debounce(this.onScroll, 500);
  }

  componentDidMount() {
    this.loadData(1);
    if (this.props.list === "bestsellers") {
      this.setState({
        subtitle:
          moment.custom(moment.orig().subtract(8, "days"), "birthday") +
          " — " +
          moment.custom(moment.orig().subtract(1, "days"), "birthday"),
      });
    }
    window.addEventListener("scroll", this.scrollDebounced, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list) {
      this.setState({
        ready: false,
      });
      this.loadData(1);
      if (this.props.list === "bestsellers") {
        this.setState({
          subtitle:
            moment.custom(moment.orig().subtract(8, "days"), "birthday") +
            " — " +
            moment.custom(moment.orig().subtract(1, "days"), "birthday"),
        });
      }
    }
    if (prevProps.unlocked.length !== this.props.unlocked.length) {
      this.mapData()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollDebounced, false);
  }

  onScroll = () => {
    if (this.state.next_page === false || this.state.loading) return false;
    if (this.props.list === "bestsellers" && this.state.page >= 2) return false;
    if (this.hasReachedBottom()) this.loadData(this.state.page + 1);
  };

  hasReachedBottom() {
    return (
      document.body.offsetHeight + document.body.scrollTop >=
      document.body.scrollHeight
    );
  }

  loadData = async (page = 1) => {
    const { list, collection = null } = this.props;
    const { token } = this.props.me;

    this.setState({
      loading: true,
      next_page: false,
    });

    const endpoint = _dataAPI[list];
    if (!endpoint) return false;

    try {
      const result = await endpoint({
        token,
        collection,
        mode: list==='set' ? 'sets' : list ,
        page,
        sorting: "release_date",
      });
      global.log("get data success", result);

      if (result.data) {
        if (
          page === 1 &&
          list === "exclusive" &&
          result.data[0] &&
          result.data[0].stamps[0].country !== this.props.cachedStore.store
        ) {
          this.props.updateStore({ store: result.data[0].stamps[0].country });
          this.props.refreshMe(this.props.me.token);
        }

        this.setState(
          {
            rawData:
              page === 1
                ? result.data
                : [...this.state.rawData, ...result.data],
            page,
            next_page: !!result.next_page_url,
          },
          this.mapData
        );

        if(list==='exclusive'){
          const result = await getSuggestions({ token });
          global.log('getSuggestions success', result);

          if(result.data){
            await this.setState({
              suggestionsRaw: result.data
            })
            this.mapSuggestions()
          }
        }
      }
    } catch (error) {
      global.log("get result error", error);
      toast.error( error.error ? I18n.t(error.error) : I18n.t('ERROR'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        closeOnClick: true,
        ...error,
      });
    }
  };

  mapSuggestions = async() => {

    const { suggestionsRaw } = this.state
    if(!suggestionsRaw || suggestionsRaw.length<=0) return false;
    const { unlocked } = this.props;

    const mapped = _.map(suggestionsRaw, d => {
      const extraInfo = getStoreItemInfo(d);
      const salesInfo = getPromoSchedule(d);
      global.log('salesInfo', salesInfo)
      
      return {
        ...d,
        ...extraInfo,
        unlocked: d.stamps.length > 0 ? unlocked.indexOf(d.stamps[0].slug) >= 0 : false,
        salesInfo,
      };
    });
    
    const ordered = _.orderBy(mapped, [ 
      'unlocked', 
      d => {
        return !!d.salesInfo.isActive ? 1 : 0
      }], ['asc', 'desc'])
    global.log('suggestions mapped', ordered)

    this.setState({ suggestions: _.take(ordered,6), moreSuggestions: ordered.length>6 });
  }

  mapData = async () => {
    const { unlocked, cachedStore } = this.props;
    const mapped = _.map(this.state.rawData, (d) => {
      const extraInfo = getStoreItemInfo(d);
      const salesInfo = getPromoSchedule(d, cachedStore.timezone);
      return {
        ...d,
        ...extraInfo,
        unlocked:
          d.stamps.length > 0 ? unlocked.indexOf(d.stamps[0].slug) >= 0 : false,
        salesInfo,
      };
    });

    global.log("mapped", mapped);
    this.setState({
      data:
        this.props.list === "exclusive"
          ? _.orderBy(
              mapped,
              [
                (d) => {
                  return !!d.salesInfo.isActive ? 1 : 0;
                },
                "price",
                "rank",
              ],
              ["desc", "desc", "desc"]
            )
          : mapped,
      loading: false,
      ready: true,
    });
  };

  render() {
    const { list = "" } = this.props;
    const { data = [], loading, ready } = this.state;

    if (!ready) {
      return (
        <div className="text-lighter text-center mt-5">
          <small>
            <span
              className="spinner-grow spinner-grow-sm mr-2 text-warning"
              role="status"
              aria-hidden="true"
            />
            {I18n.t("LOADING")}
          </small>
        </div>
      );
    }

    return (
      <div>
        <div className="store-item-group-wrapper sticky-top border-bottom mb-3 bg-stable d-flex">
          {list === "also-like" ? (
            <div className="pl-1">
              <h3 className="text-darker mt-0 mb-3">
                <span className="mr-2" >💛</span>
                {I18n.t("YOU_MIGHT_ALSO_LIKE")}
              </h3>
            </div>
          ) : list === "exclusive" ? (
            <div className="pl-1 mr-auto">
              <small className="text-lighter">
                {I18n.t("STAMP_SET_SERIES")}
              </small>
              <h3 className="text-darker mt-0 mb-3">
                {!!flags[this.props.cachedStore.store] && !!flags[this.props.cachedStore.store] && flags[this.props.cachedStore.store].emoji}
                {I18n.country(this.props.cachedStore.store)}
              </h3>
            </div>
          ) : list === "comingsoon" ? (
            <div className="pl-1">
              <h3 className="text-darker mt-0 mb-3">
                <span className="noto-emoji mr-2" >⏰</span>
                {I18n.t("Coming Soon")}
              </h3>
            </div>
          ) : list === "bestsellers" ? (
            <div className="pl-1">
              <h3 className="text-darker mt-0 mb-1">
                {I18n.t("BEST_SELLERS")}
              </h3>
              <p className=" text-light">
                <span className="noto-emoji mr-2" >🔥</span>
                {this.state.subtitle}
              </p>
            </div>
          ) : list === "single" ? (
            <div className="pl-1">
              <h3 className="text-darker mb-1">{I18n.t("STAMP_SINGLE")}</h3>
              <p className="text-light">
                <span className="noto-emoji mr-2" >🥳</span>
                {I18n.t("STAMP_LIGHTBOX_CTA")}
              </p>
            </div>
          ) : list === "set" ? (
            <div className="pl-1">
              <h3 className="text-darker mb-1">{I18n.t("STAMP_SET")}</h3>
              <p className="text-light">
                <span className="noto-emoji mr-2" >🥳</span>
                {I18n.t("STAMP_LIGHTBOX_CTA")}
              </p>
            </div>
          ) : list === "series" ? (
            <div className="pl-1">
              <small className="text-lighter">
                {I18n.t("STAMP_SET_SERIES")}
              </small>
              <h3 className="text-darker mt-0 mb-3">
                {!!this.props.seriesTitle
                  ? this.props.seriesTitle
                  : I18n.t(this.props.collection)}
              </h3>
            </div>
          ) : null}
          {list === "exclusive" &&  flags[this.props.cachedStore.store] && (
              <div className="mt-3">
                <a
                  href={"https://feedback.slowly.app/stamp-requests"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  <span className="noto-emoji mr-1" >💬</span>
                  {I18n.t("NO_EXCLUSIVE_SUGGEST")}
                </a>
                <a
                  href={"https://feedback.slowly.app/stamp-requests?search="+en_country[this.props.cachedStore.store]}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-secondary ml-2"
                >
                  <span className="noto-emoji mr-1" >🔺</span>
                  {I18n.t("NO_EXCLUSIVE_CTA", {
                    region: I18n.country(this.props.cachedStore.store),
                  })}
                </a>
              </div>
            )}
        </div>
        <div className="store-item-group row">
          {data.map((item, index) => (
            <StampItem
              item={item}
              key={"item-" + item.id}
              showRank={list === "bestsellers"}
              index={index}
              showStamp={() => {
                this.props.showStamp(item);
              }}
            />
          ))}
          {!!loading && (
            <div className="text-lighter p-3">
              <small>
                <span
                  className="spinner-grow spinner-grow-sm mr-2 text-warning"
                  role="status"
                  aria-hidden="true"
                ></span>
                {I18n.t("LOADING")}
              </small>
            </div>
          )}
          { list !== "exclusive" && data.length === 0 && !loading && this.state.next_page === false && (
            <div className="text-light p-2">
              {this.props.list === "comingsoon"
                ? I18n.t("NO_COMINGSOON_ATM")
                : I18n.t("NOT_FOUND")}
              <div className="mt-3">
                <a
                  href={"https://feedback.slowly.app/stamp-requests"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-light ml-n1"
                >
                  <i className="icon-stamp-small-01 text-calm mr-1" />
                  {I18n.t("Stamp Request")}{" "}
                </a>
              </div>
            </div>
          )}
        </div>
        { list==='exclusive' && this.state.suggestions.length>0 && (
            <div className="store-item-group-wrapper mb-5">
            <div className="border-bottom mb-3 d-flex align-items-center">
            <Link className="text-darker pl-1 mb-2 mr-auto h5" to="/store/list/also-like">
              {I18n.t("YOU_MIGHT_ALSO_LIKE")}
            </Link>
            { this.state.moreSuggestions && (
              <Link className="link-color mr-2 small" to="/store/list/also-like">
                {I18n.t("See All")} <i className="icon-chevron-right" />
              </Link>
            )}
            </div>
            <div className="store-item-group row">
              {this.state.suggestions.map((item) => (
                <StampItem
                  item={item}
                  key={"item-" + item.id}
                  showStamp={() => {
                    this.props.showStamp(item);
                  }}
                />
              ))}
            </div>
          </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    unlocked: reUnlocked(state),
    cachedStore: state.slowly.store,
  };
};

const updateStore = function updateStore(store) {
  return {
    type: "SYNC_STORE_COMPLETE",
    store,
  };
};

const refreshMe = function (token) {
  return {
    type: "GOT_TOKEN",
    token,
    skip: true,
  };
};

export default connect(mapStateToProps, {
  refreshMe,
  updateStore,
})(StampStoreList);
