import {
  INIT_APP,
  ADD_FRIEND,
  GET_FRIENDS_SUCCESS,
  CHECK_FRIEND_REQUEST_FINISH,
  REFRESH_FRIEND,
  FAV_POST,
  UNFAV_POST,
  GET_LETTERS_SUCCESS,
  SORT_FRIENDS,
  UNBLOCK_USER,
  UNBLOCK_USER_SUCCESS,
  BLOCK_USER_SUCCESS,
  REPORT_USER,
  REPORT_NEW_USER,
  HIDE_NEW_USER,
  HIDE_NEW_USER_SUCCESS,
  READ_LETTER_SUCCESS,
  SAVE_REMARK,
  HIDE_USER_SUCCESS,
  UNHIDE_USER,
  UNHIDE_USER_SUCCESS,
  LETTER_REPLY_SUCCESS,
  SAVE_REMINDER,
  UPDATE_PHOTO_SHARING_SUCCESS,
  UPDATE_AUDIO_SHARING_SUCCESS,
  SAVE_POST_PASS,
  ME_ERROR,
  LOGOUT_COMPLETE,
  // UPDATE_WEATHER
  UPDATE_FRIEND_EXTEND,
} from "../actions/type";
import moment from "moment";
import _ from "lodash";
// import I18n from '../I18n';

const defaultState = {
  friendIds: [],
  hidden: [],
  blocked: [],
  activeRequests: [],
  requests: 0,
  loading: false,
};

// let post;

const updateLocalPermission = (post, me, allow) => {
  let user_photos = !!post.allowphotos ? 1 : post.user_photos;
  let joined_photos = !!post.allowphotos ? 1 : post.joined_photos;

  if(me.id === post.joined){
    if(!!post.photorequest) user_photos = 1;
    joined_photos=allow;
  }else{
    if(!!post.photorequest) joined_photos = 1;
    user_photos=allow;
  }

  return {
    ...post,
    user_photos,
    joined_photos,
    allowphotos: (joined_photos===1 && user_photos===1),
    photorequest: 0
  }

}

const updateLocalAudioPermission = (post, me, allow) => {
  let user_audio = !!post.allowaudio ? 1 : post.user_audio;
  let joined_audio = !!post.allowaudio ? 1 : post.joined_audio;

  if(me.id === post.joined){
    if(!!post.audiorequest) user_audio = 1;
    joined_audio=allow;
  }else{
    if(!!post.audiorequest) joined_audio = 1;
    user_audio=allow;
  }

  return {
    ...post,
    user_audio,
    joined_audio,
    allowaudio: (joined_audio===1 && user_audio===1),
    audiorequest: 0
  };
}

const _contacts = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_APP:
      return {
        ...state,
        loading: false,
      };

    case REPORT_USER:
    case REPORT_NEW_USER:
    case UNBLOCK_USER:
    case HIDE_NEW_USER:
    case UNHIDE_USER:
    case ADD_FRIEND:
      return {
        ...state,
        loading: true,
      };

    case HIDE_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CHECK_FRIEND_REQUEST_FINISH:
      return {
        ...state,
        requests: action.requests,
      };

    case GET_FRIENDS_SUCCESS:
      let newState = state;
      newState.friendIds = [];

      action.response.friends.map((post, index) => {
        newState.friendIds.push(post.id);
        newState[post.id] = {
          ...state[post.id],
          ...post,
          isNew:
            post.joined === post.user_id &&
            !post.joined_at &&
            !post.by_id &&
            moment.utc().diff(moment.utc(post.created_at), "days") <= 30,
        };
        return true;
      });

      if (!!newState.blocked) delete newState.blocked;

      return {
        ...newState,
        loading: false,
        requests: action.response.requests,
        hidden: action.response.hidden,
      };

    case SORT_FRIENDS:
      if (action.field === state.sortby) return state;

      return {
        ...state,
        sortby: action.field,
      };

    case SAVE_POST_PASS:
      return {
        ...state,
        [action.postID]: {
          ...state[action.postID],
          pass: action.pass,
        },
      };

    case GET_LETTERS_SUCCESS:
      if (!action.response.user) return state;

      return {
        ...state,
        [action.postID]: {
          ...state[action.postID],
          ...action.response.user,
          ...action.response.post,
          redownload: false,
          user_status: action.response.user.status,
        },
      };

    // case UPDATE_WEATHER:
    //     return {
    //       ...state,
    //       [action.postID]: {
    //         ...state[action.postID],
    //         weather: action.weather
    //       }
    //     };
    case UPDATE_FRIEND_EXTEND:
        return {
          ...state,
          [action.postID]: {
            ...state[action.postID],
            extend: action.extend,
            commonTopics: action.commonTopics,
            otherTopics: action.otherTopics
          }
        };

    case READ_LETTER_SUCCESS:
      return {
        ...state,
        [action.postID]: {
          ...state[action.postID],
          unread:
            state[action.postID].unread === 0
              ? 0
              : state[action.postID].unread - 1,
        },
      };

    case REFRESH_FRIEND:
      return {
        ...state,
        [action.postID]: {
          ...state[action.postID],
          redownload: true,
        },
      };
    case FAV_POST:
      return {
        ...state,
        [action.postID]: {
          ...state[action.postID],
          fav: 1,
        },
      };

    case UNFAV_POST:
      return {
        ...state,
        [action.postID]: {
          ...state[action.postID],
          fav: 0,
        },
      };

    case HIDE_USER_SUCCESS:
      return {
        ...state,
        hidden: [...state.hidden, { ...action.user }],
        friendIds: _.filter(state.friendIds, (item) => item !== action.user.id),
        loading: false,
      };

    case UNHIDE_USER_SUCCESS:
      return {
        ...state,
        hidden: _.filter(state.hidden, (item) => item.id !== action.user.id),
        loading: false,
      };

    case UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        friendIds: _.filter(state.friendIds, (item) => item !== action.user.id),
        loading: false,
      };

    case SAVE_REMARK:
      return {
        ...state,
        [action.postID]: {
          ...state[action.postID],
          customdesc: action.remarks,
        },
      };

    case LETTER_REPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.postID]: {
          ...state[action.postID],
          latest_comment: action.comment.deliver_at,
          latest_sent_by: action.comment.user,
          total: state[action.postID].total + 1,
        },
      };

    case UPDATE_AUDIO_SHARING_SUCCESS:
      return {
        ...state,
        [action.postID]: {
          ...updateLocalAudioPermission(state[action.postID], action.me, action.allow)
        },
        loading: false,
      }

    case UPDATE_PHOTO_SHARING_SUCCESS:
      return{
        ...state,
        [action.postID]: {
          ...updateLocalPermission(state[action.postID], action.me, action.allow)
        },
        loading: false,
      }

    case ME_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SAVE_REMINDER:

      return {
        ...state,
        ['reminder-'+action.meID]: !!state['reminder-'+action.meID] ? [
          ...state['reminder-'+action.meID],
          ...action.ids
        ] : action.ids
      }

    case LOGOUT_COMPLETE:
      return defaultState;

    default:
      return state;
  }
}

export default _contacts
