import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { reProfileMe } from "../selectors";
import Hashids from 'hashids'
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { API_URL } from '../config/ApiConfig'

const __DEV__ = API_URL!=='https://api.getslowly.com'
const hashids = new Hashids('+DP;=SW`DGX&n|]OGoGkj/4XqPw?^Fclc2F-_V~D=rquG+L(kW_xzVR=slp+Yj;B',30);

class Refresh extends Component {
    constructor(props) {
      super(props);
      this.state = {
        retry: 0,
        checking: false,
        poping: false
      };
    }

   getQueryVariable = (query, variable) => {
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.me.role<20 && nextProps.me.role>=20) this.popPlus()
  }

  checkUpgraded = () => {
    if(this.state.checking) return false;

    this.setState({ checking: true })
    const { me } = this.props;
    this.props.refreshMe(me.token)
    this.setState({
      retry: this.state.retry+1
    })

    if(this.state.retry>3){
      //giveup
      toast.error( I18n.t('INTERNAL_ERROR') + ' - ' + I18n.t('STORE_IS_TEMP_CLOSED_TRY_LATER'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        closeOnClick: true
      });
      this.props.history.replace("/home");
    }else{
      setTimeout(()=>{
        this.setState({ checking: false }, this.checkUpgraded)
      }, 3000)
    }
  }

  popPlus = () => {
    if(this.state.poping) return false;

    this.setState({
      poping: true
    })

    const { me, match: { params } } = this.props;
    if(!!params.product || __DEV__){
      const unhashed = hashids.decode(params.product)

      if(unhashed[0]!==me.id && !__DEV__){
        this.props.history.replace("/home");
        return false;
      }
      toast.warn(
        I18n.t("Upgraded to SLOWLY PLUS"),
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          closeOnClick: true
        }
      );

      if(unhashed[1]===12){
        toast.info(
          I18n.t("COINS_ADDED", { coins: 800 }),
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            closeOnClick: true,
          }
        );
        toast.info(
          I18n.t("GOT_MULTI_NEW_STAMPS"),
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            closeOnClick: true
          }
        );
        this.props.refreshStampsSuccess({ newStamps: ['next-level','never-stop-writing' ], isSet: false });
      }else{
        toast.info(
          I18n.t("GOT_NEW_STAMP"),
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            closeOnClick: true
          }
        );
        this.props.refreshStampsSuccess({ newStamps: ['next-level'], isSet: false });
      }
    }
    // this.props.history.replace("/settings/plus");
    this.setState({
      upgraded: true
    })
  }

  componentDidMount() {
    const { match: { params } } = this.props;

    if(params.type==='topup'){
      const unhashed = hashids.decode(params.product)
      if(this.props.me && unhashed[0]===this.props.me.id ){
        global.log('show coins added toast')

        toast.info(
          "💰 " +  I18n.t("COINS_ADDED", { coins: unhashed[2]*unhashed[3] }),
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: true,
            closeOnClick: true,
          }
        );
        setTimeout(()=>{
          this.props.history.replace("/store");
        }, 500 );
        
        return true;

      }else{
        this.props.history.replace("/topup");
        return true;
      }

    }else if(params.type==='upgrade'){
      // check if role is upgraded
      if(this.props.me.role >= 20){
        this.popPlus()
      }else{
        setTimeout(this.checkUpgraded, 3000)
      }
    }
  }

  render() {
    if( this.state.upgraded
        // || __DEV__
      ){
      return (
        <div className={"container enable-sm campaign step" + this.state.step}>
          <div className="row main-row mb-3">
            <div className="col-12 text-center">
              <img
                src="https://promo.slowly.app/promo_2024_upgraded.png"
                style={{ width: 680, maxWidth: "100%" }}
                className="mt-5"
              />
              <h3 className="mt-3 mb-2 text-darker">
                {I18n.t("Upgraded to SLOWLY PLUS")}
              </h3>
              <div>
                { I18n.t("PLUS_ACTIVATED") }
              </div>

              <NavLink
                title={I18n.t("REG_COMPLETE_ACTION")}
                to="/home"
                className="no-underline"
              >
                <div
                  className="btn btn-secondary badge-pill btn-md px-5 mt-4 mb-5 link"
                >
                  <strong>
                    {I18n.t("REG_COMPLETE_ACTION")}
                  </strong>
                </div>
              </NavLink>

              <hr />
              <div className="text-center mb-5">
                <div className="text-lighter ">
                  <a
                    href={"https://slowly.app"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    <i className="icon-slowly mr-1" />
                    Slowly
                  </a>
                  &nbsp; · &nbsp;
                  <a
                    href={"https://slowly.app/terms/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    Terms of Service
                  </a>
                  &nbsp; · &nbsp;
                  <a
                    href={"https://slowly.app/privacy/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <Loader />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    loading: state.slowly.loading
  };
};

const refreshMe = function refreshMe(token) {
  return {
    type: "GOT_TOKEN",
    token,
    skip: true
  };
};

const refreshStampsSuccess = function refreshStampsSuccess({
  items = null,
  newStamps = null,
  isSet = null
}) {
  return {
    type: "REFRESH_STAMPS_SUCCESS",
    items,
    newStamps,
    isSet
  };
};

export default connect(
  mapStateToProps,
  {
    refreshMe,
    refreshStampsSuccess
  }
)(Refresh);
